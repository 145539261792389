import React, { useContext } from 'react';
import Context from '../../components/Context';
import labels from '../../data/labels.json';

function CountrySelector({
  countries,
}) {
  const { setCountry } = useContext(Context);

  const handleCountryChange = value => {
    setCountry(value);
    localStorage.setItem('userCountry', value);
  };

  const { select_country } = labels.site;

  return (
    countries && countries.length > 0 ?
      <section className="c-country-selector">
        { select_country && <h3 className="c-country-selector__title">{select_country}</h3> }
        <ul className="c-country-selector__list">
          {
            countries.map(item => (
              <li
                key={item.value}
                className="c-country-selector__item"
              >
                <button className="c-country-selector__label" onClick={() => handleCountryChange(item?.value)}>
                  <span className={`c-country-selector__image c-country-selector__image--${item?.value}`}></span>
                  <span className="c-country-selector__label-title">{item?.label}</span>
                </button>
              </li>
            ))
          }
        </ul>
      </section>
      : null 
  );
}

export default CountrySelector;
