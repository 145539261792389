import React, { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import Context from '../Context';
import packageJson from '../../../package.json';

const HomeBanner = require('../../images/home/home-banner.png');
const HomeBannerMobile = require('../../images/home/home-banner-mobile.png');

function PromoLinks({
    items,
}) {
    const { country } = useContext(Context);

    return (
        items && items.length > 0 ?
            <nav className="c-promo-links">
                <ul className="c-promo-links__list">
                    {items.map(link => (
                        <li
                            key={link.url}
                            className="c-promo-links__item"
                        >
                            <Link
                                className="c-promo-links__link"
                                to={link.url}
                            >
                                <h2 className="c-promo-links__link-title">{link.title}</h2>
                                <span className="c-promo-links__image">
                                    <img
                                        src={link.image}
                                        alt={link.title}
                                    />
                                </span>
                            </Link>
                        </li>
                    ))}

                    <li className="c-promo-links__item c-promo-links__item--banner">
                        <a
                            href={country === 'uk' ? 'https://www.nutricia.co.uk/hcp/academy.html?utm_source=nutriciadieteticapp&utm_medium=display&utm_campaign=nutriciaacademy2022' : 'https://www.nutricia.ie/hcp/academy.html?utm_source=nutriciadieteticapp&utm_medium=display&utm_campaign=nutriciaacademy2022'}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <img
                                className="image image--homeBanner"
                                src={HomeBanner}
                                alt="We work hard, we make learning easy"
                            ></img>

                            <img
                                className="image image--homeBanner image--homeBanner--mobile"
                                src={HomeBannerMobile}
                                alt="We work hard, we make learning easy"
                            ></img>
                        </a>
                    </li>
                    <li className="c-promo-links__feedback">
                        <div>
                            <h2 className="c-promo-links__link-title">How are you finding our new app?</h2>
                            <p>Send your feedback or queries to our Nutricia support team</p>
                        </div>
                        <div>
                            <a href="mailto:resourcecentre@nutricia.com?subject=Dietetic App Feedback">
                                Send feedback
                            </a>
                        </div>
                    </li>
                </ul>
                <p style={{ color: '#4B3687', fontSize: '0.75em', textAlign: 'right', paddingTop: '0.5em' }}>{packageJson.version}</p>
            </nav>
            : null
    );
}

export default memo(PromoLinks);
