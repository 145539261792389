import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { AssignmentInd } from '@material-ui/icons';

import Context from '../Context';
import SiteContext from '../../../components/Context';
import ROUTES from '../../../consts/routes.json';
import labels from '../../../data/labels.json';

function StepForm({
    children,
    stepId,
    ctas,
}) {
    const [state, dispatch] = useContext(Context);
    const { country } = useContext(SiteContext);
    const history = useHistory();
    var isValid = false;
    // bypass required height on enter patient details if age is below 18 years (otherwise use original logic)
    if (stepId === 'enter_patient_details' && state.PD_GENDER && state.PD_WEIGHT && state.PD_AGE < 216 && false){
        isValid = true;
    }
    else{
        isValid = (state.navigationItems.find(item => item.id === stepId)?.optional || state.navigationItems.find(item => item.id === stepId)?.complete);
    }
    
    const { next_step } = labels?.site;
    const { regimen_edit, report_email } = labels?.pages?.regimen;

    const openReportEmailModal = () => {
        dispatch({
            type: 'EMAIL_REPORT_ACTIVE',
            payload: true,
        });
    };

    const handleContinueClick = (event, stepId) => {
        dispatch({
            type: 'UPDATE_STEP_STATUS',
            payload: stepId,
        });
    };

    const handleResetState = () => {
        dispatch({
            type: 'RESET_STATE',
        });

        dispatch({
            type: 'COUNTRY',
            payload: country,
        });

        history.push(ROUTES.CREATE_REGIMEN);
    };

    /*
    const intermittentIds = ['enter_patient_details', 'energy_requirements'];
    const isNextIntermittent = intermittentIds.indexOf(stepId) > -1;
    const continueLink = isNextIntermittent ? ROUTES.CREATE_REGIMEN : ROUTES.CREATE_REGIMEN;
    */

    const continueLink = 'CREATE_REGIMEN_' + (state.navigationItems.findIndex(element => element.id === stepId) + 1);

    const ctaList = {
        continue: (
            <Button
                key={0}
                to={ROUTES[continueLink]}
                onClick={(event) => handleContinueClick(event, stepId)}
                component={Link}
                disabled={!isValid}
                classes={{ root: 'e-button' }}
                type="submit"
            >{next_step}</Button>
        ),

        skip: (
            <Button
                key={6}
                to={ROUTES.CREATE_REGIMEN_4}
                component={Link}
                classes={{ root: 'e-button' }}
                type="button"
            >Skip to product selection</Button>
        ),
        
        open_report: (
            <Button
                key={1}
                onClick={(event) => handleContinueClick(event, stepId)}
                to='/regimen/report'
                disabled={!state.PC_SELECTED_PRODUCTS?.length}
                component={Link}
                classes={{ root: 'e-button e-button--view-report' }}
                type="submit"
                endIcon={
                    <span className="e-basket">
                        <AssignmentInd />
                        
                        <span className="e-basket__count">
                            {state.PC_SELECTED_PRODUCTS?.length}
                        </span>
                    </span>
                }
            >
                View regimen and email report
            </Button>
        ),

        email_report: (
            <Button
                key={2}
                onClick={() => openReportEmailModal()}
                classes={{ root: 'e-button' }}
                type="button"
            >{report_email}</Button>
        ),

        edit_regimen: (
            <Button
                key={3}
                to={ROUTES.CREATE_REGIMEN_1}
                component={Link}
                classes={{ root: 'e-button e-button--secondary' }}
                type="button"
            >{regimen_edit}</Button>
        ),

        create_regimen: (
            <Button
                key={4}
                onClick={() => handleResetState()}
                classes={{ root: 'e-button e-button--secondary' }}
                type="button"
            >Create new regimen</Button>
        ),
        
        update_energy: (
            <Button
                key={5}
                onClick={() => handleResetState()}
                classes={{ root: 'e-button' }}
                type="button"
            >Update energy requirements</Button>
        ),
    };

    return (
        <div className="c-step-form">
            <fieldset>
                { children }

                {
                    ctas && ctas.length > 0 ? (
                        <section className="c-step-form__ctas">
                            {ctas.map(cta => ctaList[cta])}
                        </section>
                    ) : null
                }
            </fieldset>
        </div>
    );
}

export default StepForm;
