import React from 'react';

import Tabs from '../../components/Tabs';
import items from '../../data/pages/resources';
import labels from '../../data/labels.json';
import HeaderImage from '../../images/resources-header-image.png';

function Resources() {
    const { page_title } = labels?.pages?.resources;

    return (
        <section className="resources">
            <header className="resources__header">
                <h1 className="resources__title">{page_title}</h1>
                
                <img
                    src={HeaderImage}
                    alt="Illustration of a man holding his children"
                    className="resources__image"
                />
            </header>
            
            <Tabs items={items} />
        </section>
    );
}

export default Resources;
