import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTES from '../../consts/routes.json';
import { ContextProvider } from '../../components/ProductSearch/Context';
import { ContextProvider as RegimenProvider } from '../../components/Regimen/Context';
import Product from './Product';
import ProductsHome from './Home';

function Products() {
    return (
        <ContextProvider>
            <RegimenProvider>
                <Switch>
                    <Route
                        exact
                        path={ROUTES.PRODUCTS}
                        component={ProductsHome}
                    />

                    <Route
                        path={`${ROUTES.PRODUCTS}/compare/:id/:idb/:idc/:idd/`}
                        component={Product}
                    />

                    <Route
                        path={`${ROUTES.PRODUCTS}/compare/:id/:idb/:idc/`}
                        component={Product}
                    />

                    <Route
                        path={`${ROUTES.PRODUCTS}/compare/:id/:idb/`}
                        component={Product}
                    />

                    <Route
                        path={`${ROUTES.PRODUCTS}/compare/:id/`}
                        component={Product}
                    />

                    <Route
                        path={`${ROUTES.PRODUCTS}/:id/`}
                        component={Product}
                    />

                </Switch>
            </RegimenProvider>
        </ContextProvider>
    );
}

export default Products;
