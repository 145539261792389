import React, { memo } from "react";
import ProductHeaderImage from '../../images/products-header-image.png';

function PageHeaderProduct({
    page_title,
    text,
    subtitle,
    children,
}) {
    return (
        <header className="c-page-header-product">
            <img
                src={ProductHeaderImage}
                alt="Bottle of vitamins"
                className="c-page-header-product__image"
            />

            <h1 className="c-page-header-product__title">{page_title}</h1>

            { text && (
                <h2 className="c-page-header-product__text">{text}</h2>
            )}

            {subtitle && (
                <h2 className="c-page-header-product__subtitle">{subtitle}</h2>
            )}

            {children}
        </header>
    );
}

export default memo(PageHeaderProduct);
