import { StayPrimaryPortraitOutlined } from '@material-ui/icons';

import ROUTES from "../../../consts/routes.json";
import SaveApp from "../../../components/Support/SaveApp";

export default {
    "component": SaveApp,
    "id": "support_save-app",
    "title": "Save app to your device",
    "url": ROUTES.SUPPORT_SAVE_APP,
    "icon": StayPrimaryPortraitOutlined,
};