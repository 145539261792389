import React, { memo } from 'react';
import labels from '../../../data/labels.json';
import avatar from '../../../images/chatbot/sim-idle.gif';
import { Link } from 'react-router-dom';
import ROUTES from '../../../consts/routes.json';

function ChatbotEop() {
	const { eop } = labels?.pages?.chatbot;

	return (
        <section className='chatbot-eop'>
            <div className='chatbot-eop__wrapper'>
                <img className='chatbot-eop__avatar' src={avatar} alt="Sim EOP avatar" />
                <p className='chatbot-eop__title'>{eop.title}</p>
                <p className='chatbot-eop__description'>{eop.description}</p>
                <p className='chatbot-eop__footnotes'>{eop.footnotes}</p>
                <Link className='chatbot-eop__cta text-decoration-none' to={ROUTES.SUPPORT_CONTACT_US}>{eop.cta_label}</Link>
            </div>
        </section>
	);
}

export default memo(ChatbotEop);
