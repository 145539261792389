import React, { useContext, useState, useEffect } from 'react';
import { format, differenceInMonths, subMonths } from 'date-fns';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { isMobile } from 'react-device-detect';
import labels from '../../../data/labels.json';
import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';

function DobTab() {
  const [ state, dispatch ] = useContext(Context);
  const dateFormat = 'yyyy-MM-dd';
  const dateFormat2 = 'dd/MM/yyyy';
  const todaysDate = format(new Date(), dateFormat);
  const minDate = subMonths(new Date(), 1451); // 120 years, 11 months in months
  const [ totalInMonths, updateTotalInMonths ] = useState(state.PD_AGE || 0);
  const [ dateFromMonths, setDateFromMonths ] = useState(subMonths(new Date(), totalInMonths)); // eslint-disable-line
  const [ dateString, updateDateString ] = useState(dateFromMonths);

  const handleDatePickerChange = date => {
    const dateObj = typeof date === 'string' ? new Date(date) : date;
    
    dispatch({
      type: 'PD_DOB',
      payload: dateObj,
    });

    dispatch({
      type: 'PD_DOB_DAY',
      payload: dateObj.getDate(),
    });

    updateDateString(dateObj);
  };

  const handleMobileDateChange = e => {
    const value = format(new Date(e.target.value), dateFormat);
    handleDatePickerChange(value);
  };

  useEffect(() => {
    const now = new Date(dateString);
    const result = differenceInMonths(new Date(), now);
    updateTotalInMonths(result);
  }, [ dateString ]);

  useEffect(() => {
    dispatch({
      type: 'PD_AGE',
      payload: totalInMonths,
    });

    // eslint-disable-next-line
  }, [ totalInMonths ]);

  const { dob_date, dob_picker } = labels?.pages?.regimen;

  return (
    isMobile ?
      <div className="c-age-picker__date">
        <FieldHeader
          title={dob_date}
          labelFor="PD_AGE_DATE"
        />
        <input
          className="c-age-picker__date-input"
          type="date"
          id="PD_AGE_DATE"
          onChange={handleMobileDateChange}
          max={todaysDate}
          min={format(minDate, dateFormat)}
          value={format(state.PD_DOB, dateFormat)}
          onKeyDown={(e) => e.preventDefault()}
        />
      </div> :
      <div className="c-age-picker__mui-date">
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          format={dateFormat2}
          value={state.PD_DOB}
          onChange={handleDatePickerChange}
          variant="inline"
          inputVariant="outlined"
          maxDate={todaysDate}
          minDate={minDate}
          InputProps={{
            inputProps: {
              disabled: true,
              max: todaysDate,
            },
          }}
          KeyboardButtonProps={{
            'aria-label': dob_picker,
          }}
        />
      </div>
  );
}

export default DobTab;
