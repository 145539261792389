import React, { useState, useContext } from 'react';
import AWS from 'aws-sdk';
import { Button, Dialog, IconButton, TextField } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import labels from '../../data/labels.json';
import Context from '../Regimen/Context';
import generateEmailMarkup from '../../utils/generateEmail';

function Modal() {
    const [state, dispatch] = useContext(Context);
    const [emailAddress, updateEmailAddress] = useState('');
    const [honeypot, setHoneypot] = useState('');
    const [errorMessage, updateErrorMessage] = useState();
    const [successMessage, updateSuccessMessage] = useState();
    const {
        title,
        submit_text,
        field_placeholder,
    } = labels?.site?.email_report;

    const handleUpdateEmailAddress = event => updateEmailAddress(event.target.value);
    const handleHoneypot = event => setHoneypot(event.target.value);

    const handleClose = () => {
        dispatch({
            type: 'EMAIL_REPORT_ACTIVE',
            payload: false,
        });
    };

    AWS.config.region = process.env.REACT_APP_CTP_AWS_REGION; // Region
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_CTP_AWS_ID,
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        if (honeypot !== '') {
            return;
        }

        updateSuccessMessage('Sending...');

        const lambda = new AWS.Lambda();
        const markup = generateEmailMarkup({
            gender: state.PD_GENDER,
            age: state.PD_AGE,
            weight: state.PD_WEIGHT,
            height: state.PD_HEIGHT,
            BMI: state.BMI,
            protein: state.PATIENT_PROTEIN_RESULT,
            energy: state.PATIENT_ENERGY_RESULT,
            chosenProducts: state.PC_SELECTED_PRODUCTS,
            macroNutrients: state.RR_EMAIL_DATA.macroNutrients,
            vitamins: state.RR_EMAIL_DATA.vitamins,
            minerals: state.RR_EMAIL_DATA.minerals,
            others: state.RR_EMAIL_DATA.allNutrients,
            country: localStorage.getItem('userCountry'),
        });

        const params = {
            FunctionName: "NutriciaEmailLambda", //dev: GetStartedLambdaProxyIntegration
            InvocationType: "RequestResponse",
            Payload: JSON.stringify({
                emailAddress,
                markup,
                title: "Your Nutricia Regimen Report",
            }),
        };

        lambda.invoke(params).promise()
            .then(response => {
                const payload = response && response.Payload ? JSON.parse(response.Payload) : null;
                const body = payload && payload.body ? JSON.parse(payload.body) : null;

                if (body.status && payload.statusCode === 200) {
                    switch (body.status) {
                        case 'success':
                            updateSuccessMessage(true);
                            return;
                        case 'error':
                            updateErrorMessage("Something went wrong! Please try again");
                            return;
                        default:
                            return;
                    }
                } else {
                    errorMessage('');
                }
            })
            .catch(e => {
                updateErrorMessage(e);
                console.error(JSON.stringify(e));
            });
    };

    return (
        <Dialog
            onClose={handleClose}
            open={state.EMAIL_REPORT_ACTIVE || false}
            className="c-modal c-modal--email-report"
        >
            <form
                onSubmit={handleSubmit}
                className="c-modal__inner"
            >
                <div className="c-modal__header">
                    <h2>{title}</h2>

                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <Close />
                    </IconButton>
                </div>

                {
                    successMessage ? (
                        <div className="c-modal__content">
                            <p className="c-modal__message">Your report should be in your inbox shortly.</p>
                        </div>
                    ) : (
                        <>
                            <div className="c-modal__content">
                                <div className="c-modal__textfield c-modal__textfield--full-width c-text-field">
                                    <TextField
                                        placeholder={field_placeholder}
                                        variant="outlined"
                                        onChange={handleUpdateEmailAddress}
                                        type="email"
                                    />
                                </div>
                                
                                {
                                    errorMessage ? (
                                        <p className="c-modal__error">
                                            {errorMessage}
                                        </p>
                                    ) : null
                                }
                                
                                <input type="hidden" onChange={handleHoneypot} />
                            </div>

                            <div className="c-modal__ctas">
                                <Button
                                    classes={{ root: 'e-button' }}
                                    type="submit"
                                >
                                    {submit_text}
                                </Button>
                            </div>
                        </>
                    )
                }
            </form>
        </Dialog>
    );
}

export default Modal;
