import ranges from '../../calculation-sets/age-ranges';

const male = [
  {
    range: [ ranges.from.zero, ranges.to.threeYears ],
    a: 61,
    b: 33.7,
  },
  {
    range: [ ranges.from.threeYears, ranges.to.tenYears ],
    a: 23.3,
    b: 514,
  },
  {
    range: [ ranges.from.tenYears, ranges.to.eighteenYears ],
    a: 18.4,
    b: 581,
  },
  {
    range: [ ranges.from.eighteenYears, ranges.to.thirtyYears ],
    a: 16,
    b: 545,
  },
  {
    range: [ ranges.from.thirtyYears, ranges.to.sixtyYears ],
    a: 14.2,
    b: 593,
  },
  {
    range: [ ranges.from.sixtyYears, ranges.to.max ],
    a: 13.5,
    b: 514,
  },
];

const female = [
  {
    range: [ ranges.from.zero, ranges.to.threeYears ],
    a: 58.9,
    b: 23.1,
  },
  {
    range: [ ranges.from.threeYears, ranges.to.tenYears ],
    a: 20.1,
    b: 507,
  },
  {
    range: [ ranges.from.tenYears, ranges.to.eighteenYears ],
    a: 11.1,
    b: 761,
  },
  {
    range: [ ranges.from.eighteenYears, ranges.to.thirtyYears ],
    a: 13.1,
    b: 558,
  },
  {
    range: [ ranges.from.thirtyYears, ranges.to.sixtyYears ],
    a: 9.74,
    b: 694,
  },
  {
    range: [ ranges.from.sixtyYears, ranges.to.max ],
    a: 10.1,
    b: 569,
  },
];

const getResult = ({
  age,
  gender,
  weight,
}) => {
  let result;
  const data = gender === 'male' ? male : female;
  data.forEach((e, i) => {
    if (age >= e.range[0] && age <= e.range[1]) {
      if (i === 0) {
        result = e.a * weight - e.b;
      } else {
        result = e.a * weight + e.b;
      }
    }
  });
  return result;
};

export default getResult;
