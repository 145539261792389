import React, { memo, useEffect, useState } from 'react';
import Legend from '../../ProductSearch/Legend';
import labels from '../../../data/labels.json';
import { formatText } from '../../../utils';
import specialDiets from '../../../data/products/specialDiets.json';
//import allergenLogos from '../../../data/products/allergenLogos.json';
import RotatePhone from '../RotatePhone';
import { Close } from '@material-ui/icons';

//import allergen cases
import cerealsGlutenImage from '../../../images/allergens/nutricia-brand-allergens-cereals-with-gluten.svg';
import crustaceansImage from '../../../images/allergens/nutricia-brand-allergens-crustaceans.svg';
import eggsImage from '../../../images/allergens/nutricia-brand-allergens-eggs.svg';
import fishImage from '../../../images/allergens/nutricia-brand-allergens-fish.svg';
import peanutsImage from '../../../images/allergens/nutricia-brand-allergens-peanuts.svg';
import soybeansImage from '../../../images/allergens/nutricia-brand-allergens-soy.svg';
import milkLactoseImage from '../../../images/allergens/nutricia-brand-allergens-milk.svg';
import nutsImage from '../../../images/allergens/nutricia-brand-allergens-nuts.svg';
import celeryImage from '../../../images/allergens/nutricia-brand-allergens-celery.svg';
import mustardImage from '../../../images/allergens/nutricia-brand-allergens-mustard.svg';
import sesameSeedsImage from '../../../images/allergens/nutricia-brand-allergens-sesame.svg';
import suplhurDioxideSuplhitesImage from '../../../images/allergens/nutricia-brand-allergens-sulphites.svg';
import lupinImage from '../../../images/allergens/nutricia-brand-allergens-lupines.svg';
import molluscsImage from '../../../images/allergens/nutricia-brand-allergens-moluscs.svg';

function ProductDescription({ product, products, country, onRemoveProduct }) {
	const [infoExpands, setInfoExpands] = useState();
	const [showExpandLabels, setShowExpandLabels] = useState();

	const allergenLogos = {
		"cerealsGluten": cerealsGlutenImage,
		"crustaceans": crustaceansImage,
		"eggs": eggsImage,
		"soybeans": soybeansImage,
		"peanuts": peanutsImage,
		"fish": fishImage,
		"milkLactose": milkLactoseImage,
		"nuts": nutsImage,
		"celery": celeryImage,
		"mustard": mustardImage,
		"sesameSeeds": sesameSeedsImage,
		"suplhurDioxideSuplhites": suplhurDioxideSuplhitesImage,
		"lupin": lupinImage,
		"molluscs": molluscsImage
	}
	const locale = country === 'uk' ? 'en-GB' : 'en-IE';

	// let description = product?.productDescriptionHcp?.[locale]?.split('\n');

	// // Only if IE text should differ the field will have content for en-IE, so we fallback to en-GB if empty
	// if (description?.length === 1 && description[0] === '') {
	//   description = product?.productDescriptionHcp?.['en-GB']?.split('\n');
	// }

	// // fallback to old productDescription to prevent errors while doing the update
	// if (!description) {
	//   descriptions = product?.productDescription?.split('\n');
	// }

	// const detailsList = ['brand', 'caseSize'];

	const productInfoList = products.map((product) => ({
		title: product?.name,
		medicalIngredients: product?.medicalIngredients?.split('\n'),
		codes: product?.codes,
		gmsApproved: product?.gmsApproved,
		acbsApproved: product?.acbsApproved,
		importantNotice: product?.importantNotice?.split('\n'),
		productDescriptionHcp: product?.productDescriptionHcp,
		flavours: product?.flavours?.split(','),
		allergens: product?.allergens,
		caseSizes: product?.caseSize.split(',')
	}));

	const showAttributes = {
		importantNotice: false,
		productDescriptionHcp: false,
		allergens: false
	};

	for (let key in showAttributes) {
		if (showAttributes.hasOwnProperty(key)) {
			if (key === "allergens") {
				productInfoList.forEach(product => {
					product[key].forEach(allergen => {
						if (allergen.value && allergen.value === 'yes') {
							showAttributes[key] = true;
						}
					});
				});
			}
			if (key === "importantNotice") {
				productInfoList.forEach(product => {
					if (product[key] && product[key].length > 0) {
						showAttributes[key] = true;
					}
				});
			}
			if (key !== 'allergens' && key !== 'importantNotice') {
				productInfoList.forEach(product => {
					if (product[key]) {
						showAttributes[key] = true;
					}
				});
			}
		}
	}

	const productsNutritionalCategories = products?.map((product) => {
		const isVegetarian = product?.specialDiet?.includes('vegetarian');
		const isVegan = product?.specialDiet?.includes('vegan');
		const isKosher = product?.specialDiet?.includes('kosher');
		const isHalal = product?.specialDiet?.includes('halal');
		return [
			isVegetarian && specialDiets.vegetarian,
			isVegan && specialDiets.vegan,
			isKosher && specialDiets.kosher,
			isHalal && specialDiets.halal,
		].filter((e) => e);
	});



	const productLabels = labels?.pages?.product;

	// const pipCodeList = codes.reduce((a, c) => {
	//   a.push(c.pipCode);
	//   return a;
	// }, []).filter(e => e);

	const handleExpandInfo = (v) => {
		setInfoExpands((prev) => ({ ...prev, [v]: !prev?.[v] }));
	};

	useEffect(() => {
		let descriptionDiv = document.getElementsByClassName('js-expandable-description')[0];
		let ingredientsDiv = document.getElementsByClassName('js-expandable-ingredients')[0];
		let importantDiv = document.getElementsByClassName('js-expandable-important')[0];

		let descriptionExpands = false;
		let ingredientsExpands = false;
		let importantInfoExpands = false;

		if (descriptionDiv && descriptionDiv.offsetHeight >= 120) {
			descriptionExpands = true;
		}
		if (ingredientsDiv && ingredientsDiv.offsetHeight >= 120) {
			ingredientsExpands = true;
		}
		if (importantDiv && importantDiv.offsetHeight >= 120) {
			importantInfoExpands = true;
		}

		setShowExpandLabels({
			descriptionExpands,
			ingredientsExpands,
			importantInfoExpands

		});
	}, []);

	return (
		<div className={`c-product__tab ${products.length === 1 && 'c-product__tab--single'}`}>
			{products.length === 2 && window.innerWidth < 720 && <RotatePhone />}

			<div className='c-product__images c-product__info-wrapper'>
				{products.map(product => (
					<figure
						className='c-product__image'
						key={product.id}
					>
						<img src={product?.images[0]?.url} alt={product?.name || ''} />

						<div className='c-product__image--background' />

						{products.length > 1 ? (
							<div
								className='c-product__image--remove'
								onClick={() => onRemoveProduct(product.id)}
							>
								<Close />
							</div>
						) : null}
					</figure>
				))}
			</div>

			<div className='c-product__info-wrapper c-product__title-wrapper'>
				{products.map(product => (
					<div
						key={product.id}
						className={`${products.length === 1 && 'c-product__title--flex'}`}
					>
						<h1 className={`${products.length > 1 && 'c-product__title-mini'}`}>{product.name}</h1>
						{((locale === "en-IE" && product.productSampleAvailability.key === "roini") || (locale === "en-GB" && product.productSampleAvailability.key === "roini") || (product.productSampleAvailability.key === "all")) &&
							<a className="c-product__sample" href={locale === "en-GB" ? "https://www.nutricia.co.uk/hcp/services/sampling-service.html?utm_source=dieteticapp&utm_medium=link&utm_campaign=ordersample" : "https://www.nutricia.ie/hcp/services/sampling-service.html?utm_source=dieteticapp&utm_medium=link&utm_campaign=ordersample"}>
								Order a Sample
							</a>
						}
					</div>
				))}
			</div>


			{
				// description &&
				//   <div className="c-product__text">
				//     <h3>{productLabels?.description_title}</h3>
				//     {
				//       productInfoList.map(info => (
				//         <div>
				//           {description.map((desc, index) =>
				//             <p key={index}dangerouslySetInnerHTML={{__html: formatText(desc)}} />
				//           )}
				//           {
				//             country === 'roi' && <p>{ productLabels.GMS_approved }: { gmsApproved ? 'Yes' : 'No'}</p>
				//           }
				//           {
				//             country === 'uk' && <p>{ productLabels.ACBS_approved }: { acbsApproved ? 'Yes' : 'No' }</p>
				//           }
				//         </div>
				//       ))
				//     }
				//   </div>
			}
			{/*
			<div className='c-product__text'>
				<h3>{productLabels?.description_title}</h3>
				<div
					className={`c-product__info-wrapper${infoExpands?.productDescriptionHcp && ' c-product__info-expand'}`}>
					<div
								className='c-product__info-item'
					>
						<p>{productInfoList[0].productDescriptionHcp["en-GB"]}</p>
					</div>
					
				</div>

				<h2 onClick={() => handleExpandInfo('productDescriptionHcp')}>
					{infoExpands?.productDescriptionHcp
						? labels?.pages?.product.collapse
						: labels?.pages?.product.expand}
				</h2>
			</div>
			*/}

			{showAttributes.productDescriptionHcp ? (
				<div className='c-product__text'>
					<h3>{productLabels?.description_title}</h3>

					<div
						className={`c-product__info-wrapper js-expandable-description ${infoExpands?.productDescriptionHcp ? ' c-product__info-expand' : ''}`}>
						{productInfoList.map((info, index) =>
							info.productDescriptionHcp ? (
								<div
									className='c-product__info-item'
									key={products[index].id}
								>
									<p>{info.productDescriptionHcp["en-GB"]}</p>
								</div>
							) : null
						)}
					</div>

					{showExpandLabels?.descriptionExpands &&
						<h2 onClick={() => handleExpandInfo('productDescriptionHcp')}>
							{infoExpands?.productDescriptionHcp
								? labels?.pages?.product.collapse
								: labels?.pages?.product.expand}
						</h2>
					}
				</div>
			) : null}

			{showAttributes.allergens ? (
				<div className='c-product__text'>
					<h3>{productLabels?.allergens}</h3>

					<div className='c-product__info-wrapper'>

						{productInfoList.map((info, index) =>
							<div
								className='c-product__info-item'
								key={products[index].id}
							>
								<ul className={'c-product__list--allergens' + (productInfoList.length === 1 ? ' c-product__list--double' : '')}>
									{info.allergens.map((allergen, index) =>
										allergen.value === 'yes' ? (
											<li key={index}>
												{false &&
													<img src={allergenLogos[allergen.id]} alt={allergen.label} />
												}
												<div><b>{allergen.label}</b></div>
											</li>

										) : null)}
								</ul>
							</div>
						)}
					</div>
				</div>
			) : null}

			<div className='c-product__text'>
				<h3>{productLabels?.special_diet}</h3>

				<div className='c-product__info-wrapper c-product__key-wrapper'>
					{productsNutritionalCategories.map((nutritionalCategories, index) =>
						nutritionalCategories && nutritionalCategories.length > 0 ? (
							<div
								className='c-product__info-item'
								key={products[index].id}
							>
								<Legend items={nutritionalCategories} onProductPage />
							</div>
						) : 
						<div class="c-legend__none">
							None
					  	</div>
					)}
				</div>
				<div className='c-product__info-item'>
					<p>
						This is intended to show suitability of products for religious or dietary reasons. Some products may be suitable for kosher or halal diets but only products with Kosher approval or Halal certification have been identified here. Some individual flavours/variants may not be suitable and for more information please call our Resource Centre.
					</p>
				</div>
			</div>

			<div className='c-product__text'>
				<h3>{productLabels?.flavours_title}</h3>

				<div className='c-product__info-wrapper'>
					{productInfoList.map((info, index) =>
						info.flavours && info.flavours.length > 0 ? (
							<div
								className='c-product__info-item'
								key={products[index].id}
							>
								<ul className={'c-product__list' + (productInfoList.length === 1 ? ' c-product__list--double' : '')}>
									{info.flavours.map((flavour) => (
										<li key={flavour}>{flavour}</li>
									))}
								</ul>
							</div>
						) : null
					)}
				</div>

			</div>

			<div className='c-product__details'>
				<h3>{productLabels?.caseSize}</h3>
				<div class="c-product__info-wrapper">
					{productInfoList.map((info, index) =>
						<div
							className='c-product__info-item'
							key={products[index].id}
						>
							<ul>
								{info.caseSizes.map((caseSize) => (
									<li key={caseSize} className='c-product__details-item'>
										{caseSize}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>

			</div>

			<div className='c-product__text'>
				<h3>{productLabels?.ingredients}</h3>

				<div
					className={`
						c-product__info-wrapper
						js-expandable-ingredients
						${infoExpands?.medicalIngredients && 'c-product__info-expand'}
					`}
				>
					{productInfoList.map((info, index) =>
						info.medicalIngredients && info.medicalIngredients.length > 0 ? (
							<div
								className='c-product__info-item'
								key={products[index].id}
							>
								{info.medicalIngredients.map((medicalIngredient, index) => (
									<p
										key={index}
										dangerouslySetInnerHTML={{ __html: formatText(medicalIngredient) }}
									/>
								))}
							</div>
						) : null
					)}
				</div>

				{showExpandLabels?.ingredientsExpands &&
					<h2 onClick={() => handleExpandInfo('medicalIngredients')}>
						{infoExpands?.medicalIngredients
							? labels?.pages?.product.collapse
							: labels?.pages?.product.expand}
					</h2>
				}
			</div>

			{showAttributes.importantNotice ? (
				<div className='c-product__text'>
					<h3>{productLabels?.important_notice}</h3>

					<div
						className={`c-product__info-wrapper${infoExpands?.importantNotice ? ' c-product__info-expand' : ''}`}>
						{productInfoList.map((info, index) =>
							info.importantNotice && info.importantNotice.length > 0 ? (
								<div
									className='c-product__info-item'
									key={products[index].id}
								>
									{info.importantNotice.map((notice, index) => (
										<p key={index}>{notice}</p>
									))}
								</div>
							) : null
						)}
					</div>

					{showExpandLabels?.importantInfoExpands &&
						<h2 onClick={() => handleExpandInfo('importantNotice')} className="js-expandable-important">
							{infoExpands?.importantNotice
								? labels?.pages?.product.collapse
								: labels?.pages?.product.expand}
						</h2>
					}

				</div>
			) : null}

			<div className='c-product__text'>
				<h3>Order Codes</h3>

				<div
					className={`c-product__info-wrapper${infoExpands?.importantNotice ? ' c-product__info-expand' : ''}`}>
					{productInfoList.map((info, index) =>
						info.codes && info.codes.length > 0 ? (
							<div
								className='c-product__info-item'
								key={products[index].id}
							>
								{info.codes.map((code, index) => (
									<p key={index}>{`${code.productName}: ${locale === "en-IE" ? code.orderCode : (code.pipCode || code.orderCode)}`}</p>
								))}
							</div>
						) : null
					)}
				</div>
			</div>

			{
				// codes && codes.length > 0 ?
				//   <div className="c-product__text">
				//     <h3>{country === 'uk' ? pipCodeList.length > 0 ? productLabels?.pip_codes : 'Code' : productLabels?.order_codes}</h3>
				//     <ul className="c-product__codes">
				//       {
				//         codes.map(code => (
				//           <li key={code.orderCode} className="c-product__codes-item">
				//             <p>{ code.productName } - { country === 'uk' ? code.pipCode || code.orderCode : code.orderCode }</p>
				//           </li>
				//         ))
				//       }
				//     </ul>
				//   </div>
				//   : null
			}
		</div>
	);
}

export default memo(ProductDescription);
