import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Context from '../Context';
import Page from '../../templates/Page';
import labels from '../../data/labels.json';

function Routes() {
    const { country, navigation, } = useContext(Context);

    return (
        <Switch>
            {
                (navigation && navigation.length) ? navigation.map(item => {
                    const {
                        exact,
                        id,
                        path,
                        template: Template,
                        pageTitle,
                        subTitle,
                        description,
                        copy,
                        hidePageHeader,
                        content,
                    } = item;

                    if (country === '' && path !== '/') {
                        return <Redirect to="/" key={`${id}`} />
                    }

                    return (
                        <Route
                            exact={exact}
                            key={id}
                            path={path}
                            render={() => (
                                <Page
                                    pageTitle={pageTitle}
                                    subTitle={subTitle}
                                    description={description}
                                    copy={copy}
                                    hidePageHeader={hidePageHeader}
                                >
                                    {Template && <Template content={content} />}
                                </Page>
                            )}
                        />
                    );
                }) : null
            }

            {/* 404 page */}
            <Route
                component={() => (
                    <Page
                        pageTitle={labels?.pages[404]?.page_title}
                        copy={labels?.pages[404]?.text}
                    />
                )}
            />
        </Switch>
    );
}

export default Routes;
