import React from 'react';

import Step from '../../../data/forms/create-regimen/EnergyRequirements';
import labels from '../../../data/labels.json';
import { handleStepFields } from '../../Form';
import PageHeader from '../../PageHeader';
import StepForm from '../StepForm';
import Summary from '../Summary';

function EnergyRequirements() {
    const { page_title, text, tooltip } = labels?.pages?.regimen?.step_2;

    return (
        <section className="regimen-energy-requirements">
            <PageHeader
                page_title={page_title}
                text={text}
                tooltip={tooltip}
            />

            <Summary detailsLinks={true} />

            {
                Step.fields && Step.fields.length > 0 ?
                    <StepForm
                        stepId={Step.id}
                        nextStep={Step.next_step}
                        ctas={['continue', 'skip']}
                    >
                        { Step.fields.map(handleStepFields) }
                    </StepForm>
                : null
            }
        </section>
    );
}

export default EnergyRequirements;