import React, { memo } from 'react';
import avatar from '../../../images/chatbot/sim-waving.gif';

function ChatbotIntro({ intro, subintro = false }) {

	return (
        <section className='chatbot-intro'>
            <img className='chatbot-intro__avatar' src={avatar} alt='Sim avatar' />

            <h1 className='chatbot-intro__title'>{intro}</h1>

            {subintro !== false ? <p className='chatbot-intro__subtitle'>{subintro}</p> : ''}
        </section>
	);
}

export default memo(ChatbotIntro);