import React, { useContext, useState, memo } from 'react';
import {
  TextField as Field,
  InputAdornment,
} from '@material-ui/core';
import {
  Edit,
} from '@material-ui/icons';
import labels from '../../../data/labels.json';
import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';

function MetricTab() {
  const [ state, dispatch ] = useContext(Context);

  const [ currentWeightKG, updateCurrentWeightKG ] = useState(state.PD_WEIGHT || '');
  const [ previousWeightKG, updatePreviousWeightKG ] = useState(state.PD_WEIGHT_PREVIOUS || '');

  // const [ currentWeightValid, setCurrentWeightValid ] = useState(true);
  // const [ previousWeightValid, setPreviousWeightValid ] = useState(true);

  // const kgLimit = 250;

  const handleCurrentWeightKGChange = event => {
    const value = event.target.value;
    updateCurrentWeightKG(value);

    // Uncomment to apply field validation
    // if (value >= 0 && value <= kgLimit) {
    //   updateCurrentWeightKG(value);
    //   setCurrentWeightValid(true);
    // } else {
    //   setCurrentWeightValid(false);
    // }
  }

  const handlePreviousWeightKGChange = event => {
    const value = event.target.value;
    updatePreviousWeightKG(value);

    // Uncomment to apply field validation
    // if (value >= 0 && value <= kgLimit) {
    //   updatePreviousWeightKG(value);
    //   setPreviousWeightValid(true);
    // } else {
    //   setPreviousWeightValid(false);
    // }
  }

  useDebouncedEffect(() => {
    dispatch({
      type: 'PD_WEIGHT',
      payload: Number(currentWeightKG),
    });
  }, [ currentWeightKG ]);

  useDebouncedEffect(() => {
    dispatch({
      type: 'PD_WEIGHT_PREVIOUS',
      payload: Number(previousWeightKG),
    });
  }, [ previousWeightKG ]);

  const {
    kg_current,
    kg_previous,
  } = labels?.pages?.regimen;

  return (
    <div className="c-weight-picker__controls">
      <div className="c-weight-picker__dropdown">
        <FieldHeader
          title={kg_current}
          labelFor="PD_WEIGHT_CURRENT_KG"
        />
        <div className="c-text-field">
          <Field
            id="PD_WEIGHT_CURRENT_KG"
            variant="outlined"
            onChange={handleCurrentWeightKGChange}
            onBlur={handleCurrentWeightKGChange}
            value={currentWeightKG}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Edit />
                </InputAdornment>
              )
            }}
          />
          {/* { !currentWeightValid && <p className="e-error">Please specify a value below or equal to {kgLimit}</p> } */}
        </div>
      </div>
      <div className="c-weight-picker__dropdown">
        <FieldHeader
          title={kg_previous}
          labelFor="PD_WEIGHT_PREVIOUS_KG"
        />
        <div className="c-text-field">
          <Field
            id="PD_WEIGHT_PREVIOUS_KG"
            variant="outlined"
            onChange={handlePreviousWeightKGChange}
            onBlur={handlePreviousWeightKGChange}
            value={previousWeightKG}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Edit />
                </InputAdornment>
              )
            }}
          />
          {/* { !previousWeightValid && <p className="e-error">Please specify a value below or equal to {kgLimit}</p> } */}
        </div>
      </div>
    </div>
  );
}

export default memo(MetricTab);
