import { KeyboardBackspace } from "@material-ui/icons";
import React, { lazy, memo, Suspense } from "react";
import { Link, useLocation } from "react-router-dom";

import LanguageSelector from "../LanguageSelector";
import Logo from '../../images/nutricia-logo.svg';

const Tooltip = lazy(() => import('../Tooltip'));

function PageHeader({
    page_title,
    tooltip,
    text,
    subtitle,
    showBottomBorder,
    children,
    overideParent
}) {
    const { pathname } = useLocation();
    const parentUrl = overideParent ? overideParent : pathname.substring(0, pathname.lastIndexOf('/')) || '/';

    return (
        <header className={`
            c-page-header
            ${showBottomBorder ? 'c-page-header--bottom-border' : ''}
            ${pathname === '/' ? 'c-page-header--welcome' : ''}
            ${pathname.indexOf('regimen') > -1 ? 'c-page-header--white' : ''}
            ${pathname.indexOf('support') > -1 ? 'c-page-header--support' : ''}
        `}>
            <div className="c-page-header__wrapper">
                {
                    pathname === '/' ? (
                        <>
                            <img
                                src={Logo}
                                alt="Nutricia Logo"
                                className="c-page-header__wrapper__image"
                            />
    
                            <LanguageSelector />
                        </>
                    ) : (
                        <Link
                            to={parentUrl}
                            className="c-page-header__back"
                        >
                            <KeyboardBackspace />
                        </Link>
                    )
                }

                {page_title && <h1 className="c-page-header__title">{page_title}</h1>}

                {
                    page_title && tooltip &&
                    <Suspense fallback={<div />}>
                        <Tooltip content={tooltip || {}} />
                    </Suspense>
                }
            </div>

            {text && <p className="c-page-header__text">{text}</p>}

            {subtitle && <h2 className="c-page-header__subtitle">{subtitle}</h2>}

            {children}
        </header>
    );
}

export default memo(PageHeader);
