import React from 'react';
import Tooltip from '../../Tooltip';

function FieldHeader({
  title,
  tooltip,
  labelFor,
  titleModifier,
}) {
  const Tag = labelFor ? 'label' : 'p';
  return (
    <div className="c-field-header">
      {
        title && title !== '' &&
          <Tag
            className={`c-field-header__label${titleModifier ? ` c-field-header__label--${titleModifier}` : ''}`}
            htmlFor={labelFor}
          >
            {title}
          </Tag> 
      }
      { title && tooltip && <Tooltip content={tooltip || {}} /> }
    </div>
  );
}

export default FieldHeader;
