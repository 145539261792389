import React from 'react';

import Step from '../../../data/forms/create-regimen/ProteinCalculator';
import labels from '../../../data/labels.json';
import { handleStepFields } from '../../Form';
import PageHeader from '../../PageHeader';
import StepForm from '../StepForm';
import Summary from '../Summary';

function ProteinCalculator() {
    const { page_title, text } = labels?.pages?.regimen?.step_3;

    return (
        <section className="regimen-protein">
            <PageHeader
                page_title={page_title}
                text={text}
            />

            <Summary detailsLink={true} />

            {
                Step.fields && Step.fields.length > 0 ? (
                    <StepForm
                        stepId={Step.id}
                        nextStep={Step.next_step}
                        ctas={[
                            'continue',
                            'skip'
                        ]}
                    >
                        {Step.fields.map(handleStepFields)}
                    </StepForm>
                ) : null
            }
        </section>
    );
}

export default ProteinCalculator;
