import React, { useContext } from 'react';

import labels from '../../../data/labels.json';
import Context from '../Context';
import PageHeader from '../../PageHeader';
import StepList from '../StepList';
import Summary from '../Summary';

function RegimenLanding() {
    const { page_title } = labels?.pages?.regimen;
    const [state] = useContext(Context);
    const { navigationItems } = state;

    return (
        <section className="regimen-landing">
            <PageHeader page_title={page_title} />

            <Summary detailsLink />

            <StepList items={navigationItems} />
        </section>
    );
}

export default RegimenLanding;
