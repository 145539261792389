import ROUTES from "../../../consts/routes.json";
import Region from "../../../components/Support/Region";
import CountryIcon from '../../../components/CountryIcon';

export default {
    "component": Region,
    "id": "support_region",
    "title": "Region",
    "url": ROUTES.SUPPORT_REGION,
    "icon": CountryIcon
};