import React, { useContext, useState, useEffect } from 'react';

import Step from '../../../data/forms/create-regimen/EnterPatientDetails';
import PageHeader from '../../PageHeader';
import { handleStepFields } from '../../Form';
import StepForm from '../StepForm';
import labels from '../../../data/labels.json';
import Summary from '../Summary';
import Context from '../Context';

function EnterPatientDetails() {
    const [state] = useContext(Context);
    const { page_title, text } = labels?.pages?.regimen?.step_1;
    const [weightGain, setWeightGain] = useState(0);

    useEffect(() => {
        setWeightGain((Number(state.PD_WEIGHT) / Number(state.PD_WEIGHT_PREV)) * 100 - 100);
        //setWeightGain((weightGain<=0?"":"+") + weightGain);
    }, [state.PD_WEIGHT, state.PD_WEIGHT_PREV, weightGain]);

    // If no steps are complete (fresh regimen) reset product search session storage
    useEffect (() => {
        if (!state.navigationItems[1].complete){
            sessionStorage.removeItem('searchTerm');
        }
    }, [state]);

    return (
        <section className="regimen-patient-details">
            <PageHeader
                page_title={page_title}
                text={text}
                showBottomBorder
            />

            <Summary detailsLinks />

            {
                Step.fields && Step.fields.length > 0 ? (
                    <StepForm
                        stepId={Step.id}
                        nextStep={Step.next_step}
                        ctas={['continue']}
                    >
                        {Step.fields.map(handleStepFields)}
                    </StepForm>
                ) : null
            }
        </section>
    );
}

export default EnterPatientDetails;