import { Close } from '@material-ui/icons';
import React, { /* lazy, Suspense, */ useEffect, useState } from 'react';
import labels from '../../../data/labels.json';
import RotatePhone from '../RotatePhone';

function DirectionsForUse({ country, onRemoveProduct, products }) {
	const [infoExpands, setInfoExpands] = useState();
	const [showExpandLabels, setShowExpandLabels] = useState();
	const handleExpandInfo = (v) => {
		setInfoExpands((prev) => ({ ...prev, [v]: !prev?.[v] }));
	};

	useEffect(() => {
		let indications = false;
		let contraIndications = false;
		let precautions = false;
		let directionsForUse = false;

		let indicationsDiv = document.getElementsByClassName('js-expandable-indications')[0];
		let contraIndicationsDiv = document.getElementsByClassName('js-expandable-contraindications')[0];
		let precautionsDiv = document.getElementsByClassName('js-expandable-precautions')[0];
		let directionsDiv = document.getElementsByClassName('js-expandable-directions')[0];

		if (indicationsDiv && indicationsDiv.offsetHeight >= 120) {
			indications = true;
		}
		if (directionsDiv && directionsDiv.offsetHeight >= 120) {
			directionsForUse = true;
		}
		if (contraIndicationsDiv && contraIndicationsDiv.offsetHeight >= 120) {
			contraIndications = true;
		}
		if (precautionsDiv && precautionsDiv.offsetHeight >= 120) {
			precautions = true;
		}

		setShowExpandLabels({
			indications,
			contraIndications,
			precautions,
			directionsForUse,
		});


	}, [products]);

	var showAttributes = {
		indications: false,
		contraIndications: false,
		directionsForUse: false,
		precautions: false,
		shelfLife: false
	};

	for (let key in showAttributes) {
		if (showAttributes.hasOwnProperty(key)) {
			products.forEach(product => {
				if (product[key]) {
					showAttributes[key] = true;
				}
			});
		}
	}

	const locale = country === 'uk' ? 'en-GB' : 'en-IE';



	return (
		<div className={`c-product__tab c-product__tab--directionsforuse ${products.length === 1 ? ' c-product__tab--single' : ''}`}>
			{(products.length === 2 && window.innerWidth < 667) ? <RotatePhone /> : null}

			<div className='c-product__images c-product__info-wrapper'>
				{products.map(product => (
					<figure
						key={product.id}
						className='c-product__image'
					>
						<img
							src={product?.images[0]?.url}
							alt={product.name}
						/>

						<div className='c-product__image--background' />

						{products.length > 1 ? (
							<div
								className='c-product__image--remove'
								onClick={() => onRemoveProduct(product.id)}
							>
								<Close />
							</div>
						) : null}
					</figure>
				))}
			</div>

			<div className='c-product__info-wrapper c-product__title-wrapper'>
				{products.map(product => (
					<div
						key={product.id}
						className={`${products.length === 1 && 'c-product__title--flex'}`}
					>
						<h1 className={`${products.length > 1 && 'c-product__title-mini'}`}>{product.name}</h1>
						{((locale === "en-IE" && product.productSampleAvailability.key === "roini") || (locale === "en-GB" && product.productSampleAvailability.key === "roini") || (product.productSampleAvailability.key === "all")) &&
							<a className="c-product__sample" href={locale === "en-GB" ? "https://www.nutricia.co.uk/hcp/services/sampling-service.html?utm_source=dieteticapp&utm_medium=link&utm_campaign=ordersample" : "https://www.nutricia.ie/hcp/services/sampling-service.html?utm_source=dieteticapp&utm_medium=link&utm_campaign=ordersample"}>
								Order a Sample
							</a>
						}
					</div>
				))}
			</div>

			{country === 'uk' && showAttributes.indications ? (
				<div className='c-product__text'>
					<h3>{labels?.pages?.product?.indications}</h3>

					<div className={`c-product__info-wrapper c-product__info-wrapper--expandable js-expandable-indications${infoExpands?.indications ? ' c-product__info-expand' : ''}`}>
						{products.map(product => (
							product?.indications ? (
								<div
									key={product.id}
									className='c-product__info-item'
								>
									{product?.indications
										?.split('\n')
										.map((indication, index) => <p key={index}>{indication}</p>)}
								</div>
							) : null
						))}
					</div>

					{showExpandLabels?.indications ? (
						<h2 onClick={() => handleExpandInfo('indications')}>
							{infoExpands?.indications
								? labels?.pages?.product.collapse
								: labels?.pages?.product.expand}
						</h2>
					) : null}
				</div>
			) : null}

			{showAttributes.directionsForUse ? (
				<div>
					<div className='c-product__text'>
						<h3>{labels?.pages?.product?.directions_for_use}</h3>

						<div className={`c-product__info-wrapper c-product__info-wrapper--expandable js-expandable-directions${infoExpands?.directionsForUse ? ' c-product__info-expand' : ''}`}>
							{products.map(product => (
								product.directionsForUse ? (
									<div
										key={product.id}
										className='c-product__info-item'
									>
										{product.directionsForUse.split('\n').map((direction, index) => (
											<p key={index}>{direction}</p>
										))}
									</div>
								) : null
							))}
						</div>

						{showExpandLabels?.directionsForUse ? (
							<h2 onClick={() => handleExpandInfo('directionsForUse')}>
								{infoExpands?.directionsForUse
									? labels?.pages?.product.collapse
									: labels?.pages?.product.expand}
							</h2>
						) : null}
					</div>
				</div>
			) : null}

			<div className='c-product__text'>
				<h3>{labels?.pages?.product?.storage}</h3>

				<div className='c-product__info-wrapper'>
					{products.map(product => (
						product.storage ? (
							<div
								key={product.id}
								className='c-product__info-item'
							>
								{product.storage.split('\n').map((storageEl, index) => (
									<p key={index}>{storageEl}</p>
								))}
							</div>
						) : null
					))}
				</div>
			</div>

			{showAttributes.contraIndications ? (
				<div>
					<div className='c-product__text'>
						<h3>{labels?.pages?.product?.contra_indications}</h3>

						<div className={`c-product__info-wrapper c-product__info-wrapper--expandable js-expandable-contraindications${infoExpands?.contraIndications ? ' c-product__info-expand' : ''}`}>
							{products.map(product => (
								product.contraIndications ? (
									<div
										key={product.id}
										className='c-product__info-item'
									>
										{product.contraIndications.split('\n').map((contraIndication, index) => (
											<p key={index}>{contraIndication}</p>
										))}
									</div>
								) : null
							))}
						</div>

						{showExpandLabels?.contraIndications && (
							<h2 onClick={() => handleExpandInfo('contraIndications')}>
								{infoExpands?.contraIndications
									? labels?.pages?.product.collapse
									: labels?.pages?.product.expand}
							</h2>
						)}
					</div>
				</div>
			) : null}

			{showAttributes.precautions ? (
				<div>
					<div className='c-product__text'>
						<h3>{labels?.pages?.product?.precautions}</h3>

						<div className={`c-product__info-wrapper c-product__info-wrapper--expandable js-expandable-precautions${infoExpands?.precautions ? ' c-product__info-expand' : ''}`}>
							{products.map(product => (
								product.precautions ? (
									<div
										key={product.id}
										className='c-product__info-item'
									>
										{product.precautions.split('\n').map((precaution, index) => (
											<p key={index}>{precaution}</p>
										))}
									</div>
								) : null
							))}
						</div>

						{showExpandLabels?.precautions ? (
							<h2 onClick={() => handleExpandInfo('precautions')}>
								{infoExpands?.precautions
									? labels?.pages?.product.collapse
									: labels?.pages?.product.expand}
							</h2>
						) : null}
					</div>
				</div>
			) : null}

			{showAttributes.shelfLife ? (
				<div>
					<div className='c-product__text'>
						<h3>{labels?.pages?.product?.shelf_life}</h3>

						<div className='c-product__info-wrapper'>
							{products.map(product => (
								product.shelfLife ? (
									<div
										key={product.id}
										className='c-product__info-item'
									>
										<p>{product.shelfLife}</p>
									</div>
								) : null
							))}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
}

export default DirectionsForUse;
