import { Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';

import labels from '../../../data/labels.json';

function HeaderComparison({onCancelComparison}) {
	return (
		<div className='c-product-header-comparison'>
			<h2>{labels.pages.products.product_comparison_header}</h2>
			<Button
				className='c-filter-list__close'
				onClick={() => onCancelComparison()}
				type='button'>
				<Close />
			</Button>
		</div>
	);
}

export default HeaderComparison;
