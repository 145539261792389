import React, { useContext, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';

function Dropdown({
  field,
  alt
}) {
  const [ state, dispatch ] = useContext(Context);
  const currentValue = state[field.id] || '';

  const handleChange = event => {
    dispatch({
      type: field.id,
      payload: event.target.value,
    });
  };

  useEffect(() => {
    if (currentValue === '') {
      if (field.useConditionalDefaultFromRangeValue) {
        const rangeValue = state[field.useConditionalDefaultFromRangeValue];

        if (rangeValue) {
          const defaultOption = field?.values?.find(e => rangeValue >= e.defaultFor[0] && rangeValue <= e.defaultFor[1]);

          if (defaultOption) {
            dispatch({
              type: field.id,
              payload: defaultOption.value,
            });
          }
        }
      } else {
        const firstItem = field?.defaultOption ? field.values[field.defaultOption].value : field.values && field.values[0] ? field.values[0].value : undefined;
        if (firstItem) {
          dispatch({
            type: field.id,
            payload: firstItem,
          });
        }  
      }
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="c-dropdown u-content-wrapper">
      <FieldHeader
        title={field.title}
        tooltip={field.tooltip}
        labelFor={field.id}
      />
      <div className="e-select">
        <select
          className={`e-select__select ${alt && 'c-dropdown__select--rounded'}`}
          id={field.id}
          name={field.id}
          onChange={handleChange}
          value={currentValue}
        >
          {
            field.values && field.values.length > 0 ?
              field.values.map(val => (
                <option
                  key={val.value}
                  value={val.value}
                >
                  {val.label}
                </option>
              ))
            : null
          }
        </select>
        <ArrowDropDown className="e-select__arrow" />
      </div>
      {
        field.textField &&
        <>
          <TextField
            key={field.id}
            variant="outlined"
            value={currentValue}
            InputProps={{
              readOnly: true,
            }}
          />
        </>
      }
    </div>
  );
}

export default Dropdown;
