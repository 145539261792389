import React, { useContext } from 'react';
import { ArrowDropDown } from '@material-ui/icons';
import Context from '../../components/Context';
import countries from '../../data/forms/countries';

function LanguageSelector() {
  const { country, setCountry } = useContext(Context);
  const handleCountryChange = event => {
    const value = event.target.value;
    setCountry(value);
    localStorage.setItem('userCountry', value);
  };

  return (
    country && countries && countries.length > 0 ?
      <label
        htmlFor="lang_selector"
        className="c-language-selector"
      >
        <span className={`c-language-selector__icon c-language-selector__icon--${country}`} />
        <select
          className="c-language-selector__dropdown"
          onChange={handleCountryChange}
          id="lang_selector"
          name="lang_selector"
          defaultValue={country}
          aria-label="Language selector"
        >
          {
            countries.map(c => (
              <option
                key={c.value}
                value={c.value}
                aria-label={c.label}
              >
                {c.shortLabel}
              </option>
            ))
          }
        </select>
        <ArrowDropDown />
      </label>
      : null
  );
}

export default LanguageSelector;
