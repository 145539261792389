import React, { useEffect, useState, useContext } from 'react'; // eslint-disable-line
import Context from '../../Regimen/Context';
import ranges from '../../../utils/calculation-sets/age-ranges';

function VisibilityWrapper({
  field,
  children,
}) {
  const [ state ] = useContext(Context);
  const { ER_CALCULATION_METHOD, PC_CALCULATION_METHOD, COUNTRY, PD_AGE, PC_TYPE_OF_FEED, BMI, ER_STRESS_FACTOR, PC_FLUID_ADJUST } = state; 
  const { useConditionalVisibility, id } = field;

  const conditions = {
    PC_PENG_PDF: PC_CALCULATION_METHOD === 'peng',
    PD_PENG_PDF: ER_CALCULATION_METHOD === 'peng_2018',
    ENERGY_RESULT_UK_PAEDS_0TO18YEARS: ER_CALCULATION_METHOD === 'uk_paeds_0months_18years',
    ENERGY_RESULT_ROI_PAEDS_UNDER6MONTHS: ER_CALCULATION_METHOD === 'roi_paeds_under_6months',
    ER_ROI_6M17Y: ER_CALCULATION_METHOD === 'roi_paeds_6months_17years',
    ER_ROI_6M17Y_ADJUST: ER_CALCULATION_METHOD === 'roi_paeds_6months_17years',
    ER_CALORIC_EQUATION: ER_CALCULATION_METHOD === 'peng_2018' && BMI < 30,
    ER_CALORIC_TOGGLE: ER_CALCULATION_METHOD === 'peng_2018' && BMI < 30,
    ER_PAL_DIT_CATEGORY: ER_CALCULATION_METHOD === 'peng_2018' || ER_CALCULATION_METHOD === 'schofield' || ER_CALCULATION_METHOD === 'harris_benedict' || ER_CALCULATION_METHOD === 'henry_oxford' || ER_CALCULATION_METHOD === 'mifflin_st_jeor',
    ER_PAL_DIT_TITLE: ER_CALCULATION_METHOD === 'peng_2018' || ER_CALCULATION_METHOD === 'schofield' || ER_CALCULATION_METHOD === 'harris_benedict' || ER_CALCULATION_METHOD === 'henry_oxford' || ER_CALCULATION_METHOD === 'mifflin_st_jeor',
    ER_HARRIS_BENEDICT_ACTIVITY: ER_CALCULATION_METHOD === 'harris_benedict',
    ER_SCHOFIELD_ACTIVITY: ER_CALCULATION_METHOD === 'schofield',
    ER_PENN_TMAX: ER_CALCULATION_METHOD === 'penn_state',
    ER_PENN_VE: ER_CALCULATION_METHOD === 'penn_state',
    ER_SACN_ADJUST: ER_CALCULATION_METHOD === 'sacn',
    ER_STRESS_FACTOR: ER_CALCULATION_METHOD === 'schofield' || ER_CALCULATION_METHOD === 'harris_benedict' || ER_CALCULATION_METHOD === 'henry_oxford',
    ER_STRESS_FACTOR_ADJUST: (ER_CALCULATION_METHOD === 'schofield' || ER_CALCULATION_METHOD === 'harris_benedict' || ER_CALCULATION_METHOD === 'henry_oxford') && (ER_STRESS_FACTOR && ER_STRESS_FACTOR !== ''),
    ER_OVER30_MIFFLIN: ER_CALCULATION_METHOD === 'peng_2018' && BMI >= 30,
    ER_OVER30_MIFFLIN_TEXT: ER_CALCULATION_METHOD === 'peng_2018' && BMI >= 30,
    PC_UK_PAEDS: PC_CALCULATION_METHOD === 'paeds' && COUNTRY === 'uk' && PD_AGE <= ranges.to.eighteenYears,
    PC_ROI_PAEDS: PC_CALCULATION_METHOD === 'paeds' && COUNTRY === 'roi' && state.PD_AGE <= ranges.to.eighteenYears,
    PC_ROI_UNDER6MONTHS: PC_CALCULATION_METHOD === 'paeds' && COUNTRY === 'roi' && PD_AGE <= ranges.to.sixMonths,
    PC_MIN_MAX: PC_CALCULATION_METHOD === 'min_max',
    PC_MIN_MAX_GPERDAY: PC_CALCULATION_METHOD === 'min_max',
    PC_NITROGEN: PC_CALCULATION_METHOD === 'nitrogen',
    PC_NITROGEN_TITLE: PC_CALCULATION_METHOD === 'nitrogen',
    PC_NITROGEN_RESULT: PC_CALCULATION_METHOD === 'nitrogen',
    PC_UK_ADULTS: PC_CALCULATION_METHOD === 'peng',
    PC_PENG_TOGGLE: PC_CALCULATION_METHOD === 'peng',
    PC_PROTEIN_RESULT: PC_CALCULATION_METHOD === 'peng' || PC_CALCULATION_METHOD === 'paeds',
    PC_CATEGORY_OF_FEED: PC_TYPE_OF_FEED !== '',
    PC_FLUID_ADJUST_RESULT: PC_FLUID_ADJUST,
  };

  const [ visible, updateVisibility ] = useState(conditions[id]);

  useEffect(() => {
    updateVisibility(conditions[id]);

    // eslint-disable-next-line
  }, [ state ]);

  if (!useConditionalVisibility) {
    // don't apply to fields that don't have specific rules.
    return children || null;
  }
  return visible ? children : null;
}

export default VisibilityWrapper;
