import React, { memo } from 'react';
import sendIcon from '../../../images/chatbot/send-icon.png';

function ChatbotSearch({ placeholder, onSubmit }) {

	return (
        <section className="chatbot-container">
            <form className='chatbot__search-input' onSubmit={onSubmit}>
                <input name="chatbotSearchTerm" placeholder={placeholder} />
                <button type="submit">
                    <img src={sendIcon} alt="Send message" />
                </button>
            </form>
        </section>
	);
}

export default memo(ChatbotSearch);
          