import React from 'react';
import {
    AssignmentIndOutlined,
    Description,
    HomeOutlined,
    DescriptionOutlined as DocumentIcon,
    MobileScreenShare,
    NotificationsActive,
    Phone,
    Search,
} from '@material-ui/icons';

import { ReactComponent as SupportIcon } from '../images/support-icon.svg';

import ROUTES from '../consts/routes.json';
import labels from './labels.json';

import Contact from '../templates/Contact';
import CreateRegimen from '../templates/CreateRegimen';
import Notifications from '../templates/Notifications';
import Products from '../templates/Products';
import Resources from '../templates/Resources';
import Support from '../templates/Support';
import SaveToHomescreen from '../templates/SaveToHomescreen';
import TextPage from '../templates/TextPage';
import Welcome from '../templates/Welcome';
import Chatbot from '../templates/Chatbot';

import privacyData from '../data/pages/privacy-policy';
import termsData from '../data/pages/terms';

const data = [
    {
        "id": "welcome",
        "path": ROUTES.WELCOME,
        "template": Welcome,
        "exact": true,
        "navIcon": <HomeOutlined />,
        "pageTitle": labels?.pages?.welcome?.page_title,
        "subTitle": labels?.pages?.welcome?.subtitle,
        "description": labels?.pages?.welcome?.text,
    },
    {
        "id": "regimen",
        "path": ROUTES.CREATE_REGIMEN,
        "template": CreateRegimen,
        "navIcon": <AssignmentIndOutlined />,
        "hidePageHeader": true,
    },
    {
        "id": "products",
        "path": ROUTES.PRODUCTS,
        "template": Products,
        "navIcon": <Search />,
        "hidePageHeader": true,
    },
    {
        "id": "resources",
        "path": ROUTES.RESOURCES,
        "template": Resources,
        "exact": true,
        "navIcon": <DocumentIcon />,
        "pageTitle": labels?.pages?.resources?.page_title,
        "subTitle": labels?.pages?.resources?.subtitle,
        "description": labels?.pages?.resources?.text,
        "hidePageHeader": true,
    },
    {
        "id": "save_app",
        "path": ROUTES.SUPPORT_SAVE_APP,
        "template": SaveToHomescreen,
        "exact": true,
        "navIcon": <MobileScreenShare />,
        "pageTitle": "Save to home screen",
        // "subTitle": labels?.pages?.resources?.subtitle,
        "description": "This website has app functionality. Add to it your home screen to use it in full screen and while you are offline.",
    },
    //re-instate when onesignal integrated again
    {
        "id": "notifications",
        "path": ROUTES.SUPPORT_NOTIFICATIONS,
        "template": Notifications,
        "exact": true,
        "navIcon": <NotificationsActive />,
        "pageTitle": labels?.pages?.notifications?.page_title,
        "subTitle": labels?.pages?.notifications?.subtitle,
        "description": labels?.pages?.notifications?.text,
    },
    {
        "id": "terms",
        "path": ROUTES.SUPPORT_TERMS_AND_CONDITIONS,
        "template": TextPage,
        "exact": true,
        "pageTitle": labels?.pages?.terms?.page_title,
        "subTitle": labels?.pages?.terms?.subtitle,
        "description": labels?.pages?.terms?.text,
        "navIcon": <Description />,
        "content": termsData,
    },
    {
        "id": "privacy",
        "path": ROUTES.SUPPORT_PRIVACY,
        "template": TextPage,
        "exact": true,
        "navIcon": <Description />,
        "pageTitle": labels?.pages?.privacy?.page_title,
        "subTitle": labels?.pages?.privacy?.subtitle,
        "description": labels?.pages?.privacy?.text,
        "content": privacyData,
    },
    {
        "id": "contact",
        "path": ROUTES.SUPPORT_CONTACT_US,
        "template": Contact,
        "exact": true,
        "navIcon": <Phone />,
        "pageTitle": labels?.pages?.contact?.page_title,
        "subTitle": labels?.pages?.contact?.subtitle,
        "description": labels?.pages?.contact?.text,
    },
    {
        "id": "support",
        "path": ROUTES.SUPPORT,
        "template": Support,
        "navIcon": <SupportIcon />,
        "pageTitle": labels?.pages?.support?.page_title,
        "subTitle": labels?.pages?.support?.subtitle,
        "description": labels?.pages?.support?.text,
        "hidePageHeader": true,
    },
    {
        "id": "region",
        "path": ROUTES.SUPPORT_REGION,
        "template": Contact,
        "exact": true,
        "navIcon": <Phone />,
        "pageTitle": labels?.pages?.contact?.page_title,
        "subTitle": labels?.pages?.contact?.subtitle,
        "description": labels?.pages?.contact?.text,
    },
    {
        "id": "chatbot",
        "path": ROUTES.CHATBOT_LANDING,
        "template": Chatbot,
        "exact": true,
        "navIcon": <Description />,
        "hidePageHeader": true,
        "description": labels?.pages?.chatbot?.description,
    },
    {
        "id": "product-query",
        "path": ROUTES.CHATBOT_PRODUCT,
        "template": Chatbot,
        "exact": true,
        "navIcon": <Description />,
        "hidePageHeader": true,
        "description": labels?.pages?.chatbot?.description,
    },
    {
        "id": "general-query",
        "path": ROUTES.CHATBOT_GENERAL,
        "template": Chatbot,
        "exact": true,
        "navIcon": <Description />,
        "hidePageHeader": true,
        "description": labels?.pages?.chatbot?.description,
    },
    {
        "id": "stock-query",
        "path": ROUTES.CHATBOT_STOCK,
        "template": Chatbot,
        "exact": true,
        "navIcon": <Description />,
        "hidePageHeader": true,
        "description": labels?.pages?.chatbot?.description,
    },
    {
        "id": "request-query",
        "path": ROUTES.CHATBOT_REQUEST,
        "template": Chatbot,
        "exact": true,
        "navIcon": <Description />,
        "hidePageHeader": true,
        "description": labels?.pages?.chatbot?.description,
    },
    {
        "id": "chatbot-faq",
        "path": ROUTES.CHATBOT_FAQ,
        "template": Chatbot,
        "exact": true,
        "navIcon": <Description />,
        "hidePageHeader": true,
        "description": labels?.pages?.chatbot?.description,
    }
];

export default data;
