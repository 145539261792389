import React, { useContext } from 'react';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import labels from '../../../data/labels.json';
import Context from '../../Regimen/Context';

function Datepicker({
  field,
}) {
  const { dob_picker } = labels?.pages?.regimen;
  const [ state, dispatch ] = useContext(Context);
  const currentValue = new Date(state[field.id]) || new Date();
  const handleChange = event => {
    dispatch({
      type: field.id,
      payload: event.target.value,
    });
  };

  return (
    <KeyboardDatePicker
      margin="normal"
      id="date-picker-dialog"
      label={field.title}
      format="dd/MM/yyyy"
      value={currentValue}
      onChange={handleChange}
      KeyboardButtonProps={{
        'aria-label': dob_picker,
      }}
    />
  );
}

export default Datepicker;
