import React, { useContext, useState, lazy, Suspense } from 'react';
import Context from '../../Regimen/Context';
import ROUTES from '../../../consts/routes.json';

const ProductCard = lazy(() => import('../../ProductSearch/ProductCard'));
const Modal = lazy(() => import('../ProductComparison/FilteredList/Modal'));


function SummaryBlock() {
  const [ state, dispatch ] = useContext(Context);

  const chosenProducts = state.PC_SELECTED_PRODUCTS || [];

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  const removeProduct = (id) => {
    if (!id) return;

    let newArr = [...chosenProducts];
        newArr = newArr.filter(e => e.id !== id);

    dispatch({
      type: 'PC_SELECTED_PRODUCTS',
      payload: newArr,
    });
  };

  const handleClose = () => {
    setModalOpen(false);
  }

  const editAmount = (product) => {
    const newProduct = {
      ...product,
      volume: 0,
      volumeUnit: '',
    };
    setSelectedProduct(newProduct);
    setModalOpen(true);
  };

  const confirmEditAmount = (product, volume, volumeUnit) => {
 
    let newArr = [...chosenProducts];
    let newProduct = {...selectedProduct};
    newProduct.volume = volume;
    newProduct.volumeUnit = volumeUnit;
    
    for (let i = 0; i < newArr.length; i++){
      if (newArr[i].id === selectedProduct.id){
        newArr[i] = newProduct;
        break;
      }
      continue;
    }

    dispatch({
      type: 'PC_SELECTED_PRODUCTS',
      payload: newArr,
    });

    setSelectedProduct();
    setModalOpen(false);
  };

 

  return (
    <div>
      {
        (chosenProducts && chosenProducts.length > 0) ?
          <div className="c-selected-products">
            <ul className="c-selected-products__container">
              {
                chosenProducts.map(product => (
                  <li key={product.id} className="c-selected-products__card-wrapper">
                    <Suspense fallback={<div style={{ boxShadow: '0 1px 3px #00000029', backgroundColor: '#FFF', borderRadius: '4px', height: '210px', display: 'block' }}/>}>
                      <ProductCard
                        id={product.id}
                        url={`/regimen${ROUTES.PRODUCTS}/${product.id}`}
                        name={product.name}
                        packSize={product.packSize}
                        image={product.images[0]?.url}
                        volume={product.volume}
                        volumeUnit={product.volumeUnit}
                        handleClick={removeProduct}
                        mini
                        editAmount={true}
                        handleEditAmount={() => editAmount(product)}
                      />
                    </Suspense>
                  </li>
                ))
              }
            </ul>
          </div>
        : null
      }
      {modalOpen &&
        <Modal
          handleClose={handleClose}
          handleAddProductToComparison={confirmEditAmount}
          selectedProduct={selectedProduct}
          design="modal"
          altSubmitLabel={"Update Product"}
        />
      }
    </div>
  );
}

export default SummaryBlock;
