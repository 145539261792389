/* eslint-disable  react-hooks/exhaustive-deps */ // Disabling for entire file as there are quite a few false positives
import React, { createContext, useEffect, useReducer, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { get, keys } from 'idb-keyval';

import steps from '../../data/forms/create-regimen';
import {
    BMI,
    pengCalculator,
    BMRCalculator,
    SACNCalculator,
    energyUK0to18Years,
    energyROI6MonthsTo17Years,
    schofieldCalculator,
    pennCalculator,
    nitrogenLowCalculator,
    nitrogenAverageCalculator,
    nitrogenHighCalculator,
    proteinMinCalculator,
    proteinMaxCalculator,
    henryOxfordCalculator,
    harrisBenedictCalculator,
} from '../../utils/calculators';
import calculateFluidPerKG from '../../utils/calculation-sets/fluid-requirements';
import UKProteinPaeds from '../../utils/calculation-sets/protein/uk/under18';
import { products as ProductsStore } from '../../utils/db';
import { getRegimenStepItems } from '../../utils';
import ranges from '../../utils/calculation-sets/age-ranges';

const initialState = {
    navigationItems: steps.filter(step => !step.hiddenFromNavigation).map(step => ({
        id: step.id,
        component: step.component,
        exact: step.exact,
        dependant: step.dependant,
        navigation_title: step.navigation_title,
        disabled: step.disabled,
        url: step.url,
        complete: step.complete,
        optional: step.optional,
        requiredFields: step.fields.filter(step => step.required).map(step => step.id),
    })),
    error: null,
};

const reducer = (state, action) => {
    let currentNav;
    let foundIndex;

    switch (action.type) {
        case 'RESET_STATE':
            return {
                ...initialState,
            };
        case 'MAKE_VALID':
            currentNav = getRegimenStepItems(state);
            foundIndex = currentNav.findIndex(e => e.id === action.payload);

            currentNav[foundIndex].complete = true;

            return {
                ...state,
                navigationItems: currentNav,
            };
        case 'MAKE_INVALID':
            currentNav = getRegimenStepItems(state);
            foundIndex = currentNav.findIndex(e => e.id === action.payload);

            currentNav[foundIndex].complete = false;

            return {
                ...state,
                navigationItems: currentNav,
            };
        case 'NAVIGATION':
            const newItems = getRegimenStepItems(state);
            return {
                ...state,
                navigationItems: newItems,
            };
        case 'UPDATE_STEP_STATUS':
            currentNav = getRegimenStepItems(state);
            foundIndex = currentNav.findIndex(e => e.id === action.payload);
            currentNav[foundIndex].complete = true;

            return {
                ...state,
                navigationItems: currentNav,
            };
        default:
            return {
                ...state,
                [action.type]: action.payload,
            };
    }
};

export const ContextProvider = ({
    children,
    country,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const location = useLocation();

    // ==================================
    // Product data
    // ==================================

    useEffect(() => {
        let promises = [];

        keys(ProductsStore)
            .then(keys => {
                for (let i = 0; i < keys.length; i++) {
                    promises.push(
                        get(keys[i], ProductsStore)
                            .then(product => product)
                            .catch(err => console.log(err))
                    );
                }
            })
            .then(() => {
                Promise.all(promises)
                    .then(data => {
                        let localisedData = data;

                        if (localisedData && localisedData.length > 0) {
                            localisedData = localisedData.filter(e => {
                                const value = e?.productAvailability?.key;
                                return value === 'both' || value === country;
                            });
                        }

                        dispatch({
                            type: 'LOADING',
                            payload: false,
                        });

                        dispatch({
                            type: 'DEFAULT_PRODUCT_LIST',
                            payload: localisedData,
                        });

                        dispatch({
                            type: 'PRODUCT_RESULTS',
                            payload: localisedData,
                        });
                    })
                    .catch(error => console.log(error));
            });
    }, []);

    useEffect(() => {
        let results = state.DEFAULT_PRODUCT_LIST || [];

        if (state.FILTER_AGE) {
            results = results.filter(e => e.categories.find(c => c.slug === state.FILTER_AGE));
        }

        if (state.FILTER_CATEGORY) {

            results = results.filter(e => e.categories.find(c => c.slug === state.FILTER_CATEGORY));
        }

        if (state.FILTER_METABOLICS) {
            results = results.filter(e => e.categories.find(c => c.slug === state.FILTER_METABOLICS));
        }

        dispatch({
            type: 'PRODUCT_RESULTS',
            payload: results,
        });
    }, [state.FILTER_AGE, state.FILTER_CATEGORY, state.FILTER_METABOLICS]);

    // ==================================
    // Energy Calculators
    // ==================================

    useEffect(() => {
        const age = state.PD_AGE;
        const ROIPaedsUnder6Months = country === 'roi' && age <= ranges.to.sixMonths;
        const ROIPaeds6MonthsTo18Years = country === 'roi' && (age >= ranges.from.sixMonths && age <= ranges.to.eighteenYears);
        const UKPaeds = country === 'uk' && age <= ranges.to.eighteenYears;

        const payload = [
            {
                "value": "roi_paeds_under_6months",
                "label": "Paediatric requirements (ROI)",
                "active": ROIPaedsUnder6Months,
            },
            {
                "value": "roi_paeds_6months_17years",
                "label": "Paediatric requirements (ROI)",
                "active": ROIPaeds6MonthsTo18Years,
            },
            {
                "value": "uk_paeds_0months_18years",
                "label": "Paediatric requirements (UK)",
                "active": UKPaeds,
            },
            {
                "value": "peng_2018",
                "label": "PENG",
                "active": true,
            },
            {
                "value": "mifflin_st_jeor",
                "label": "Mifflin St Jeor",
                "active": true,
                // "active": state.BMI > 30 && !ROIPaedsUnder6Months && !ROIPaeds6MonthsTo18Years && !UKPaedsUnder12Months && !UKPaeds,
            },
            {
                "value": "harris_benedict",
                "label": "Harris Benedict",
                "active": true,
            },
            {
                "value": "henry_oxford",
                "label": "Henry Oxford",
                "active": true,
            },
            {
                "value": "schofield",
                "label": "Schofield",
                "active": true,
            },
            {
                "value": "penn_state",
                "label": "Penn State",
                "active": true,
            },
            {
                "value": "sacn",
                "label": "SACN (EARs)",
                "active": true,
            }
        ].filter(e => e.active);

        dispatch({
            type: 'ENERGY_CALCULATORS',
            payload,
        });

        dispatch({
            type: 'ER_CALCULATION_METHOD',
            payload: payload[0].value,
        });

    }, [
        state.PD_AGE,
        country,
        state.BMI,
    ]);

    // ==================================
    // BMI Calculation
    // ==================================

    useEffect(() => {
        const payload = BMI({
            height: state.PD_HEIGHT / 100,
            weight: state.PD_WEIGHT,
        });

        dispatch({
            type: 'BMI',
            payload,
        });

    }, [state.PD_WEIGHT, state.PD_HEIGHT]);

    // ==================================
    // Weight Difference Calculation
    // ==================================

    useEffect(() => {
        const currentWeight = Number(state.PD_WEIGHT);
        const previousWeight = Number(state.PD_WEIGHT_PREVIOUS);
        const difference = currentWeight - previousWeight;
        const result = difference / previousWeight * 100;

        dispatch({
            type: 'WEIGHT_DIFFERENCE',
            payload: state.PD_WEIGHT && state.PD_WEIGHT_PREVIOUS ? `${result > 0 ? '+' : ''}${result.toFixed(1)}%` : `0%`,
        });

    }, [state.PD_WEIGHT, state.PD_WEIGHT_PREVIOUS]);

    // ==================================
    // Energy: PENG
    // ==================================

    useEffect(() => {
        let payload;

        if (state.BMI >= 30) {
            // use mifflin st jeor 
            payload = BMRCalculator({
                height: state.PD_HEIGHT || 0,
                weight: state.PD_WEIGHT || 0,
                age: state.PD_AGE / 12 || 0,
                gender: state.PD_GENDER,
            });
        } else {
            payload = pengCalculator({
                weight: state.PD_WEIGHT || 0,
                caloricEquation: (state.ER_CALORIC_TOGGLE === 'max' ? state.ER_CALORIC_EQUATION_MAX : state.ER_CALORIC_EQUATION_MIN) || 10,
            });

            const minResult = pengCalculator({
                weight: state.PD_WEIGHT || 0,
                caloricEquation: state.ER_CALORIC_EQUATION_MIN,
            });

            const maxResult = pengCalculator({
                weight: state.PD_WEIGHT || 0,
                caloricEquation: state.ER_CALORIC_EQUATION_MAX,
            });

            dispatch({
                type: 'ENERGY_RESULT_PENG_PREADJUST',
                payload: `${minResult || 0} - ${maxResult || 0}`,
            });
        }

        payload = payload * (state.ER_PAL_DIT_CATEGORY || 1);

        dispatch({
            type: 'ENERGY_RESULT_PENG',
            payload,
        });

    }, [
        state.PD_GENDER,
        state.PD_AGE,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
        state.ER_CALORIC_EQUATION_MIN,
        state.ER_CALORIC_EQUATION_MAX,
        state.ER_CALORIC_TOGGLE,
        state.ER_PAL_DIT_CATEGORY,
        state.ER_ENERGY_INTAKE,
        country,
    ]);

    // ==================================
    // Energy: Mifflin St Jeor
    // ==================================

    useEffect(() => {
        let payload = Math.round(BMRCalculator({
            height: state.PD_HEIGHT || 0,
            weight: state.PD_WEIGHT || 0,
            age: state.PD_AGE / 12 || 0,
            gender: state.PD_GENDER,
        }));

        dispatch({
            type: 'ENERGY_RESULT_MIFFLINSTJEOR_BASE',
            payload
        });

        payload = Math.round(payload * (state.ER_PAL_DIT_CATEGORY || 1));

        dispatch({
            type: 'ENERGY_RESULT_MIFFLINSTJEOR',
            payload,
        });

    }, [
        state.PD_WEIGHT,
        state.PD_HEIGHT,
        state.PD_AGE,
        state.PD_GENDER,
        state.ER_PAL_DIT_CATEGORY,
    ]);

    // ==================================
    // Energy: Schofield
    // ==================================

    useEffect(() => {
        let payload = schofieldCalculator({
            weight: state.PD_WEIGHT || 0,
            age: state.PD_AGE || 0,
            gender: state.PD_GENDER,
        });

        if (state.ER_STRESS_FACTOR_ADJUST) {
            const stressFactorAdjust = (payload / 100) * Number(state.ER_STRESS_FACTOR_ADJUST);
            payload = payload + stressFactorAdjust;
        }

        if (state.ER_PAL_DIT_CATEGORY) {
            payload = payload * (state.ER_PAL_DIT_CATEGORY || 1);
        }

        dispatch({
            type: 'ENERGY_RESULT_SCHOFIELD',
            payload,
        });

    }, [
        state.PD_HEIGHT,
        state.PD_AGE,
        state.PD_GENDER,
        state.ER_STRESS_FACTOR_ADJUST,
        state.ER_PAL_DIT_CATEGORY,
    ]);

    // ==================================
    // Energy: Henry Oxford
    // ==================================

    useEffect(() => {
        let payload = henryOxfordCalculator({
            weight: state.PD_WEIGHT || 0,
            age: state.PD_AGE || 0,
            gender: state.PD_GENDER,
        });

        if (state.ER_STRESS_FACTOR_ADJUST) {
            const stressFactorAdjust = (payload / 100) * Number(state.ER_STRESS_FACTOR_ADJUST);
            payload = payload + stressFactorAdjust;
        }

        if (state.ER_PAL_DIT_CATEGORY) {
            payload = payload * (state.ER_PAL_DIT_CATEGORY || 1);
        }

        dispatch({
            type: 'ENERGY_RESULT_HENRY_OXFORD',
            payload,
        });

    }, [
        state.PD_HEIGHT,
        state.PD_AGE,
        state.PD_GENDER,
        state.ER_STRESS_FACTOR_ADJUST,
        state.ER_PAL_DIT_CATEGORY,
    ]);

    // ==================================
    // Energy: Harris Benedict
    // ==================================

    useEffect(() => {
        let payload = harrisBenedictCalculator({
            height: state.PD_HEIGHT || 0,
            weight: state.PD_WEIGHT || 0,
            age: state.PD_AGE || 0,
            gender: state.PD_GENDER,
        });

        if (state.ER_STRESS_FACTOR_ADJUST) {
            const stressFactorAdjust = (payload / 100) * Number(state.ER_STRESS_FACTOR_ADJUST);
            payload = payload + stressFactorAdjust;
        }

        if (state.ER_PAL_DIT_CATEGORY) {
            payload = payload * (state.ER_PAL_DIT_CATEGORY || 1);
        }

        dispatch({
            type: 'ENERGY_RESULT_HARRISBENEDICT',
            payload: payload,
        });

    }, [
        state.PD_WEIGHT,
        state.PD_HEIGHT,
        state.PD_AGE,
        state.PD_GENDER,
        state.ER_STRESS_FACTOR_ADJUST,
        state.ER_PAL_DIT_CATEGORY,
    ]);

    // ==================================
    // Energy: Penn State
    // ==================================

    useEffect(() => {
        const payload = pennCalculator({
            height: state.PD_HEIGHT || 0,
            weight: state.PD_WEIGHT || 0,
            age: state.PD_AGE / 12 || 0,
            gender: state.PD_GENDER,
            bmi: state.BMI,
            tmax: state.ER_PENN_TMAX || 0,
            ve: state.ER_PENN_VE || 0,
        });

        dispatch({
            type: 'ENERGY_RESULT_PENN',
            payload: payload,
        });

    }, [
        state.PD_WEIGHT,
        state.PD_HEIGHT,
        state.PD_AGE,
        state.PD_GENDER,
        state.ER_PENN_TMAX,
        state.ER_PENN_VE,
    ]);

    // ==================================
    // Energy: SACN
    // ==================================

    useEffect(() => {
        const bmr = SACNCalculator({
            age: state.PD_AGE || 0,
            gender: state.PD_GENDER,
        });

        const payload = (Number(state.ER_SACN_ADJUST) || 1) * bmr;

        dispatch({
            type: 'ENERGY_RESULT_SACN',
            payload: payload,
        });

    }, [
        state.PD_WEIGHT,
        state.PD_HEIGHT,
        state.PD_AGE,
        state.PD_GENDER,
        state.ER_SACN_ADJUST,
    ]);

    // ==================================
    // Energy: UK Paeds 0 - 18 years
    // ==================================

    useEffect(() => {
        const values = energyUK0to18Years({
            age: state.PD_AGE || 0,
            gender: state.PD_GENDER,
        });

        dispatch({
            type: 'ENERGY_RESULT_UK_0to18_VALUES',
            payload: values,
        });

        const KcalResult = Math.floor((Number(values?.kcal_day || 0) / 100) * Number(state.ENERGY_RESULT_UK_0to18_KCAL_SLIDER_TOTAL || 0));
        const KcalKGResult = Math.floor((Number(values?.kcal_kg_day || 0) * Number(state.PD_WEIGHT || 0)) / 100 * Number(state.ENERGY_RESULT_UK_0to18_KCALKG_SLIDER_TOTAL || 0));
        const payload = state.PD_AGE <= ranges.to.oneYear || state.ENERGY_RESULT_UK_0to18_ACTIVE_TAB !== 0 ? KcalKGResult : KcalResult;

        dispatch({
            type: 'ENERGY_RESULT_UK_PAEDS_0TO18YEARS',
            payload,
        });

    }, [
        state.PD_AGE,
        state.PD_GENDER,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
        state.ENERGY_RESULT_UK_0to18_KCAL_SLIDER_TOTAL,
        state.ENERGY_RESULT_UK_0to18_KCALKG_SLIDER_TOTAL,
        state.ENERGY_RESULT_UK_0to18_ACTIVE_TAB,
    ]);

    // ==================================
    // Energy: ROI Paeds under 6 months
    // ==================================

    useEffect(() => {
        const values = energyUK0to18Years({ // Need to use UK data
            age: state.PD_AGE || 0,
            gender: state.PD_GENDER,
        });

        dispatch({
            type: 'ENERGY_RESULT_ROI_UNDER6MONTHS_VALUES',
            payload: values,
        });

        const payload = Math.floor((Number(values?.kcal_kg_day || 0) * Number(state.PD_WEIGHT || 0)) / 100 * Number(state.ENERGY_RESULT_ROI_UNDER6MONTHS_KCALKG_SLIDER_TOTAL || 0));

        dispatch({
            type: 'ENERGY_RESULT_ROI_PAEDS_UNDER6MONTHS',
            payload,
        });

    }, [
        state.PD_AGE,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
        state.PD_GENDER,
        state.ENERGY_RESULT_ROI_UNDER6MONTHS_KCALKG_SLIDER_TOTAL,
    ]);

    // ============================================
    // Energy: ROI Paeds 6 Months - 17 Years
    // ============================================

    useEffect(() => {
        const result = energyROI6MonthsTo17Years({
            pal: state.ER_ROI_6M17Y,
            age: state.PD_AGE,
            gender: state.PD_GENDER,
        });

        const payload = typeof result === 'number' ? Math.round(((result || 0) / 100) * (state.ER_ROI_6M17Y_ADJUST || 100)) : result;

        dispatch({
            type: 'ENERGY_RESULT_ROI_PAEDS_6M17Y_TOTAL',
            payload,
        });

    }, [
        state.PD_AGE,
        state.PD_GENDER,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
        state.ER_ROI_6M17Y,
        state.ER_ROI_6M17Y_ADJUST,
    ]);

    // ==================================
    // Protein Calculators
    // ==================================

    useEffect(() => {
        const payload = [
            {
                "value": "paeds",
                "label": country === 'roi' ? "Paediatric requirements (ROI)" : "Paediatric requirements (UK)",
                "active": state.PD_AGE <= ranges.to.eighteenYears,
            },
            {
                "value": "peng",
                "label": "PENG",
                "active": true,
            },
            {
                "value": "min_max",
                "label": "Min-Max g/kg/day",
                "active": true,
            },
            {
                "value": "nitrogen",
                "label": "Nitrogen",
                "active": true,
            },
        ].filter(e => e.active);

        dispatch({
            type: 'PROTEIN_CALCULATORS',
            payload,
        });

        dispatch({
            type: 'PC_CALCULATION_METHOD',
            payload: payload[0].value,
        });

    }, [state.PD_AGE]);

    // ==================================
    // Protein: Irish Adults Only
    // ==================================

    useEffect(() => {
        const minWeight = state.PC_MINIMUM || 0;
        const maxWeight = state.PC_MAXIMUM || 0;
        const currentWeight = state.PD_WEIGHT || 0;
        const BMIValue = state.BMI || 0;

        const minResult = proteinMinCalculator({
            minWeight,
            currentWeight,
            BMIValue,
        });

        const maxResult = proteinMaxCalculator({
            maxWeight,
            currentWeight,
            BMIValue,
        });

        dispatch({
            type: 'PROTEIN_IRISH_ADULTS_MINIMUM',
            payload: minResult,
        });

        dispatch({
            type: 'PROTEIN_IRISH_ADULTS_MAXIMUM',
            payload: maxResult,
        });

    }, [
        state.PC_MINIMUM,
        state.PC_MAXIMUM,
        state.PD_AGE,
        state.PD_GENDER,
        state.PD_WEIGHT,
        state.PD_WEIGHT,
        state.BMI,
    ]);

    // ==================================
    // Protein: UK Paeds Only
    // ==================================

    useEffect(() => {
        const proteinPerDay = UKProteinPaeds({
            age: state.PD_AGE,
            gender: state.PD_GENDER,
        });

        dispatch({
            type: 'PROTEIN_RESULT_UK_018_VALUE',
            payload: proteinPerDay,
        });

        const result = (Number(proteinPerDay || 0) / 100) * Number(state.PROTEIN_RESULT_UK_018_GPERDAY_SLIDER || 0);

        dispatch({
            type: 'PROTEIN_RESULT_UK_018_GPERDAY_TOTAL',
            payload: result,
        });

    }, [
        state.PD_AGE,
        state.PD_GENDER,
        state.PROTEIN_RESULT_UK_018_GPERDAY_SLIDER,
    ]);

    useEffect(() => {
        dispatch({
            type: 'PROTEIN_RESULT_UK_018_KGPERDAY_TOTAL',
            payload: Number(state.PROTEIN_RESULT_UK_018_KGPERDAY || 0) * Number(state.PD_WEIGHT || 0),
        });

    }, [
        state.PD_AGE,
        state.PD_GENDER,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
        state.PROTEIN_RESULT_UK_018_KGPERDAY,
    ]);

    // ==================================
    // Nitrogen Calculations
    // ==================================

    useEffect(() => {
        const nitrogenLevel = state.PC_NITROGEN || 0;
        const weightInKG = state.PD_WEIGHT || 0;
        const BMIValue = state.BMI || 0;

        const resultLowest = nitrogenLowCalculator({
            nitrogenLevel,
            weightInKG,
            BMIValue,
        });

        const resultAverage = nitrogenAverageCalculator({
            nitrogenLevel,
            weightInKG,
            BMIValue,
        });

        const resultHigh = nitrogenHighCalculator({
            nitrogenLevel,
            weightInKG,
            BMIValue,
        });

        dispatch({
            type: 'NITROGEN_LOWEST',
            payload: resultLowest,
        });

        dispatch({
            type: 'NITROGEN_AVERAGE',
            payload: resultAverage,
        });

        dispatch({
            type: 'NITROGEN_HIGHEST',
            payload: resultHigh,
        });

    }, [
        state.PC_NITROGEN,
        state.PD_AGE,
        state.PD_GENDER,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
        state.BMI,
    ]);

    // ==================================
    // Fluid Calculations
    // ==================================

    useEffect(() => {
        const payload = calculateFluidPerKG(state.PD_AGE);

        dispatch({
            type: 'FLUID_PER_KG',
            payload,
        });
    }, [
        state.PD_AGE,
    ]);

    useEffect(() => {
        const payload = Number(state.PD_WEIGHT || 0) * Number(state.FLUID_PER_KG || 0);

        dispatch({
            type: 'FLUID_RESULT',
            payload,
        });
    }, [
        state.FLUID_PER_KG,
        state.PD_AGE,
        state.PD_GENDER,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
    ]);

    useEffect(() => {
        const payload = Number(state.PD_WEIGHT || 0) * Number(state.PC_FLUID_ADJUST || 0);

        dispatch({
            type: 'FLUID_RESULT_ADJUST',
            payload,
        });

    }, [
        state.PD_AGE,
        state.PD_GENDER,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
        state.PC_FLUID_ADJUST,
    ]);

    // ==================================
    // ENERGY RESULTS
    // ==================================

    const [energyResult, setEnergyResult] = useState(0);

    useEffect(() => {
        const {
            ENERGY_RESULT_MIFFLINSTJEOR,
            ENERGY_RESULT_PENG,
            ENERGY_RESULT_HENRY_OXFORD,
            ENERGY_RESULT_HARRISBENEDICT,
            ENERGY_RESULT_ROI_PAEDS_UNDER6MONTHS,
            ENERGY_RESULT_ROI_PAEDS_6M17Y_TOTAL,
            ENERGY_RESULT_UK_PAEDS_0TO18YEARS,
            ENERGY_RESULT_SCHOFIELD,
            ENERGY_RESULT_PENN,
            ENERGY_RESULT_SACN,
            ER_CALCULATION_METHOD,
        } = state;

        const selectedMethod = () => {
            switch (ER_CALCULATION_METHOD) {
                case 'roi_paeds_under_6months':
                    return ENERGY_RESULT_ROI_PAEDS_UNDER6MONTHS > 0 ? Math.round(ENERGY_RESULT_ROI_PAEDS_UNDER6MONTHS) : 0;
                case 'roi_paeds_6months_17years':
                    return ENERGY_RESULT_ROI_PAEDS_6M17Y_TOTAL > 0 ? Math.round(ENERGY_RESULT_ROI_PAEDS_6M17Y_TOTAL) : 0;
                case 'uk_paeds_0months_18years':
                    return ENERGY_RESULT_UK_PAEDS_0TO18YEARS > 0 ? Math.round(ENERGY_RESULT_UK_PAEDS_0TO18YEARS) : 0;
                case 'sacn':
                    return ENERGY_RESULT_SACN > 0 ? Math.round(ENERGY_RESULT_SACN) : 0;
                case 'penn_state':
                    return ENERGY_RESULT_PENN > 0 ? Math.round(ENERGY_RESULT_PENN) : 0;
                case 'schofield':
                    return ENERGY_RESULT_SCHOFIELD > 0 ? Math.round(ENERGY_RESULT_SCHOFIELD) : 0;
                case 'henry_oxford':
                    return ENERGY_RESULT_HENRY_OXFORD > 0 ? Math.round(ENERGY_RESULT_HENRY_OXFORD) : 0;
                case 'harris_benedict':
                    return ENERGY_RESULT_HARRISBENEDICT > 0 ? Math.round(ENERGY_RESULT_HARRISBENEDICT) : 0;
                case 'mifflin_st_jeor':
                    return ENERGY_RESULT_MIFFLINSTJEOR > 0 ? Math.round(ENERGY_RESULT_MIFFLINSTJEOR) : 0;
                case 'peng_2018':
                    return ENERGY_RESULT_PENG > 0 ? Math.round(ENERGY_RESULT_PENG) : 0;
                default:
                    return '-';
            }
        };

        setEnergyResult(selectedMethod);

    }, [
        state.ER_CALCULATION_METHOD,
        state.COUNTRY,
        state.ENERGY_RESULT_MIFFLINSTJEOR,
        state.ENERGY_RESULT_PENG,
        state.ENERGY_RESULT_HENRY_OXFORD,
        state.ENERGY_RESULT_HARRISBENEDICT,
        state.ENERGY_RESULT_ROI_PAEDS_UNDER6MONTHS,
        state.ENERGY_RESULT_ROI_PAEDS_6M17Y_TOTAL,
        state.ENERGY_RESULT_UK_PAEDS_0TO18YEARS,
        state.ENERGY_RESULT_SCHOFIELD,
        state.ENERGY_RESULT_PENN,
        state.ENERGY_RESULT_SACN,
        state.PD_AGE,
        state.PD_HEIGHT,
        state.PD_WEIGHT,
        state.PD_GENDER,
    ]);

    useEffect(() => {
        dispatch({
            type: 'PATIENT_ENERGY_RESULT',
            payload: energyResult,
        })
    }, [energyResult]);

    // ==================================
    // PROTEIN RESULTS
    // ==================================

    useEffect(() => {
        const age = state.PD_AGE || 0;
        const isROI = country === 'roi';
        const isUK = country === 'uk';
        const UK0MonthsTo18Years = isUK && age <= ranges.to.eighteenYears;
        const ROI0MonthsTo18Years = isROI && age <= ranges.to.eighteenYears;
        const isAdult = age >= ranges.from.eighteenYears;

        if (state.PC_CALCULATION_METHOD === 'paeds') {
            if (ROI0MonthsTo18Years) {
                dispatch({
                    type: 'PATIENT_PROTEIN_RESULT',
                    payload: state.PROTEIN_RESULT_ROI_018_KGPERDAY_TOTAL || 0,
                });
            } else if (UK0MonthsTo18Years) {
                if (state.PROTEIN_RESULT_UK_018_ACTIVE === 0) {
                    dispatch({
                        type: 'PATIENT_PROTEIN_RESULT',
                        payload: state.PROTEIN_RESULT_UK_018_GPERDAY_TOTAL || 0,
                    });
                } else {
                    dispatch({
                        type: 'PATIENT_PROTEIN_RESULT',
                        payload: state.PROTEIN_RESULT_UK_018_KGPERDAY_TOTAL || 0,
                    });
                }
            }
        }
        else if (state.PC_CALCULATION_METHOD === 'nitrogen') {
            const value = Number(state.NITROGEN_LOWEST) || 0;
            dispatch({
                type: 'PATIENT_PROTEIN_RESULT',
                payload: value,
            });
        }
        else if (state.PC_CALCULATION_METHOD === 'min_max') {
            const value = Number(state.PROTEIN_IRISH_ADULTS_MINIMUM) || 0;
            dispatch({
                type: 'PATIENT_PROTEIN_RESULT',
                payload: value,
            });
        }
        else if (state.PC_CALCULATION_METHOD === 'peng' || isAdult) {
            const sliderValue = state.PC_PENG_TOGGLE === 'max' ? state.PC_PENG_PROTEIN_MAX : state.PC_PENG_PROTEIN_MIN;
            const value = Number(sliderValue || 0) * Number(state.PD_WEIGHT || 0);
            const BMIAdjuster = v => (state.BMI >= 30 && state.BMI < 50) ? v * 0.75 : state.BMI > 50 ? v * 0.65 : v;
            const result = BMIAdjuster(value);
            const minValue = Number(state.PC_PENG_PROTEIN_MIN || 0) * Number(state.PD_WEIGHT || 0);
            const maxValue = Number(state.PC_PENG_PROTEIN_MAX || 0) * Number(state.PD_WEIGHT || 0);
            const minResult = BMIAdjuster(minValue);
            const maxResult = BMIAdjuster(maxValue);

            dispatch({
                type: 'PC_RESULT_PENG_PREADJUST',
                payload: `${(minResult || 0).toFixed(1)} - ${(maxResult || 0).toFixed(1)}`,
            });

            dispatch({
                type: 'PATIENT_PROTEIN_RESULT',
                payload: result,
            });
        }
    },
        [
            state.PC_CALCULATION_METHOD,
            state.PC_UK_ADULTS,
            state.PD_HEIGHT,
            state.PD_WEIGHT,
            state.PD_AGE,
            state.PD_GENDER,
            state.NITROGEN_LOWEST,
            state.PROTEIN_IRISH_ADULTS_MINIMUM,
            state.PROTEIN_RESULT_ROI_018_KGPERDAY_ADJUSTED,
            state.PROTEIN_RESULT_UK_018_GPERDAY_TOTAL,
            state.PROTEIN_RESULT_UK_018_KGPERDAY_TOTAL,
            state.PROTEIN_RESULT_UK_018_ACTIVE,
            state.PC_PENG_PROTEIN_MIN,
            state.PC_PENG_PROTEIN_MAX,
            state.PC_PENG_TOGGLE,
            country,
        ]);

    // ==================================
    // Country = this is only used in the Visibility Wrapper
    // ==================================

    useEffect(() => {
        dispatch({
            type: 'COUNTRY',
            payload: country,
        });
    }, [country]);

    // ==================================
    // Update Regimen Navigation Status
    // ==================================

    useEffect(() => {
        dispatch({
            type: 'NAVIGATION',
        });
    }, [
        // Anything that can affect navigation validation should go here
        state.PD_GENDER,
        state.PD_AGE,
        state.PD_WEIGHT,
        state.PD_HEIGHT,
        state.PC_SELECTED_PRODUCTS,
        country,
        location,
    ]);

    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    );
}

const Context = createContext(initialState);

export default Context;
