import React from 'react';

import labels from '../../../data/labels.json';
import items from '../../../data/pages/contact-us';
import PageHeader from '../../PageHeader';
import ContactBlock from '../../ContactBlock';


export default function ContactUs() {
    const { additional_labels, page_title } = labels?.pages?.support?.contact_us;

    return (
        <div className="support-contact-us">
            <PageHeader
                page_title={page_title}
            />

            { items && items.length > 0 ? (
                <div className="support-contact-us__container">
                    {
                        items.map(item => {
                            switch(item.type) {
                                case 'contact_block':
                                    return (
                                        <ContactBlock
                                            key={item.id}
                                            {...item}
                                            labels={additional_labels}
                                        />
                                    );
                                default:
                                    return null;
                                }            
                        })
                    }
                </div>
            ) : null }
        </div>
    );
}