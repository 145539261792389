import React, { useContext } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import SiteContext from '../../components/Context';
import { ContextProvider } from '../../components/Regimen/Context';
import Routes from './Routes';

export default function Support() {
    const { country } = useContext(SiteContext);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ContextProvider country={country}>
                <Routes />
            </ContextProvider>
        </MuiPickersUtilsProvider>
    );
}