import React from 'react';
import ContactBlock from '../../components/ContactBlock';
import items from '../../data/pages/contact-us';
import labels from '../../data/labels.json';

function Contact() {
  const { additional_labels } = labels?.pages?.contact;
  

  return (
    items && items.length > 0 ?
      items.map(item => {
        switch(item.type) {
          case 'contact_block':
            return (
              <ContactBlock
                key={item.id}
                {...item}
                labels={additional_labels}
              />
            );
          default:
            return null;
        }            
      })
      : null
  );
}

export default Contact;
