import React, { useContext, useEffect, useState } from 'react';
import { TextField as Field, InputAdornment } from '@material-ui/core';
// import { Edit } from '@material-ui/icons';

import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';

function AgeTextField({ field }) {
    const [state, dispatch] = useContext(Context);
    const defaultValue = state[field.readonly] || (state[field.id] && state[field.id] / 12) || '';
    const [currentValue, setCurrentValue] = useState(defaultValue);
    const [isActive, setIsActive] = useState(false);

    const handleChange = event => {
        const value = event.target.value;

        setCurrentValue(value);
    };

    useDebouncedEffect(() => {
        dispatch({
            type: field.id,
            payload: currentValue * 12, // Multiply by 12 to get months from years
        });

        setIsActive(currentValue > 0);
    }, [currentValue]);

    useEffect(() => {
        if (!state[field.readonly]) {
            return;
        }

        setCurrentValue(defaultValue);
    }, [defaultValue, field.readonly, state]);

    return (
        <div className={`c-text-field${field.isResultField ? ' t-result' : ''}${isActive ? ' active' : ''}`}>
            <FieldHeader
                title={field.title}
                tooltip={field.tooltip}
                labelFor={field.id}
            />

            <Field
                key={field.id}
                id={field.id}
                variant="outlined"
                value={`${field.readonly ? field.readonlyround ? Number(currentValue)?.toFixed(1) : currentValue : currentValue}${field.unit ? ` ${field.unit}` : ''}`}
                type={field.fieldType || 'text'}
                onChange={field.readonly ? null : handleChange}
                onBlur={field.readonly ? null : handleChange}
                placeholder="Eg 24 years"
                InputProps={{
                    endAdornment: <InputAdornment position="end">{currentValue > 0 && 'years'}</InputAdornment>
                }}
            />
        </div>
    );
}

export default AgeTextField;
