import labels from "../../data/labels.json";
import ROUTES from '../../consts/routes.json';
import first from '../../images/home/first.jpg';
import second from '../../images/home/second.jpg';
import third from '../../images/home/third.jpg';
import fourth from '../../images/chatbot/sim-smiling.gif';

const { items } = labels?.pages?.home;

const Home = [
    {
        "id": "first",
        "title": items.first,
        "image": first,
        "url": ROUTES.CREATE_REGIMEN,
    },
    {
        "id": "second",
        "title": items.second,
        "image": second,
        "url": ROUTES.PRODUCTS,
    },
    {
        "id": "third",
        "title": items.third,
        "image": third,
        "url": ROUTES.RESOURCES,
    },
    {
        "id": "fourth",
        "title": items.fourth,
        "image": fourth,
        "url": ROUTES.CHATBOT_LANDING
    }
];

export default Home;
