import React from 'react';

function FieldGroup({
  children,
  title,
}) {
  return (
    <div className="c-field-group">
      { title && <h2 className="c-field-group__title">{ title }</h2> }
      <div className="c-field-group__content">
        { children }
      </div>
    </div>
  );
}

export default FieldGroup;
