import Home from './Home';
import SaveApp from './SaveApp';
import Notifications from './Notifications';
import Region from './Region';
import Privacy from './Privacy';
import TermsAndConditions from './TermsAndConditions';
import ContactUs from './ContactUs';
// import Volumes from './Volumes';
import Sync from './Sync';

export default [
    Home,
    Sync,
    SaveApp,
    Notifications,
    TermsAndConditions,
    Privacy,
    ContactUs,
    Region,
    // Volumes,
];
