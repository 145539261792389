import React from 'react';
import ChatbotEop from '../../../components/Chatbot/ChatbotEop';
import ChatbotHeader from '../../../components/Chatbot/ChatbotHeader';

import ChatbotIntro from '../../../components/Chatbot/ChatbotIntro';
import ChatbotSearch from '../../../components/Chatbot/ChatbotSearch';
import ChatbotQuery from '../../../components/Chatbot/ChatbotQuery';
import ChatbotQueryItem from '../../../components/Chatbot/ChatbotQueryItem';

function Query({ labels, intro }) {
    const handleClick = (e, message) => {
        if (!window.BE_API.isInitialized()) {
            window.BE_API.create();
        }
        window.BE_API.sendMessage({ message });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);

        handleClick(e, formData.get('chatbotSearchTerm'));
    }

	return (
		<>
            <ChatbotHeader title={labels.title} />

            <ChatbotIntro intro={intro} subintro={labels?.subintro} />
            
            <ChatbotSearch placeholder={labels.placeholder} onSubmit={handleSubmit} />
            
            <ChatbotQuery title={labels.queries.title}>
                {
                    labels.queries.items.map(item => (
                        <ChatbotQueryItem item={item} onClick={handleClick} key={item} />
                    ))
                }
            </ChatbotQuery>

            <ChatbotEop />
		</>
	);
}

export default Query;
