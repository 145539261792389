import React from 'react';

import Step from '../../../data/forms/create-regimen/Report';
import { handleStepFields } from '../../Form';
import StepForm from '../StepForm';
import SelectedProducts from '../SelectedProducts';
import PageHeader from '../../PageHeader';
import labels from '../../../data/labels.json';
import EmailReportModal from '../../EmailReportModal';
import Summary from '../Summary';

function RegimenReport() {
    const { page_title, text } = labels?.pages?.regimen?.step_5;

    return (
        <section>
            <PageHeader
                page_title={page_title}
                text={text}
                showBottomBorder
            />

            <Summary detailsLinks />
            <SelectedProducts/>

            {
                Step.fields && Step.fields.length > 0 ? (
                    <StepForm
                        stepId={Step.id}
                        nextStep={Step.next_step}
                        ctas={[
                            'email_report',
                            'edit_regimen',
                        ]}
                    >
                        { Step.fields.map(handleStepFields) }
                    </StepForm>
                ) : null
            }

            <EmailReportModal />
        </section>
    );
}

export default RegimenReport;
