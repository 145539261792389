import React, { useContext, useEffect, useState } from 'react';
import {
    FormControlLabel,
    Checkbox,
    Button,
} from '@material-ui/core';
import Context from '../../components/Context';
import NotificationBlockedInstructions from '../../components/NotificationBlockedInstructions';
import NotificationsFallbackSettings from '../../components/NotificationsFallbackSettings';
import labels from '../../data/labels.json';

function Notifications() {
    const {
        onManageWebPushSubscriptionButtonClicked,
        userHasPushEnabled,
        browserSupportsNotifications,
        subscribed,
    } = useContext(Context);

    const subscribeText = labels.site.notification_subscribe;
    const unsubscribeText = labels.site.notification_unsubscribe;

    // Handle our checkbox option states
    const [adults, setAdult] = useState(false);
    const [paediatrics, setPaediatric] = useState(false);
    const [metabolics, setMetabolic] = useState(false);

    const checkboxMap = {
        'adults': setAdult,
        'paediatrics': setPaediatric,
        'metabolics': setMetabolic,
    };

    const OneSignal = window.OneSignal || [];

    // Handle checkbox interactions
    const handleCheckboxChange = event => {
        const value = event.target.value;
        const isChecked = event.target.checked;

        checkboxMap[value](isChecked);

        if (OneSignal?.sendTag) {
            OneSignal.push(() => OneSignal.sendTag(value, isChecked));
        }
    };

    function handleClick(event) {
        onManageWebPushSubscriptionButtonClicked(event);
    }

    useEffect(() => {
        // Retrieve the user's previously selected tags
        function getTags() {
            OneSignal.push(() => {
                if (OneSignal?.getTags) {
                    OneSignal
                        .getTags()
                        .then((tags) => {
                            if (!tags) {
                                return;
                            }

                            // update the UI accordingly
                            setAdult(tags.adults === 'true');
                            setPaediatric(tags.paediatrics === 'true');
                            setMetabolic(tags.metabolics === 'true');
                        });
                }
            });
        }

        getTags();

        // eslint-disable-next-line
    }, []);

    return (
        <>
            {
                subscribed && userHasPushEnabled ?
                    <div className="c-filter-list__list c-filter-list__list--full c-filter-list__list--notifications">
                        <ul>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={adults}
                                            value="adults"
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="Adults"
                                />
                            </li>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={paediatrics}
                                            value="paediatrics"
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="Paediatrics"
                                />
                            </li>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={metabolics}
                                            value="metabolics"
                                            onChange={handleCheckboxChange}
                                        />
                                    }
                                    label="Metabolics"
                                />
                            </li>
                        </ul>
                    </div>
                    : null
            }
            {
                userHasPushEnabled ?
                    <Button
                        onClick={handleClick}
                        classes={{ root: 'e-button' }}
                        type="button"
                    >
                        {subscribed ? unsubscribeText : subscribeText}
                    </Button>
                    :
                    !browserSupportsNotifications ?
                        <NotificationsFallbackSettings /> :
                        <NotificationBlockedInstructions />
            }
        </>
    );
}

export default Notifications;
