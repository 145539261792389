import React from 'react';

import labels from '../../../data/labels.json';
import PageHeader from '../../PageHeader';
import TextPage from '../../../templates/TextPage';

import content from '../../../data/pages/privacy-policy';

export default function Privacy() {
    const { page_title } = labels?.pages?.support?.privacy;

    return (
        <div className="support-privacy">
            <PageHeader
                page_title={page_title}
            />

            <TextPage content={content} />
        </div>
    );
}