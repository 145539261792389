import React from 'react';
import FieldHeader from '../FieldHeader';

function Title({
  field,
}) {
  return (
    <div className="c-form-title">
      <FieldHeader
        title={field?.title}
        tooltip={field?.tooltip}
      />
    </div>
  );
}

export default Title;