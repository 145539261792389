import React, { lazy, Suspense, useState, useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ContextProvider } from '../Context';
import SiteWrapper from '../SiteWrapper';
import ScrollToTop from '../ScrollToTop';
import Loader from '../Loader';
import UsePageTracking from '../../utils/usePageTracking';
import Routes from '../Routes';
import labels from '../../data/labels.json';

const Navigation = lazy(() => import('../Navigation'));
// const SiteHeader = lazy(() => import('../SiteHeader'));
const LoadingSplash = lazy(() => import('../LoadingSplash'));
const Notification = lazy(() => import('../Notification'));
const HCPSplash = lazy(() => import('../HCPSplash'));
const Onboarding = lazy(() => import('../Onboarding'));

function App() {
    const [userIsHCP, setUserIsHCP] = useState(localStorage?.getItem('userIsHCP'));
    const [userOnboarded, setUserOnboarded] = useState(localStorage?.getItem('userIsOnboarded'));
    const [waitingWorker, setWaitingWorker] = useState();
    const [newVersionAvailable, setNewVersionAvailable] = useState(false);

    const handleHCPChange = value => setUserIsHCP(value);

    const updateServiceWorker = () => {
        if(waitingWorker) {
            waitingWorker.postMessage({
                type: 'SKIP_WAITING',
            });
        }
        setNewVersionAvailable(false);
        window.location.reload();
    }

    useEffect(() => {
        if('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/sw.js')
                .then(registration => {
                    if(registration.waiting) {
                        setWaitingWorker(registration.waiting);
                        setNewVersionAvailable(true);
                    } else {
                        registration.addEventListener('updatefound', () => {
                            const serviceWorker = registration.installing;

                            serviceWorker.onstatechange = () => {
                                if(registration.waiting) {
                                    setWaitingWorker(registration.waiting);
                                    setNewVersionAvailable(true);
                                }
                            };
                        });
                    }
                });
        }

    }, [userIsHCP]);

    return (
        <Router>
            <UsePageTracking>
                <ContextProvider userOnboarded={userOnboarded}>
                    {
                        userIsHCP ? (
                            userOnboarded ? (
                                <>
                                    {
                                        newVersionAvailable &&
                                        <Suspense fallback={<div />}>
                                            <Notification
                                                title={labels?.site?.notification_new_version}
                                                handleClick={updateServiceWorker}
                                            >
                                                { labels.site.notification_update_cta }
                                            </Notification>
                                        </Suspense>
                                    }

                                    <ScrollToTop />

                                    <Suspense fallback={<div />}>
                                        {/* <SiteHeader /> */}
                                    </Suspense>

                                    <SiteWrapper>
                                        <Suspense fallback={<Loader />}>
                                            <Routes />
                                        </Suspense>
                                    </SiteWrapper>
                                    <Suspense fallback={<div />}>
                                        <Navigation />
                                    </Suspense>
                                </>
                            ) : (
                                <Suspense fallback={<div />}>
                                    <Onboarding onChange={setUserOnboarded} />
                                </Suspense>
                            )
                        ) : (
                            <Suspense fallback={<div />}>
                                <HCPSplash onChange={handleHCPChange} />
                            </Suspense>
                        )
                    }
                    
                    <Suspense fallback={<div />}>
                        <LoadingSplash />
                    </Suspense>
                </ContextProvider>
            </UsePageTracking>
        </Router>
    );
}

export default App;
