import React, { memo } from 'react';

function Instructions({
  items,
}) {
  return (
    <div className="c-instructions">
      {
        items && items.length > 0 ?
          <ul className="c-instructions__list">
            {
              items.map((item, index) => (
                <li key={item.id}>
                  <div className="c-instructions__item">
                    <span className={`c-instructions__icon c-instructions__icon--${item.icon}`} />
                    <h3 className="c-instructions__text" dangerouslySetInnerHTML={{ __html: `<strong>${ index + 1 }.</strong> ${ item.text }` }} />
                  </div>
                </li>
              ))
            }
          </ul>
        : null
      }
    </div>
  );
}

export default memo(Instructions);
