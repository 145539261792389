import React from 'react';
import PageHeader from '../../components/PageHeader';

function Page({
    pageTitle,
    subTitle,
    description,
    copy,
    hidePageHeader,
    children,
}) {
    return (
        <>
            {
                !hidePageHeader && (
                    <PageHeader
                        page_title={pageTitle || 'Untitled'}
                        subtitle={subTitle}
                        text={description}
                        showBottomBorder
                    />
                )
            }

            {copy && <p>{copy}</p>}
            
            {children}
        </>
    );
}

export default Page;
