import { NotificationsNone } from '@material-ui/icons';

import ROUTES from "../../../consts/routes.json";
import Notifications from "../../../components/Support/Notifications";

export default {
    "component": Notifications,
    "id": "support_notifications",
    "title": "Notifications",
    "url": ROUTES.SUPPORT_NOTIFICATIONS,
    "icon": NotificationsNone
};