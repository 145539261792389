import { PhoneInTalkOutlined } from '@material-ui/icons';

import ROUTES from "../../../consts/routes.json";
import ContactUs from "../../../components/Support/ContactUs";

export default {
    "component": ContactUs,
    "id": "support_contact-us",
    "title": "Contact Us",
    "url": ROUTES.SUPPORT_CONTACT_US,
    "icon": PhoneInTalkOutlined
};