import React, { useContext, useState } from 'react';
import { setDate, subMonths } from 'date-fns';
import Context from '../../Regimen/Context';
import {
    TextField as Field,
    InputAdornment,
} from '@material-ui/core';
import {
    Edit,
} from '@material-ui/icons';
import labels from '../../../data/labels.json';
import FieldHeader from '../FieldHeader';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';

function AgeTab(field) {
    const [state, dispatch] = useContext(Context);
    const [totalInMonths, updateTotalInMonths] = useState(state.PD_AGE || 0);
    const [years, updateYears] = useState(Math.floor(totalInMonths / 12) || '');
    const [months, updateMonths] = useState(Math.floor(totalInMonths % 12) || '');

    // const [ yearsValid, setYearsValid ] = useState(true);
    // const [ monthsValid, setMonthsValid ] = useState(true);

    const yearLimit = 120;
    const monthLimit = 11;

    const handleYearsChange = event => {
        const value = event.target.value;
        updateYears(value);

        // Uncomment to apply field validation
        // if (value >= 0 && value <= 120) {
        //   updateYears(value);
        //   setYearsValid(true);
        // } else {
        //   setYearsValid(false);
        // }
    };

    const handleMonthsChange = event => {
        const value = event.target.value;
        updateMonths(value);

        // Uncomment to apply field validation
        // if (value >= 0 && value <= 11) {
        //   updateMonths(value);
        //   setMonthsValid(true);
        // } else {
        //   setMonthsValid(false);
        // }
    };

    useDebouncedEffect(() => updateTotalInMonths(Number(years * 12) + Number(months)), [years, months]);

    useDebouncedEffect(() => {
        dispatch({
            type: 'PD_AGE',
            payload: totalInMonths,
        });

        let DOBTotal = subMonths(new Date(), totalInMonths);

        if (state.PD_DOB_DAY) {
            DOBTotal = setDate(DOBTotal, state.PD_DOB_DAY);
        }

        dispatch({
            type: 'PD_DOB',
            payload: DOBTotal,
        });
    }, [totalInMonths]);

    const { age_months, age_years } = labels?.pages?.regimen;

    return (
        <div className="c-age-picker__controls">
            <div className="c-age-picker__dropdown">
                <div className="c-text-field">
                    <FieldHeader
                        title={age_years}
                        labelFor="PD_AGE_YEARS"
                    />
                    
                    <Field
                        id="PD_AGE_YEARS"
                        variant="outlined"
                        onChange={handleYearsChange}
                        onBlur={handleYearsChange}
                        value={years}
                        type="number"
                        InputProps={{
                            inputProps: {
                                min: 0,
                                max: yearLimit,
                            },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Edit />
                                </InputAdornment>
                            )
                        }}
                    />
                    {/* { !yearsValid && <p className="e-error">Please enter a number of {yearLimit} or below</p> } */}
                </div>
            </div>
            <div className="c-age-picker__dropdown">
                <div className="c-text-field">
                    <FieldHeader
                        title={age_months}
                        labelFor="PD_AGE_MONTHS"
                    />
                    <Field
                        id="PD_AGE_MONTHS"
                        variant="outlined"
                        onChange={handleMonthsChange}
                        onBlur={handleMonthsChange}
                        value={months}
                        type="number"
                        InputProps={{
                        inputProps: {
                            min: 0,
                            max: monthLimit,
                        },
                        endAdornment: (
                            <InputAdornment position="end">
                            <Edit />
                            </InputAdornment>
                        )
                        }}
                    />
                    {/* { !monthsValid && <p className="e-error">Please enter a number of {monthLimit} or below</p> } */}
                </div>
            </div>
        </div>
    );
}

export default AgeTab;
