import React from 'react';
import { Route, Switch } from 'react-router-dom';

import routes from '../../data/forms/support';

function Routes() {
    const homepage = routes[0];

    return (
        routes && routes.length > 0 ? (
            <Switch>
                <Route
                    exact={homepage.exact}
                    key={homepage.id}
                    path={homepage.url}
                    component={homepage.component}
                />

                {
                    routes.map(item => {
                        return (
                            <Route
                                exact={item.exact}
                                key={item.id}
                                path={item.url}
                                component={item.component}
                            />
                        );
                    })
                }
            </Switch>
        ) : null
    );
}

export default Routes;
