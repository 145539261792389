import React, { lazy, Suspense, useState } from 'react';
import labels from '../../../data/labels.json';
import specialDiets from '../../../data/products/specialDiets.json';

import { Close } from '@material-ui/icons';
import RotatePhone from '../RotatePhone';
import NutrientTable from '../Table';

const Legend = lazy(() => import('../../../components/ProductSearch/Legend'));

function Nutrition({ products, onRemoveProduct, country }) {
	const productsItems = products?.map(product => [

		{
			title: labels?.pages?.product?.macro_nutrients,
			items: products.length === 1 ? product?.macroNutrients : product?.referenceMacroNutrients,
		},
		{
			title: labels?.pages?.product?.amino_acids,
			items: product?.aminoAcids,
		},
		{
			title: labels?.pages?.product?.minerals,
			items: products.length === 1 ? product?.minerals : product?.referenceMinerals,
		},
		{
			title: labels?.pages?.product?.vitamins,
			items: products.length === 1 ? product?.vitamins : product?.referenceVitamins
		},
		{
			title: labels?.pages?.product?.other_nutrient_info,
			items: products.length === 1 ? product?.otherNutrientInformation : product?.referenceOtherNutrientInformation,
		},
		{
			title: 'amount',
			value: product?.displayNutrientsQuantification
		},
		{
			title: 'headings',
			items: product?.headings
		}
	]);


	const initialAccordionState = [true, false, false, false, false];
	const [accordionsOpen, setAccordionsOpen] = useState([initialAccordionState]);

	var showAminoAcids, showMinerals, showVitamins, showOtherInfo;
	showAminoAcids = showMinerals = showVitamins = showOtherInfo = false;

	productsItems.forEach((product, index) => {
		if (product[1].items) {
			product[1].items.forEach((amino, index) => {
				if (amino[2].value !== '-,-') {
					showAminoAcids = true;
				}
			});
		}
		if (product[2].items && product[2].items.length !== 0) {
			showMinerals = true;
		}
		if (product[3].items && product[3].items.length !== 0) {
			showVitamins = true;
		}
		if (product[4].items && product[4].items.length !== 0) {
			showOtherInfo = true;
		}
	});

	/*
	var showAminoAcids = false;

	if (productsItems.length === 1 && productsItems[0][1].items){
		productsItems[0][1].items.forEach((amino) => {
			if (amino[2].value !== '-,-') {
				showAminoAcids = true;
			}
		});
	}
	*/


	const productsNutritionalCategories = products?.map(product => {
		const isVegetarian = product?.specialDiet?.includes('vegetarian');
		const isVegan = product?.specialDiet?.includes('vegan');
		const isKosher = product?.specialDiet?.includes('kosher');
		const isHalal = product?.specialDiet?.includes('halal');

		return [
			isVegetarian && specialDiets.vegetarian,
			isVegan && specialDiets.vegan,
			isKosher && specialDiets.kosher,
			isHalal && specialDiets.halal,
		].filter(dietItem => dietItem);
	});

	const headings = products?.[0]?.headings?.split(',');

	const handleAccordion = (i) => {
		setAccordionsOpen((prev) => ({ ...prev, [i]: !prev?.[i] }));
	};

	const locale = country === 'uk' ? 'en-GB' : 'en-IE';

	return (
		<div className="c-product__tab">
			{(products.length === 2 && window.innerWidth < 720) ? <RotatePhone /> : null}

			<div className='c-product__images c-product__info-wrapper'>
				{products.map(product => (
					<figure
						className='c-product__image'
					>
						<img
							src={product?.images[0]?.url}
							alt={product?.name || ''}
						/>

						<div className='c-product__image--background' />

						{products.length > 1 ? (
							<div
								className='c-product__image--remove'
								onClick={() => onRemoveProduct(product.id)}
							>
								<Close />
							</div>
						) : null}
					</figure>
				))}
			</div>

			<div className='c-product__info-wrapper c-product__title-wrapper'>
				{products.map(product => (
					<div
						key={product.id}
						className={`${products.length === 1 && 'c-product__title--flex'}`}
					>
						<h1 className={`${products.length > 1 && 'c-product__title-mini'}`}>{product.name}</h1>
						{((locale === "en-IE" && product.productSampleAvailability.key === "roini") || (locale === "en-GB" && product.productSampleAvailability.key === "roini") || (product.productSampleAvailability.key === "all")) &&
							<a className="c-product__sample" href={locale === "en-GB" ? "https://www.nutricia.co.uk/hcp/services/sampling-service.html?utm_source=dieteticapp&utm_medium=link&utm_campaign=ordersample" : "https://www.nutricia.ie/hcp/services/sampling-service.html?utm_source=dieteticapp&utm_medium=link&utm_campaign=ordersample"}>
								Order a Sample
							</a>
						}
					</div>
				))}
			</div>

			{productsItems.length > 1 &&
				<div className='c-product__info-wrapper c-product__values'>
					{productsItems.map((_product, index) => (
						<div key={'Values' + index}>
							<div>
								Values per {_product[5].value.label}
							</div>
						</div>
					))}
				</div>
			}

			<div onClick={() => handleAccordion(0)} className="c-product__accordion">
				<div>
					Macronutrients
				</div>
				{!accordionsOpen[0] &&
					<div>
						+
					</div>
				}
				{accordionsOpen[0] &&
					<div>
						-
					</div>
				}
			</div>
			{accordionsOpen[0] &&
				<div className='c-product__info-wrapper'>
					{productsItems.map((_product, index) => (
						<div key={index}>
							<NutrientTable
								headings={_product[6].items.split(',')}
								productItems={_product[0].items}
								double={(productsItems.length === 1)}
								splitValues={false}
								showUnit={true}
								removeUnitFromName={products.length !== 1}
								showBothValues={products.length === 1}
							/>
						</div>
					))}
				</div>
			}

			{showAminoAcids && productsItems.length <= 1 &&
				<div onClick={() => handleAccordion(1)} className="c-product__accordion">
					<div>
						Amino Acids
					</div>
					{!accordionsOpen[1] &&
						<div>
							+
						</div>
					}
					{accordionsOpen[1] &&
						<div>
							-
						</div>
					}
				</div>
			}
			{accordionsOpen[1] && showAminoAcids && productsItems.length <= 1 &&
				<div className='c-product__info-wrapper'>
					{productsItems.map((_product, index) => (
						<div key={index}>
							<NutrientTable
								headings={headings}
								productItems={_product[1].items}
								title={"test"}
								double={(productsItems.length === 1)}
								splitValues={false}
								aminoAcids={true}
							/>
						</div>
					))}
				</div>
			}

			{productsItems && showMinerals &&
				<div className="m-0">
					<div onClick={() => handleAccordion(2)} className="c-product__accordion">
						<div>
							Minerals
						</div>
						{!accordionsOpen[2] &&
							<div>
								+
							</div>
						}
						{accordionsOpen[2] &&
							<div>
								-
							</div>
						}
					</div>
					<>
						{accordionsOpen[2] &&
							<div className='c-product__info-wrapper'>
								{productsItems.map((_product, index) => (
									<div key={index}>
										<NutrientTable
											headings={headings}
											productItems={_product[2].items}
											title={"test"}
											double={(productsItems.length === 1)}
											splitValues={productsItems.length !== 1}
											unit={_product[5].value.label}
											showUnit={true}
											removeUnitFromName={products.length !== 1}
											showBothValues={products.length === 1}
										/>
									</div>
								))}
							</div>
						}
					</>
				</div>
			}

			{showVitamins &&
				<div className="m-0">
					<div onClick={() => handleAccordion(3)} className="c-product__accordion">
						<div>
							Vitamins
						</div>
						{!accordionsOpen[3] &&
							<div>
								+
							</div>
						}
						{accordionsOpen[3] &&
							<div>
								-
							</div>
						}
					</div>
					{accordionsOpen[3] &&
						<div className='c-product__info-wrapper'>
							{productsItems.map((_product, index) => (
								<div key={index}>
									<NutrientTable
										headings={headings}
										productItems={_product[3].items}
										title={"test"}
										double={(productsItems.length === 1)}
										splitValues={false}
										showUnit={true}
										removeUnitFromName={products.length !== 1}
										showBothValues={products.length === 1}
									/>
								</div>
							))}
						</div>
					}
				</div>
			}

			{showOtherInfo &&
				<div>
					<div onClick={() => handleAccordion(4)} className="c-product__accordion">
						<div>
							Other nutrient information
						</div>
						{!accordionsOpen[4] &&
							<div>
								+
							</div>
						}
						{accordionsOpen[4] &&
							<div>
								-
							</div>
						}
					</div>
					{accordionsOpen[4] &&
						<div className='c-product__info-wrapper'>
							{productsItems.map((_product, index) => (
								<div key={index}>
									<NutrientTable
										headings={headings}
										productItems={_product[4].items}
										title={"test"}
										double={(productsItems.length === 1)}
										splitValues={false}
										showUnit={true}
										removeUnitFromName={products.length !== 1}
										showBothValues={products.length === 1}
									/>
								</div>
							))}
						</div>
					}
				</div>
			}

			<h1 className='mt-2'>{labels.pages.product.key}</h1>

			<div className='c-product__info-wrapper c-product__key-wrapper'>
				{productsNutritionalCategories.map(category => (
					<div key={category}>
						<Suspense fallback={<div />}>
							<Legend items={category} onProductPage />
						</Suspense>
					</div>
				))}
			</div>
			<p className="c-product__key-disclaimer">
				This is intended to show suitability of products for religious or dietary reasons. Some products may be suitable for kosher or halal diets but only products with Kosher approval or Halal certification have been identified here. Some individual flavours/variants may not be suitable and for more information please call our Resource Centre.
			</p>
		</div>
	);
}

export default Nutrition;
