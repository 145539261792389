import "./onesignal.js";

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import './styles/main.scss';
import App from './components/App';

import packageJson from '../package.json';

console.log(`App version ${packageJson.version}`);

document.querySelector('html').setAttribute('version', packageJson.version);

ReactDOM.render(
    <App />,
    document.getElementById('root')
);
