import React, { useContext, useState, useEffect } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

import Context from '../Context';
import ROUTES from '../../../consts/routes.json';
import GenderIcon from '../../../images/regimen-gender.svg';
import GenderIconFemale from '../../../images/regimen-female.svg';
import AgeIcon from '../../../images/regimen-age.svg';
import WeightIcon from '../../../images/regimen-weight.svg';
import HeightIcon from '../../../images/regimen-height.svg';
import BMIIcon from '../../../images/bmi.svg';
import labels from '../../../data/labels.json';

function Summary({ detailsLink = true }) {
    const [state] = useContext(Context);
    const [profileStarted, setProfileStarted] = useState(false);

    useEffect(() => {
        if (state.PD_WEIGHT || state.PD_WEIGHT_PREV || state.PD_HEIGHT || state.PD_GENDER || state.PD_AGE){
            setProfileStarted(true);
        }
    }, [state]);

    /*
    const [scrollState, setScrollState] = useState({visible: "");

    const listenScrollEvent = e => {
        setScrollState("hidden");
    }

    window.addEventListener('scroll', listenScrollEvent);
    */

    const capitalize = ([firstChar, ...rest]) => firstChar.toUpperCase() + rest.join('');

    const summaryItems = [
        {
            icon: state.PD_GENDER && state.PD_GENDER === 'female' ? GenderIconFemale : GenderIcon,
            placeholder: 'Gender',
            text: state.PD_GENDER && capitalize(state.PD_GENDER),
        },
        {
            icon: AgeIcon,
            placeholder: 'Age',
            text: state.PD_AGE && (state.PD_AGE < 12 ? `${Math.round(+state.PD_AGE)}months` : `${Math.round(+state.PD_AGE / 12)}yrs`),
        },
        {
            icon: WeightIcon,
            placeholder: 'Weight',
            text: state.PD_WEIGHT && `${state.PD_WEIGHT}kgs`,
        },
        {
            icon: HeightIcon,
            placeholder: 'Height',
            text: state.PD_HEIGHT ? `${state.PD_HEIGHT}cm` : 'N/A',
        },
        {
            icon: BMIIcon,
            placeholder: 'BMI',
            text: (state.BMI && state.PD_AGE > 2) ? `${Math.round(state.BMI * 10) / 10}kg/m<sup>2</sup>` : 'N/A',
        },
    ];

    return (
        <div className="regimen-summary">
            <div className="regimen-summary__container">
                <div className="regimen-summary__top">
                    <div>
                        {!window.location.href.includes('enter-patient-details') && state.navigationItems[1].complete &&
                            <p className="regimen-summary__top__label">
                                Estimated energy<br />requirements
                            </p>
                        }

                        {
                            state.navigationItems[3].complete ? (
                                <p className="
                                    regimen-summary__chip
                                    regimen-summary__chip--protein
                                ">
                                    Protein { state.PATIENT_PROTEIN_RESULT.toFixed(1) } g/day
                                </p>
                            ) : null
                        }
                    </div>

                    <div>
                        {!window.location.href.includes('enter-patient-details') && state.navigationItems[1].complete &&
                            <p className="regimen-summary__energy-text">
                                { Math.round(state.PATIENT_ENERGY_RESULT * 10) / 10 }
                                <span>kcal</span>
                            </p>
                        }
                        

                        {
                            state.navigationItems[3].complete ? (
                                <p className="
                                    regimen-summary__chip
                                    regimen-summary__chip--fluid
                                ">
                                    Fluid { state.FLUID_RESULT } ml/day
                                </p>
                            ) : null
                        }
                    </div>
                </div>

                <div className="regimen-summary__bottom">
                    <ul>
                        {
                            summaryItems.map(({ icon, placeholder, text }) => (
                                <div key={placeholder} className="regimen-summary__info">
                                    <div className="regimen-summary__info__iconWrapper">
                                        { placeholder === 'BMI' ? 
                                            (
                                            <div className="regimen-summary__info__icon regimen-summary__info__icon--bmi">
                                                <img src={icon} alt="" />
                                            </div>
                                            ) : 
                                            (
                                                <div className="regimen-summary__info__icon">
                                                    <img src={icon} alt="" />
                                                </div>
                                            ) 
                                        }
                                    </div>

                                    <p className="regimen-summary__info__text">
                                        {
                                            text ? ReactHtmlParser(text) : placeholder
                                        }
                                    </p>
                                </div>
                            ))
                        }
                    </ul>
                </div>
            </div>

            {
                detailsLink ? (
                    <div className="regimen-summary__link">
                        <Link
                            to={ROUTES.CREATE_REGIMEN_1}
                            className="MuiButtonBase-root MuiButton-root e-button MuiButton-text"
                        >
                            {profileStarted ? labels?.pages?.regimen?.summary : labels?.pages?.regimen?.summaryNew}
                        </Link>
                    </div>
                ) : null
            }
        </div>
    );
}

export default Summary;
