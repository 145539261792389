import labels from "../../../data/labels.json";
import ROUTES from "../../../consts/routes.json";
import ProductComparison from "../../../components/Regimen/ProductComparison";

const { step_4 } = labels?.pages?.regimen;

export default {
    "id": "product_comparison",
    "url": ROUTES.CREATE_REGIMEN_4,
    "next_step": ROUTES.CREATE_REGIMEN_5,
    "navigation_title": step_4?.navigation_title,
    "step_title": step_4?.step_title,
    "component": ProductComparison,
    "dependant": "enter_patient_details",
    "fields": [
        /* {
            "id": "PC_FILTERS",
            "type": "group",
            "items": [
                {
                    "id": "FILTER_AGE",
                    "title": "Filter by age:",
                    "type": "dropdown",
                    "required": true,
                    "values": [
                        {
                            "value": "",
                            "label": "All",
                        },
                        {
                            "value": "age-infant-0-12m",
                            "label": "Infant (0-12m)",
                        },
                        {
                            "value": "age-child-1-18y",
                            "label": "Child (1-18y)",
                        },
                        {
                            "value": "age-adult-18-y",
                            "label": "Adult (18y+)",
                        },
                    ]
                },
                {
                    "id": "FILTER_CATEGORY",
                    "title": "Filter by category:",
                    "type": "dropdown",
                    "values": [
                        {
                            "value": "",
                            "label": "All",
                        },
                        {
                            "value": "format-powder-and-tablets",
                            "label": "Powder and Tablets",
                        },
                        {
                            "value": "format-liquid",
                            "label": "Liquid",
                        },
                        {
                            "value": "format-food",
                            "label": "Food",
                        },
                        {
                            "value": "format-oral-nutritional-supplement",
                            "label": "Oral Nutritional Supplement",
                        },
                        {
                            "value": "format-tube-feed",
                            "label": "Tube Feed",
                        },
                    ],
                },
                {
                    "id": "FILTER_METABOLICS",
                    "title": "Filter by metabolic condition:",
                    "type": "dropdown",
                    "tooltip": {
                        "type": "text",
                        "text": "Only select this filter if your patient has an inherited metabolic disorder."
                    },
                    "values": [
                        {
                            "value": "",
                            "label": "All",
                        },
                        {
                            "value": "metabolic-conditions-other",
                            "label": "Other",
                        },
                        {
                            "value": "metabolic-conditions-pku",
                            "label": "PKU",
                        },
                        {
                            "value": "metabolic-conditions-ga1",
                            "label": "GA1",
                        },
                        {
                            "value": "metabolic-conditions-hcu",
                            "label": "HCU",
                        },
                        {
                            "value": "metabolic-conditions-iva",
                            "label": "IVA",
                        },
                        {
                            "value": "metabolic-conditions-mma-pa",
                            "label": "MAA-PA",
                        },
                        {
                            "value": "metabolic-conditions-msud",
                            "label": "MSUD",
                        },
                        {
                            "value": "metabolic-conditions-nkh",
                            "label": "NKH",
                        },
                        {
                            "value": "metabolic-conditions-sod",
                            "label": "SOD",
                        },
                        {
                            "value": "metabolic-conditions-tyr",
                            "label": "TYR",
                        },
                        {
                            "value": "metabolic-conditions-ucd",
                            "label": "UCD",
                        },
                    ],
                }
            ]
        }, */
        { // this is here for validation
            "id": "PC_SELECTED_PRODUCTS",
            "title": "Selected Products",
            "required": true,
        }
    ]
}
