import React, { useContext } from 'react';
import Context from '../../components/Context';
import CountrySelector from '../../components/CountrySelector';
import PromoLinks from '../../components/PromoLinks';
import countries from '../../data/forms/countries';
import home from '../../data/pages/home';

function Welcome() {
    const { country } = useContext(Context);

    return (
        <div className="welcome__links">
            {country ? <PromoLinks items={home} /> : <CountrySelector countries={countries} />}
        </div>
    );
}

export default Welcome;
