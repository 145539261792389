import React from 'react';

function TextPage({ content }) {
    return (
        <article className="u-content-wrapper">
            { content && content.length > 0 ? (
                content.map((item, index) => {
                    switch (item.type) {
                        case 'title':
                            return (
                                <h2 key={`text-page-${index}`}>{item.value}</h2>
                            );
                        case 'unorderedlist':
                            return (
                                <ul key={`text-page-${index}`}>
                                    { item.value && item.value.length > 0 ? (
                                        item.value.map((listItem, index) => (
                                            <li
                                                key={index}
                                                dangerouslySetInnerHTML={{ __html: listItem }}
                                            />
                                        ))
                                    ) : null }
                                </ul>
                            );
                        default:
                            return (
                                <p
                                    key={`text-page-${index}`}
                                    dangerouslySetInnerHTML={{ __html: item.value }}
                                />
                            );
                    }
                })
            ) : null }
        </article>
    );
}

export default TextPage;
