import ranges from './calculation-sets/age-ranges';

// export function resolve(sourceObject, property) {
//   return property ? property.split('.').reduce((o, k) => o && o[k], sourceObject) : '';
// }

// export function generateArrayFromValue(max = 1, min = 1) {
//   const array = Array.from(Array(max), (_, i) => i + min);
//   return array.map(e => ({
//     label: e,
//     value: e,
//   }));
// }

// export function getCurrentValue(data, field, defaultValue = '') {
//   return data?.find(e => e.id === field.id)?.value || defaultValue;
// }

// export function flattenFieldArray(data) {
//   return data?.reduce((r, { items, ...rest}) => {
//     r.push(rest);
//     if (items) r.push(...flattenFieldArray(items));
//     return r;
//   }, [])
// }

export function groupArrayByLetter(data) {
    const sortedData = data && data.length > 0 ? data.sort((a, b) => {
        return a?.name?.localeCompare(b?.name);
    }) : [];
    const reducedData = sortedData
        .reduce((r, e) => {
            const key = e?.name && e?.name[0];
            if (!r[key]) r[key] = []
            r[key].push(e);
            return r;
        }, {});
    return Object.entries(reducedData);
}

// export function compare(a, o, b) {
//   const operator = o || '===';
//   const operators = {
//       '===':       (l, r) => l === r,
//       '!==':       (l, r) => l !== r,
//       '<':         (l, r) => l < r,
//       '>':         (l, r) => l > r,
//       '<=':        (l, r) => l <= r,
//       '>=':        (l, r) => l >= r,
//       'typeof':    (l, r) => l === typeof r,
//       'nottypeof': (l, r) => l !== typeof r,
//   }
//   return operators[operator](a, b);
// }

export function getRegimenStepItems(state) {
    const items = state.navigationItems;

    return items?.filter(e => !e.hiddenFromNavigation).map(step => {

        const complete = step.complete || step?.requiredFields.length > 0 ? step?.requiredFields.every(id => state[id]) : false;
        const disabled = step?.dependant ? !Boolean(items?.find(e => e.id === step.dependant)?.complete) : false;

        return ({
            ...step,
            complete,
            disabled,
        });
    })
}

export function makeBold(input) {
    // This is used to bold any phrases that are wrapped in to asterisks.
    // i.e. **milk** would return in strong tags
    // This is used on the product information page.
    return input.replace(/\*\*(\S[^**]+\S)\*\*/g, "<strong>$1</strong>");
}

export function makeItalic(input) {
    // This is used to add italic any phrases that are wrapped in to underscores.
    // i.e. _milk_ would return in em tags
    // This is used on the product information page.
    return input.replace(/_(\S[^**]+\S)_/g, "<em>$1</em>");
}

export function formatText(input) {
    // This is just a combination of makeBold() and makeItalic()
    // This is used on the product information page.
    let formattedText = input;
    formattedText = makeBold(formattedText);
    formattedText = makeItalic(formattedText);
    return formattedText;
}

export function toCamelCase(str) {
    return str?.split(' ').map((word, index) => {
        // If it is the first word make sure to lowercase all the chars.
        if (index === 0) {
            return word.toLowerCase();
        }
        // If it is not the first word only upper case the first char and lowercase the rest.
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join('');
}

export function toTitleCase(str) {
    if (str.length > 0)
    {
        return str.trim().split(' ')
        .map(w => w.length > 0 ? w[0].toUpperCase() + w.substring(1) : '')
        .join(' ');
    }
    return '';
}

export function getResultByGenderAge({
    age,
    gender,
    maleData,
    femaleData,
}) {
    let result;
    const data = gender === 'male' ? maleData : femaleData;

    if (data) {
        data.forEach(e => {
            if (age >= e.age[0] && age <= e.age[1]) {
                result = e.value;
            }
            return;
        });
    }

    return result;
}

// This can be used to return correct nutrient values maps
// this can be reused as nutreient values have the same structure,
// while minerals and proteins tend to have varied structures
export function generateNutrientValues({
    ZeroToThreeMonths,
    FourToSixMonths,
    SevenToNineMonths,
    TenToTwelveMonths,
    OneToThreeYears,
    FourToSixYears,
    SevenToTenYears,
    ElevenToFourteenYears,
    FifteenToEighteenYears,
    NineteenToFiftyYears,
    FiftyPlus,
}) {
    return [
        {
            age: [ranges.from.zero, ranges.to.fourMonths],
            value: ZeroToThreeMonths,
        },
        {
            age: [ranges.from.fourMonths, ranges.to.sevenMonths],
            value: FourToSixMonths,
        },
        {
            age: [ranges.from.sevenMonths, ranges.to.tenMonths],
            value: SevenToNineMonths,
        },
        {
            age: [ranges.from.tenMonths, ranges.to.oneYear],
            value: TenToTwelveMonths,
        },
        {
            age: [ranges.from.oneYear, ranges.to.fourYears],
            value: OneToThreeYears,
        },
        {
            age: [ranges.from.fourYears, ranges.to.sevenYears],
            value: FourToSixYears,
        },
        {
            age: [ranges.from.sevenYears, ranges.to.elevenYears],
            value: SevenToTenYears,
        },
        {
            age: [ranges.from.elevenYears, ranges.to.fifteenYears],
            value: ElevenToFourteenYears,
        },
        {
            age: [ranges.from.fifteenYears, ranges.to.nineteenYears],
            value: FifteenToEighteenYears,
        },
        {
            age: [ranges.from.nineteenYears, ranges.to.fiftyYears],
            value: NineteenToFiftyYears,
        },
        {
            age: [ranges.from.fiftyYears, ranges.to.max],
            value: FiftyPlus,
        },
    ];
};

export function generateNutrientValuesMagnesium({
    ZeroToThreeMonths,
    FourToSixMonths,
    SevenToNineMonths,
    TenToTwelveMonths,
    OneToThreeYears,
    FourToSixYears,
    SevenToTenYears,
    ElevenToFourteenYears,
    FifteenToEighteenYears,
    NineteenToFiftyYears,
    FiftyPlus,
}) {
    return [
        {
            age: [ranges.from.zero, ranges.to.fourMonths],
            value: ZeroToThreeMonths,
        },
        {
            age: [ranges.from.fourMonths, ranges.to.sevenMonths],
            value: FourToSixMonths,
        },
        {
            age: [ranges.from.sevenMonths, ranges.to.tenMonths],
            value: SevenToNineMonths,
        },
        {
            age: [ranges.from.tenMonths, ranges.to.oneYear],
            value: TenToTwelveMonths,
        },
        {
            age: [ranges.from.oneYear, ranges.to.threeYears],
            value: OneToThreeYears,
        },
        {
            age: [ranges.from.threeYears, ranges.to.sevenYears],
            value: FourToSixYears,
        },
        {
            age: [ranges.from.sevenYears, ranges.to.tenYears],
            value: SevenToTenYears,
        },
        {
            age: [ranges.from.tenYears, ranges.to.fifteenYears],
            value: ElevenToFourteenYears,
        },
        {
            age: [ranges.from.fifteenYears, ranges.to.eighteenYears],
            value: FifteenToEighteenYears,
        },
        {
            age: [ranges.from.eighteenYears, ranges.to.fiftyYears],
            value: NineteenToFiftyYears,
        },
        {
            age: [ranges.from.fiftyYears, ranges.to.max],
            value: FiftyPlus,
        },
    ];
};
