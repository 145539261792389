import labels from "../../../data/labels.json";
import ROUTES from "../../../consts/routes.json";
import EnergyRequirements from "../../../components/Regimen/EnergyRequirements";
import ranges from "../../../utils/calculation-sets/age-ranges";

const { step_2 } = labels?.pages?.regimen;

export default {
    "id": "energy_requirements",
    "url": ROUTES.CREATE_REGIMEN_2,
    "next_step": ROUTES.CREATE_REGIMEN_3,
    "navigation_title": step_2?.navigation_title,
    "step_title": step_2?.step_title,
    "component": EnergyRequirements,
    "dependant": "enter_patient_details",
    "optional": true,
    "fields": [
        {
            "id": "ER_CALCULATION_METHOD",
            "title": "Estimate energy expenditure",
            "type": "calculator_selector",
            "tooltip": {
                "type": "text",
                "text": `Estimated requirements are a starting point only therefore the application of clinical judgment and monitoring are essential.

                Equations used (full reference list available under Resources section):

                Adults:
                - PENG 2018
                - Mifflin St. Jeor 1990
                - Harris-Benedict 1919
                - Henry Oxford 2005
                - Schofield 1985: adapted by DoH 1991
                - Penn State: Frankenfield et al. 2009; Frankenfield and Ashcraft 2011
                - SACN 2011
                
                Paediatric requirements (UK): SACN 2011
                
                Paediatric requirements (ROI): 
                - <6 months: SACN 2011
                - 6 months - 17 years: EFSA 2017
                `,
            }
        },
        {
            "id": "PD_PENG_PDF",
            "title": " ",
            "type": "link",
            "button": "PENG guidelines",
            "classes": "button",
            "buttonText": "PENG guidelines",
            "isDownload": true,
            "url": "https://www.nutricia.com/content/dam/sn/local/gbr/approved/pdf/peng-nutricia-combined-pocket-guide.pdf",
            "useConditionalVisibility": true,
            "style": "button"
        },
        {
            "id": "ENERGY_RESULT_UK_PAEDS_0TO18YEARS",
            "title": "UK Energy for paediatrics 0 to 18 years",
            "type": "ukenergypaeds_0to18",
            "useConditionalVisibility": true,
            "tab1SliderTotal": "ENERGY_RESULT_UK_0to18_KCAL_SLIDER_TOTAL",
            "tab2SliderTotal": "ENERGY_RESULT_UK_0to18_KCALKG_SLIDER_TOTAL",
            "activeTabField": "ENERGY_RESULT_UK_0to18_ACTIVE_TAB",
            "values": "ENERGY_RESULT_UK_0to18_VALUES",
            "activeTab": 0,
            "useOnChange": true
        },
        {
            "id": "ENERGY_RESULT_ROI_PAEDS_UNDER6MONTHS",
            "title": "Irish paediatrics under 6 months",
            "type": "ukenergypaeds_0to18",
            "useConditionalVisibility": true,
            "tab2SliderTotal": "ENERGY_RESULT_ROI_UNDER6MONTHS_KCALKG_SLIDER_TOTAL",
            "values": "ENERGY_RESULT_ROI_UNDER6MONTHS_VALUES",
        },
        {
            "id": "ER_ROI_6M17Y",
            "title": "PAL",
            "type": "dropdown",
            "useConditionalVisibility": true,
            "useConditionalDefaultFromRangeValue": "PD_AGE",
            "tooltip": {
                "type": "text",
                "text": "Choose PAL descriptor. Note there are no EFSA assigned PAL values for infants under 12 months. PAL values also vary according to age. Please refer to EFSA guidance for more information: EFSA 2019, Dietary Reference Values for Nutrients, Summary Report. doi: 10.2903/sp.efsa.2017.e15121"
            },
            "values": [
                {
                    "value": "pal_none",
                    "label": "No PAL",
                    "defaultFor": [ranges.from.sixMonths, ranges.to.oneYear],
                },
                {
                    "value": "pal_14",
                    "label": "PAL 1.4",
                    "defaultFor": [ranges.from.oneYear, ranges.to.nineYears],
                },
                {
                    "value": "pal_16",
                    "label": "PAL 1.6",
                    "defaultFor": [ranges.from.fourYears, ranges.to.eighteenYears],
                },
                {
                    "value": "pal_18",
                    "label": "PAL 1.8",
                    "defaultFor": [ranges.from.fourYears, ranges.to.eighteenYears],
                },
                {
                    "value": "pal_20",
                    "label": "PAL 2.0",
                    "defaultFor": [ranges.from.tenYears, ranges.to.eighteenYears],
                },
            ],
        },
        {
            "id": "ER_ROI_6M17Y_ADJUST",
            "title": "Adjust the value:",
            "type": "slider",
            "defaultValue": 100,
            "stepIncrement": 1,
            "useConditionalVisibility": true,
            "items": [
                {
                    "value": 1,
                    "label": "1%",
                    "sliderLabel": "1%",
                },
                {
                    "value": 150,
                    "label": "150%",
                    "sliderLabel": "150%",
                }
            ],
            "useOnChange": true
        },
        {
            "id": "ER_CALORIC_EQUATION",
            "title": "Select minimum and maximum kcal/kg",
            "type": "caloric_range",
            "defaultMinValue": 10,
            "defaultMaxValue": 40,
            "stepIncrement": 1,
            "minValue": "ER_CALORIC_EQUATION_MIN",
            "maxValue": "ER_CALORIC_EQUATION_MAX",
            "readOnly": "ENERGY_RESULT_PENG_PREADJUST",
            "textField": "Caloric range:",
            "unit": "(kcal/kg/day)",
            "useOnChange": true,
            "tooltip": {
                "type": "text",
                "text": "Refer to table 3.1 of the downloadable PENG 2018 pdf for guidance."
            },
            "useConditionalVisibility": true,
            "items": [
                {
                    "value": 10,
                    "label": "10"
                },
                {
                    "value": 40,
                    "label": "40"
                }
            ],
        },
        {
            "id": "ER_CALORIC_TOGGLE",
            "title": "Use minimum or maximum in calculations",
            "class": "c-radio-list__group--calory",
            "type": "radio",
            "useConditionalVisibility": true,
            "items": [
                {
                    "id": "ER_CALORIC_TOGGLE_MIN",
                    "title": "Minimum",
                    "value": "min",
                },
                {
                    "id": "ER_CALORIC_TOGGLE_MAX",
                    "title": "Maximum",
                    "value": "max",
                }
            ]
        },
        {
            "id": "ER_OVER30_MIFFLIN_TEXT",
            "text": "Your patient's BMI is over 30, so the Mifflin St Jeor equation is being used instead. Baseline value:",
            "type": "text",
            "tooltip": {
                "type": "text",
                "text": "test tooltip"
            },
            "useConditionalVisibility": true,
        },
        {
            "id": "ER_OVER30_MIFFLIN",
            "type": "textfield",
            "useConditionalVisibility": true,
            "readonly": "ENERGY_RESULT_MIFFLINSTJEOR_BASE",
        },
        {
            "id": "ER_STRESS_FACTOR",
            "title": "Stress Factor",
            "type": "stress_factor",
            "useConditionalVisibility": true,
        },
        {
            "id": "ER_STRESS_FACTOR_ADJUST",
            "title": "Stress Factor Adjust",
            "type": "stress_factor_adjuster",
            "useConditionalVisibility": true,
        },
        /* {
            "id": "ER_PAL_DIT_TITLE",
            "title": "Estimate total energy expenditure",
            "type": "title",
            "useConditionalVisibility": true,
        }, */
        {
            "id": "ER_PAL_DIT_CATEGORY",
            "title": "Estimate total energy expenditure",
            "type": "pal_dit_slider",
            "defaultValue": 1.0,
            "stepIncrement": 0.05,
            "textField": "Adjust for PAL & DIT:",
            "useLabelsForTextField": true,
            "useOnChange": true,
            "items": [
                {
                    "value": 1.0,
                    "label": "In bed and immobile",
                    "units": "(1.0 - 1.1)",
                    "sliderLabel": "1.0",
                },
                {
                    "value": 1.05,
                    "label": "In bed and immobile",
                    "units": "(1.0 - 1.1)",
                },
                {
                    "value": 1.1,
                    "label": "In bed and/or sitting out",
                    "units": "(1.1 - 1.2)",
                },
                {
                    "value": 1.15,
                    "label": "In bed and/or sitting out",
                    "units": "(1.1 - 1.2)",
                },
                {
                    "value": 1.2,
                    "label": "Limited mobility",
                    "units": "(1.2 - 1.25)",
                },
                {
                    "value": 1.25,
                    "label": "Sedentary",
                    "units": "(1.25 - 1.4)",
                },
                {
                    "value": 1.3,
                    "label": "Sedentary",
                    "units": "(1.25 - 1.4)",
                },
                {
                    "value": 1.35,
                    "label": "Sedentary",
                    "units": "(1.25 - 1.4)",
                },
                {
                    "value": 1.4,
                    "label": "Sedentary",
                    "units": "(1.25 - 1.4)",
                },
                {
                    "value": 1.45,
                    "label": "Physical activity - Light",
                    "units": "(1.4 - 1.6)",
                },
                {
                    "value": 1.5,
                    "label": "Physical activity - Light",
                    "units": "(1.4 - 1.6)",
                },
                {
                    "value": 1.55,
                    "label": "Physical activity - Light",
                    "units": "(1.4 - 1.6)",
                },
                {
                    "value": 1.6,
                    "label": "Physical activity - Moderate",
                    "units": "(1.6 - 1.7)",
                },
                {
                    "value": 1.65,
                    "label": "Physical activity - Moderate",
                    "units": "(1.6 - 1.7)",
                },
                {
                    "value": 1.7,
                    "label": "Physical activity - Heavy",
                    "units": "(1.7 - 1.9)",
                },
                {
                    "value": 1.75,
                    "label": "Physical activity - Heavy",
                    "units": "(1.7 - 1.9)",
                },
                {
                    "value": 1.8,
                    "label": "Physical activity - Heavy",
                    "units": "(1.7 - 1.9)",
                },
                {
                    "value": 1.85,
                    "label": "Physical activity - Heavy",
                    "units": "(1.7 - 1.9)",
                },
                {
                    "value": 1.9,
                    "label": "Physical activity - Heavy",
                    "units": "(1.7 - 1.9)",
                    "sliderLabel": "1.9",
                },
            ],
            "useConditionalVisibility": true,
            "tooltip": {
                "type": "text",
                "text": "For patients who are physically active a PAL greater than 1.4 may be required",
            }
        },
        {
            "id": "ER_PENN_TMAX",
            "title": "Temperature (max)",
            "type": "textfield",
            "fieldType": "number",
            "useConditionalVisibility": true,
            "maxValue": 150,
        },
        {
            "id": "ER_PENN_VE",
            "title": "Ventilation in L/min",
            "type": "textfield",
            "fieldType": "number",
            "useConditionalVisibility": true,
            "maxValue": 150,
        },
        {
            "id": "ER_SACN_ADJUST",
            "title": "Adjust EARs",
            "type": "dropdown",
            "useConditionalVisibility": true,
            "defaultOption": 1,
            "values": [
                {
                    "value": 0.75,
                    "label": "75%"
                },
                {
                    "value": 1,
                    "label": "100%"
                },
                {
                    "value": 1.2,
                    "label": "120%"
                },
                {
                    "value": 1.5,
                    "label": "150%"
                },
            ],
        },
        {
            "id": "ER_ENERGY_RESULT",
            "type": "energy_result",
        },
    ],
}
