import ranges from '../../calculation-sets/age-ranges';

const male = [
  {
    range: [ ranges.from.zero, ranges.to.threeMonths ],
    value: 574,
  },
  {
    range: [ ranges.from.threeMonths, ranges.to.fiveMonths ],
    value: 598,
  },
  {
    range: [ ranges.from.fiveMonths , ranges.to.sevenMonths ],
    value: 622,
  },
  {
    range: [ ranges.from.sevenMonths, ranges.to.thirteenMonths ],
    value: 718,
  },
  {
    range: [ ranges.from.thirteenMonths, ranges.to.twoYears ],
    value: 765,
  },
  {
    range: [ ranges.from.twoYears, ranges.to.threeYears ],
    value: 1004,
  },
  {
    range: [ ranges.from.threeYears, ranges.to.fourYears ],
    value: 1171,
  },
  {
    range: [ ranges.from.fourYears, ranges.to.fiveYears ],
    value: 1386,
  },
  {
    range: [ ranges.from.fiveYears, ranges.to.sixYears ],
    value: 1482,
  },
  {
    range: [ ranges.from.sixYears, ranges.to.sevenYears ],
    value: 1577,
  },
  {
    range: [ ranges.from.sevenYears, ranges.to.eightYears ],
    value: 1649,
  },
  {
    range: [ ranges.from.eightYears, ranges.to.nineYears ],
    value: 1745,
  },
  {
    range: [ ranges.from.nineYears, ranges.to.tenYears ],
    value: 1840,
  },
  {
    range: [ ranges.from.tenYears, ranges.to.elevenYears ],
    value: 2032,
  },
  {
    range: [ ranges.from.elevenYears, ranges.to.twelveYears ],
    value: 2127,
  },
  {
    range: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
    value: 2247,
  },
  {
    range: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
    value: 2414,
  },
  {
    range: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
    value: 2629,
  },
  {
    range: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
    value: 2820,
  },
  {
    range: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
    value: 2964,
  },
  {
    range: [ ranges.from.seventeenYears, ranges.to.eighteenYears ],
    value: 3083,
  },
  {
    range: [ ranges.from.eighteenYears, ranges.to.nineteenYears ],
    value: 3155,
  },
  {
    range: [ ranges.from.nineteenYears, ranges.to.twentyFiveYears ],
    value: 2772,
  },
  {
    range: [ ranges.from.twentyFiveYears, ranges.to.thirtyFiveYears ],
    value: 2749,
  },
  {
    range: [ ranges.from.thirtyFiveYears, ranges.to.fortyFiveYears ],
    value: 2629,
  },
  {
    range: [ ranges.from.fortyFiveYears, ranges.to.fiftyFiveYears ],
    value: 2581,
  },
  {
    range: [ ranges.from.fiftyFiveYears, ranges.to.sixtyFiveYears ],
    value: 2581,
  },
  {
    range: [ ranges.from.sixtyFiveYears, ranges.to.seventyFiveYears ],
    value: 2342,
  },
  {
    range: [ ranges.from.seventyFiveYears, ranges.to.max ],
    value: 2294,
  },
];

const female = [
  {
    range: [ ranges.from.zero, ranges.to.threeMonths ],
    value: 502,
  },
  {
    range: [ ranges.from.threeMonths, ranges.to.fiveMonths ],
    value: 550,
  },
  {
    range: [ ranges.from.fiveMonths , ranges.to.sevenMonths ],
    value: 574,
  },
  {
    range: [ ranges.from.sevenMonths, ranges.to.thirteenMonths ],
    value: 646,
  },
  {
    range: [ ranges.from.thirteenMonths, ranges.to.twoYears ],
    value: 717,
  },
  {
    range: [ ranges.from.twoYears, ranges.to.threeYears ],
    value: 932,
  },
  {
    range: [ ranges.from.threeYears, ranges.to.fourYears ],
    value: 1076,
  },
  {
    range: [ ranges.from.fourYears, ranges.to.fiveYears ],
    value: 1291,
  },
  {
    range: [ ranges.from.fiveYears, ranges.to.sixYears ],
    value: 1362,
  },
  {
    range: [ ranges.from.sixYears, ranges.to.sevenYears ],
    value: 1482,
  },
  {
    range: [ ranges.from.sevenYears, ranges.to.eightYears ],
    value: 1530,
  },
  {
    range: [ ranges.from.eightYears, ranges.to.nineYears ],
    value: 1625,
  },
  {
    range: [ ranges.from.nineYears, ranges.to.tenYears ],
    value: 1721,
  },
  {
    range: [ ranges.from.tenYears, ranges.to.elevenYears ],
    value: 1936,
  },
  {
    range: [ ranges.from.elevenYears, ranges.to.twelveYears ],
    value: 2032,
  },
  {
    range: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
    value: 2103,
  },
  {
    range: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
    value: 2223,
  },
  {
    range: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
    value: 2342,
  },
  {
    range: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
    value: 2390,
  },
  {
    range: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
    value: 2414,
  },
  {
    range: [ ranges.from.seventeenYears, ranges.to.nineteenYears ],
    value: 2462,
  },
  {
    range: [ ranges.from.nineteenYears, ranges.to.twentyFiveYears ],
    value: 2175,
  },
  {
    range: [ ranges.from.twentyFiveYears, ranges.to.thirtyFiveYears ],
    value: 2175,
  },
  {
    range: [ ranges.from.thirtyFiveYears, ranges.to.fortyFiveYears ],
    value: 2103,
  },
  {
    range: [ ranges.from.fortyFiveYears, ranges.to.fiftyFiveYears ],
    value: 2103,
  },
  {
    range: [ ranges.from.fiftyFiveYears, ranges.to.sixtyFiveYears ],
    value: 2079,
  },
  {
    range: [ ranges.from.sixtyFiveYears, ranges.to.seventyFiveYears ],
    value: 1912,
  },
  {
    range: [ ranges.from.seventyFiveYears, ranges.to.max ],
    value: 1840,
  },
];

const getResult = ({
  age,
  gender,
}) => {
  let result;
  const data = gender === 'male' ? male : female;
  data.forEach(e => {
    if (age >= e.range[0] && age <= e.range[1]) {
      result = e.value;
    }
  });
  return result;
};

export default getResult;
