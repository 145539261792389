import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import ROUTES from '../../../consts/routes.json';
import backIcon from '../../../images/chatbot/back-icon.png';
import closeIcon from '../../../images/chatbot/close-icon.png';

function ChatbotHeader({ title }) {
    const { pathname } = useLocation();
    const parentUrl = pathname.substring(0, pathname.lastIndexOf('/')) || '/';

	return (
        <section className='chatbot-header'>
            <Link to={parentUrl} className='chatbot-header__previous'>
                <img src={backIcon} alt="Back to Previous Page" />
            </Link>
            
            <h2 className='chatbot-header__title'>{title}</h2>

            <Link to={ROUTES.CHATBOT_LANDING} className='chatbot-header__close'>
                <img src={closeIcon} alt="Back to Chatbot Initial Page" />
            </Link>
        </section>
	);
}

export default memo(ChatbotHeader);
