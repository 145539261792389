import React, { useContext, useEffect, useState } from 'react';
import { InputAdornment, TextField as Field } from '@material-ui/core';
import {
    Edit,
} from '@material-ui/icons';

import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';
import Toggle from '../Toggle';

function HeightTextField({ field }) {
    const [state, dispatch] = useContext(Context);
    const CMConversionRate = 2.54;
    const inchesConversionRate = 0.393700;

    const [currentCM, setCurrentCM] = useState(state.PD_HEIGHT > 0 ? state.PD_HEIGHT : '');
    const [currentFeet, setCurrentFeet] = useState('');
    const [currentInches, setCurrentInches] = useState('');
    
    const [isToggleActive, setToggleActive] = useState(false);
    const [isMetric, setIsMetric] = useState(true);

    const setMetric = () => {
        if (isMetric) { return; }
        const totalInches = Number(currentFeet * 12) + Number(currentInches);
        const heightInMetric = totalInches * CMConversionRate;

        setCurrentCM(Math.round(heightInMetric));
    };
    
    const setImperial = () => {
        if (!isMetric) { return; }
        let feet = Math.round(Number((currentCM * inchesConversionRate) / 12));
        let inches = Math.round(Number((currentCM * inchesConversionRate) % 12));
        let realFeet = ((currentCM*0.393700) / 12);
        feet = Math.floor(realFeet);
        inches = (realFeet - feet) * 12;
        inches = +inches.toFixed(2);
        
        setCurrentFeet(feet);
        setCurrentInches(inches);
    };

    const handleChange = (event, type) => {
        const value = event.target.value;

        if (type === 'cm') {
            setCurrentCM(value);
            setImperial();
        } else if (type === 'feet') {
            setCurrentFeet(value);
        } else if (type === 'inches') {
            setCurrentInches(value);
        }
    };

    
    useDebouncedEffect(() => {
        setMetric();
    }, [currentFeet, currentInches]);

    useDebouncedEffect(() => {
        setImperial();
    }, [currentCM]);

    useDebouncedEffect(() => {
        setToggleActive(currentCM > 0);

        dispatch({
            type: field.id,
            payload: currentCM,
        });
    }, [currentCM]);

    // Fired when component mounts
    useEffect(() => {
        if (currentCM > 0) {
            setImperial();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`
            height-text-field
            c-text-field${field.isResultField ? ' t-result' : ''}
            ${isToggleActive ? ' active' : ''}
        `}>
            <div className="height-text-field__header">
                <FieldHeader
                    title={field.title}
                    tooltip={field.tooltip}
                    labelFor={field.id}
                />

                <Toggle
                    callback={() => setIsMetric(!isMetric)}
                    isActive={isMetric}
                    label={`Switch to ${isMetric ? 'ft / in' : 'cm'}`}
                />
            </div>

            {
                isMetric ?
                    <Field
                        variant="outlined"
                        value={currentCM}
                        onChange={event => handleChange(event, 'cm')}
                        onBlur={event => handleChange(event, 'cm')}
                        placeholder="Eg 75 cm"
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{currentCM >= 0 && 'cm'} <Edit /></InputAdornment>
                        }}
                    />
                :
                    <div className="height-text-field__imperial">
                        <Field
                            variant="outlined"
                            value={currentFeet}
                            placeholder="Eg 5 feet"
                            onChange={event => handleChange(event, 'feet')}
                            onBlur={event => handleChange(event, 'feet')}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{currentFeet >= 0 && 'feet'} <Edit /></InputAdornment>
                            }}
                        />

                        <Field
                            variant="outlined"
                            value={currentInches}
                            placeholder="Eg 3.5 inches"
                            onChange={event => handleChange(event, 'inches')}
                            onBlur={event => handleChange(event, 'inches')}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{currentInches >= 0 && 'inches'} <Edit /></InputAdornment>
                            }}
                        />
                    </div>
            }
        </div>
    );
}

export default HeightTextField;
