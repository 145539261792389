import React from 'react';
import labels from '../../../data/labels.json';
import Tabs from '../../Tabs';
import MetricTab from './MetricTab';
import ImperialTab from './ImperialTab';
import FieldHeader from '../FieldHeader';

function WeightPicker() {
  const {
    weight_picker_title,
    weight_picker_tab1,
    weight_picker_tab2,
  } = labels?.pages?.regimen;

  const tabItems = [
    {
      "id": "kg",
      "title": weight_picker_tab1,
      "component": <MetricTab />,
    },
    {
      "id": "stones",
      "title": weight_picker_tab2,
      "component": <ImperialTab />,
    },
  ];
  
  return (
    <section className="c-weight-picker">
      <FieldHeader
        title={weight_picker_title}
      />
      <Tabs items={tabItems} />
    </section>
  );
}

export default WeightPicker;
