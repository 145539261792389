import labels from "../../../data/labels.json";
import ROUTES from "../../../consts/routes.json";
import EnterPatientDetails from '../../../components/Regimen/EnterPatientDetails';

const { step_1 } = labels?.pages?.regimen;

export default {
    "id": "enter_patient_details",
    "url": ROUTES.CREATE_REGIMEN_1,
    "next_step": ROUTES.CREATE_REGIMEN_2,
    "navigation_title": step_1.navigation_title,
    "step_title": step_1.step_title,
    "component": EnterPatientDetails,
    "exact": true,
    "fields": [
        {
            "id": "PD_GENDER",
            "title": "Patient gender",
            "type": "radio",
            "required": true,
            "items": [
                {
                    "id": "PD_GENDER_MALE",
                    "title": "Male",
                    "value": "male",
                },
                {
                    "id": "PD_GENDER_FEMALE",
                    "title": "Female",
                    "value": "female",
                }
            ]
        },
        {
            "id": "PD_AGE",
            "title": "Patient age",
            "type": "age_picker",
            "fieldType": "number",
            // "type": "age_picker",
            // "required": true,
        },
        {
            "id": "PD_WEIGHT",
            "title": "Current Patient Weight",
            "type": "weighttextfield",
            "fieldType": "number",
            "required": true,
        },
        {
            "id": "PD_WEIGHT_PREV",
            "title": "Previous Patient Weight",
            "type": "weighttextfield",
            "fieldType": "number",
            "required": false,
        },
        {
            "id": "PD_HEIGHT",
            "title": "Patient Height (Optional <18yrs)",
            "type": "heighttextfield",
            "required": false
        },
        {
            "id": "PD_BMI_WEIGHT",
            "type": "patient_result",
        }
    ]
};
