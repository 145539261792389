import React, { useContext, useEffect, useState } from 'react';
import { InputAdornment, TextField as Field } from '@material-ui/core';
import {
    Edit,
} from '@material-ui/icons';

import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';
import Toggle from '../Toggle';

function WeightTextField({
    field,
}) {
    const [state, dispatch] = useContext(Context);
    const KGConversionRate = 0.45359237;
    const poundsConversionRate = 2.204622;

    const [currentKG, setCurrentKG] = useState(state.PD_WEIGHT > 0 ? state.PD_WEIGHT : '');
    const [currentStones, setCurrentStones] = useState('');
    const [currentPounds, setCurrentPounds] = useState('');
    
    const [isToggleActive, setToggleActive] = useState(false);
    const [isMetric, setIsMetric] = useState(true);

    const setMetric = () => {
        if ( isMetric ){ return; }
        const totalPounds = Number(currentStones * 14) + Number(currentPounds);
        const weightInMetric = totalPounds * KGConversionRate;

        setCurrentKG(Math.round(weightInMetric));
    };
    
    const setImperial = () => {
        if ( !isMetric ){ return; }

        const totalPounds = currentKG * poundsConversionRate;
        const stones = Math.floor(Number(totalPounds / 14));
        const pounds = Math.round(Number(totalPounds % 14));

        setCurrentStones(stones);
        setCurrentPounds(pounds);
    };

    const handleChange = (event, type) => {
        const value = event.target.value;

        if (type === 'kg') {
            setCurrentKG(value);
            setImperial();
        } else if (type === 'stones') {
            setCurrentStones(value);
        } else if (type === 'pounds') {
            setCurrentPounds(value);
        }
    };

    useDebouncedEffect(() => {
        setToggleActive(currentKG > 0);

        dispatch({
            type: field.id,
            payload: currentKG,
        });
    }, [currentKG]);

    useDebouncedEffect(() => {
        setMetric();
    }, [currentStones, currentPounds]);

    // Fired when component mounts
    useEffect(() => {
        if (currentKG > 0) {
            setImperial();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`
            weight-text-field
            c-text-field${field.isResultField ? ' t-result' : ''}
            ${isToggleActive ? ' active' : ''}
        `}>
            <div className="weight-text-field__header">
                <FieldHeader
                    title={field.title}
                    tooltip={field.tooltip}
                    labelFor={field.id}
                />

                <Toggle
                    callback={() => setIsMetric(!isMetric)}
                    isActive={isMetric}
                    label={`Switch to ${isMetric ? 'st / lb' : 'kg'}`}
                />
            </div>

            {
                isMetric ?
                    <Field
                        variant="outlined"
                        value={currentKG}
                        onChange={event => handleChange(event, 'kg')}
                        onBlur={event => handleChange(event, 'kg')}
                        placeholder="Eg 75 kg"
                        type="number"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">{currentKG >= 0 && 'kg'}<Edit /></InputAdornment>
                        }}
                    />
                :
                    <div className="weight-text-field__imperial">
                        <Field
                            variant="outlined"
                            value={currentStones}
                            placeholder="Eg 10 stones"
                            onChange={event => handleChange(event, 'stones')}
                            onBlur={event => handleChange(event, 'stones')}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{currentStones >= 0 && 'stones'}<Edit /></InputAdornment>
                            }}
                        />

                        <Field
                            variant="outlined"
                            value={currentPounds}
                            placeholder="Eg 5 pounds"
                            onChange={event => handleChange(event, 'pounds')}
                            onBlur={event => handleChange(event, 'pounds')}
                            type="number"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">{currentPounds >= 0 && 'pounds'}<Edit /></InputAdornment>
                            }}
                        />
                    </div>
            }
        </div>
    );
}

export default WeightTextField;
