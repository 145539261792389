import { DescriptionOutlined } from '@material-ui/icons';

import ROUTES from "../../../consts/routes.json";
import Privacy from "../../../components/Support/Privacy";

export default {
    "component": Privacy,
    "id": "support_privacy",
    "title": "Privacy",
    "url": ROUTES.SUPPORT_PRIVACY,
    "icon": DescriptionOutlined
};