/* eslint-disable  react-hooks/exhaustive-deps */
import React, { createRef, useContext, useEffect, useRef, lazy, Suspense } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useLocation } from 'react-router-dom';
import { isIOS, isIE } from 'react-device-detect';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Context from '../Context';

const Notification = lazy(() => import('../Notification'));

function SiteWrapper({ children }) {
    const ref = createRef();
    const tooltip = useRef();
    const { pathname } = useLocation();

    const {
        navigationExpanded,
        filterListExpanded,
        tooltipActive,
        handleTooltipActive,
        notification,
        updateNotification,
        loading,
    } = useContext(Context);

    const handleUpdateNotification = () => {
        updateNotification();

        // if (notification.isOneSignalNotification) {
        //   const currentNotifications = localStorage.getItem('userNotifications') ? JSON.parse(localStorage.getItem('userNotifications')) : [];

        //   let result = [ ...currentNotifications ];
        //   // Only save in storage if doesn't exist
        //   if (!result.includes(notification.id)) {
        //     result.push(notification.id);
        //   }

        //   localStorage.setItem('userNotifications', JSON.stringify(result));
        // }
    };

    useEffect(() => {
        const shouldLock = navigationExpanded ||
            loading ||
            tooltipActive ||
            (!isIOS && filterListExpanded);

        if (shouldLock) {
            disableBodyScroll(ref.current, {
                allowTouchMove: el => el.classList.includes('c-tooltip__content'),
            });
        } else {
            enableBodyScroll(ref.current);
        }
    }, [
        navigationExpanded,
        filterListExpanded,
        tooltipActive,
        loading,
    ]);

    const handleTooltipClick = e => {
        if (tooltip.current.contains(e.target)) {
            return;
        }
        handleTooltipActive();
    };

    useEffect(() => {
        window.addEventListener("mousedown", handleTooltipClick);

        return () => {
            window.removeEventListener("mousedown", handleTooltipClick);
        };
    }, []);

    return (
        <>
            <main
                ref={ref}
                className={`${isIE ? 'is-ie' : ''}${pathname === '/' ? 'welcome' : ''}${pathname === '/support/region' ? 'main--support-region' : ''}${pathname === '/regimen' ? 'main--regimen-landing' : ''}${pathname === '/regimen/intermittent' ? 'main--regimen-intermittent' : ''}${pathname === '/regimen/products' ? 'main--regimen-products' : ''}${pathname === '/products' ? 'main--products' : ''}${pathname === '/regimen/report' ? 'main--regimen-report' : ''}`}
            >
                {
                    notification &&
                    <Suspense fallback={<div />}>
                        <Notification
                            title={notification.title}
                            type={notification.type}
                            link={notification.link}
                            handleClose={handleUpdateNotification}
                        >
                            {notification.text}
                        </Notification>
                    </Suspense>
                }
                {children || null}
            </main>

            <div className={`c-tooltip__overlay${tooltipActive ? ' c-tooltip__overlay--active' : ''}`}>
                <IconButton
                    onClick={() => handleTooltipActive()}
                    className="c-tooltip__close"
                    aria-label="Close">
                    <Close />
                </IconButton>
                <div className="c-tooltip__content" ref={tooltip}>
                    {tooltipActive?.type === 'text' ? <p dangerouslySetInnerHTML={{ __html: tooltipActive?.text }} className="c-tooltip__text" /> : null}
                </div>
            </div>
        </>
    );
}

export default SiteWrapper;
