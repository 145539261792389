import React, { useContext, useEffect, useState } from 'react';
import { Button } from '@material-ui/core';
import ReactGA from 'react-ga4';
import { get, } from 'idb-keyval';

import Description from '../../../components/Product/Description';
import PageHeader from '../../../components/PageHeader';
import SiteContext from '../../../components/Context';
import Tabs from '../../../components/Tabs';
import Loader from '../../../components/Loader';
import labels from '../../../data/labels.json';
import { products as ProductsStore } from '../../../utils/db';
// import PageHeaderFixed from '../../../components/PageHeaderFixed';
// import HeaderComparison from '../../../components/ProductSearch/HeaderComparison';
import PageFooter from '../../../components/PageFooter';
import { useHistory } from 'react-router';
import Nutrition from '../../../components/Product/Nutrition';
import DirectionsForUse from '../../../components/Product/DirectionsForUse';

function Product({
    match,
    location,
    productId
}) {
    const history = useHistory();
    const { country } = useContext(SiteContext);
    const [product, /* setProduct */] = useState();
    const { tabs, sample_text } = labels?.pages?.product;
    const sampleDataKey = product?.productSampleAvailability?.key;
    const UKSample = (country === 'uk' && (sampleDataKey === 'uk' || sampleDataKey === 'ukni' || sampleDataKey === 'all'));
    const ROISample = (country === 'roi' && (sampleDataKey === 'roi' || sampleDataKey === 'roini' || sampleDataKey === 'all'));
    const sampleAvailable = UKSample || ROISample;

    const isCompareProduct = location.pathname.includes('/compare');
    const [products, setProducts] = useState([]);
    // const [backUrl, setBackUrl] = useState('');
    const [tempProducts, setTempProducts] = useState([]);
    const isRegimen = location.pathname.includes("regimen");

    useEffect(() => {

        let IDs;
        
        IDs = [match?.params?.id];

        if (match?.params?.idb){
            IDs.push(match?.params?.idb);
        }
        if (match?.params?.idc){
            IDs.push(match?.params?.idc);
        }
        if (match?.params?.idd){
            IDs.push(match?.params?.idd);
        }

        /*
        (IDs || []).map(id => get(id, ProductsStore)
            .then(product => {
                console.log("Product Parent loop through product ids should happen first");
                console.log(product);
                console.log(products);
                setProducts(prev => [...prev, product])
                setTempProducts(prev => [...prev, product]);
            })
            .catch(err => console.log(err)));
        */

        // declare the data fetching function
        const fetchData = async (_ids) => {
            let _products = [];
            for (let i = 0; i < _ids.length; i++){
                let _product = await get(_ids[i], ProductsStore);
                _products.push(_product);
            }

            _products.forEach((item, i) => {
                if (item === undefined){
                    _products.splice(i, 1);
                }
            });

            setProducts([..._products]);
            setTempProducts([..._products]);
        }

        fetchData(IDs);

        /*
        let _products = [];
        for (let i = 0; i < IDs.length; i++){
            get(IDs[i], ProductsStore)
                .then(product => {
                    console.log(product);
                    if (product !== undefined){
                        _products.push(product);
                    }
                    console.log(_products);
                    setProducts([..._products]);
                    setTempProducts([..._products]);
                }).catch(err => console.log(err));
        }
        */

        console.log("Completed");
        

            //setBackUrl(match?.params?.backurl);

        // eslint-disable-next-line
    }, []);


    
    useEffect(() => {
        if (window.matchMedia("(orientation: portrait)").matches && products.length > 2) {
            console.log("setting to first 2");
            setProducts([products[0], products[1]]);
        }
    }, [products])
    

    useEffect(() => {
        function handleResize() {
            console.log("resizing");
            if (products.length <= 2){
                return;
            }
            // vertical
            if (window.matchMedia("(orientation: portrait)").matches) {
                setProducts(prev => [prev[0], prev[1]]);
            } else {
                setProducts(tempProducts);
            }
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });
    

    const handleCancelComparison = () => {
        history.goBack();
    }

    const handleProductRemove = (id) => {
        const newProducts = tempProducts.filter(product => product.id !== id);
        setTempProducts(newProducts);
        setProducts(newProducts);
    }

    const tabList = [
        {
            id: "directions",
            title: tabs.directions,
            component: (
                <DirectionsForUse
                    products={products}
                    country={country}
                    onRemoveProduct={handleProductRemove}
                />
            ),
        },
        {
            id: "description",
            title: tabs.description,
            component: (
                <Description
                    product={product}
                    products={products}
                    country={country}
                    onRemoveProduct={handleProductRemove}
                />
            ),
        },    
        {
            id: "nutritional",
            title: tabs.nutritional,
            component: (
                <Nutrition
                    products={products}
                    onRemoveProduct={handleProductRemove}
                    country={country}
                />
            ),
        }
    ];

    function handleSampleClick() {
        if (!window.location.href.includes('localhost')) {
            ReactGA.event({
                category: 'Product Sample',
                action: 'User Interaction',
                value: true,
            });
        }
    }

    console.log("Products state just before return");
    console.log(products);



    return (
        products ?
            <section className={`c-product ${products.length === 2 ? 'c-product-double' : products.length === 3 ? 'c-product-triple' : products.length === 4 ? 'c-product-quadruple' : ''}`}>
                <PageHeader
                    page_title={isCompareProduct ? labels.pages.products.product_comparison_header : products[0]?.name}
                    showBottomBorder
                    overideParent={isRegimen ? '/regimen/products' : '/products'}
                />
                {/* <PageHeaderFixed
          show
          offset={0}
          page_title={isCompareProduct ? labels.pages.products.product_comparison_header : products[0]?.name}
          showBottomBorder>
          <HeaderComparison onCancelComparison={handleCancelComparison}/>
        </PageHeaderFixed> */}

                <Tabs items={tabList} setDefaultTab={2} />
                <div className="c-product__cta">
                    {
                        !productId && sampleAvailable &&
                        <Button
                            className="e-button"
                            color="primary"
                            size="large"
                            variant="contained"
                            href={UKSample ? 'https://www.nutricia.co.uk/hcp/services/sampling-service.html?utm_source=dieteticapp&utm_medium=link&utm_campaign=ordersample' : 'https://www.nutricia.ie/hcp/services/sampling-service.html?utm_source=dieteticapp&utm_medium=link&utm_campaign=ordersample'}
                            target="_blank"
                            rel="noopener noreferrer"
                            component="a"
                            onClick={handleSampleClick}
                        >
                            {sample_text}
                        </Button>
                    }
                </div>

                <PageFooter>
                    {products.length >= 2 ? (
                        <div className="c-product__removeall">
                            {/* <Link to={ROUTES.PRODUCTS}> */}

                            <Button
                                onClick={handleCancelComparison}
                                size="large"
                                color="primary"
                                fullWidth
                                className="c-product__removeall-button"
                                type="button">
                                {labels?.pages?.products?.product_compare_remove_all}
                            </Button>
                            
                            {/* </Link> */}
                        </div>
                    ) : null}
                </PageFooter>
            </section>
            : <Loader />
    );
}

export default Product;
