import React from 'react';

function Text({
  field,
}) {

  return (
    <div>
      <p>{field.text}</p>
    </div>
  );
}

export default Text;
