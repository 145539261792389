import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import { CheckCircleOutline } from '@material-ui/icons';

import labels from '../../../data/labels.json';
import PageHeader from '../../PageHeader';
import Context from '../../Context';
import COUNTRIES from '../../../data/forms/countries';

export default function Region() {
    const { country, setCountry } = useContext(Context);
    const { page_title } = labels?.pages?.support?.region;

    const handleCountryChange = value => {
        setCountry(value);
        localStorage.setItem('userCountry', value);
    };

    return (
        <div className="support-region">
            <PageHeader
                page_title={page_title}
            />

            <div className="support-region__inner">
                <h1 className="support-region__hint">Choose region</h1>

                <ul className="support-region__countries">
                    {COUNTRIES?.map(COUNTRY => (
                        <li
                            className={`support-region__country${country === COUNTRY.value ? ' active' : '' }`}
                            key={COUNTRY.value}
                        >
                            <Button
                                classes={{ root: `e-button` }}
                                onClick={() => handleCountryChange(COUNTRY.value)}
                            >
                                <span>{COUNTRY.label}</span>

                                <CheckCircleOutline />
                            </Button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}