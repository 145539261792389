import labels from "../../data/labels.json";
import ROUTES from '../../consts/routes.json';

const { items } = labels?.pages?.chatbot;

const Chatbot = [
    {
        "id": "first",
        "title": items.first,
        "url": ROUTES.CHATBOT_PRODUCT,
    },
    {
        "id": "second",
        "title": items.second,
        "url": ROUTES.CHATBOT_GENERAL,
    },
    // {
    //     "id": "third",
    //     "title": items.third,
    //     "url": ROUTES.CHATBOT_STOCK,
    // },
    {
        "id": "fourth",
        "title": items.fourth,
        "url": ROUTES.CHATBOT_FAQ,
    },
];

export default Chatbot;
