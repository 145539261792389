import React from 'react';
import { isIOS, isSafari, isWindows, isChrome, isEdgeChromium, isAndroid, isMacOs, isSamsungBrowser } from 'react-device-detect';

import labels from '../../../data/labels.json';
import PageHeader from '../../PageHeader';
import Instructions from '../../Instructions';

export default function SaveApp() {
    const { description, page_title } = labels?.pages?.support?.save_app;

    const options = [
        {
            id: 'ios',
            items: [
                {
                    id: 1,
                    icon: 'ios-safari-share',
                    text: `Press the '<i>share</i>' button`,
                },
                {
                    id: 2,
                    icon: 'ios-safari-plus',
                    text: `Press '<i>add to home screen</i>'`,
                },
            ],
            active: isIOS && isSafari,
        },
        {
            id: 'windows_chrome',
            items: [
                {
                    id: 1,
                    icon: 'windows-chrome-plus',
                    text: `Click the <i>plus</i> icon in the url bar (next to the <i>star/bookmark</i> icon)`,
                },
                {
                    id: 2,
                    icon: 'windows-chrome-confirm',
                    text: `In the 'Install app?' prompt, click <i>Install</i> to confirm installation.`,
                },
                {
                    id: 3,
                    icon: 'windows-chrome-settings',
                    text: `To uninstall, click the 3 dots at the top of the app and choose <i>Uninstall Nutricia Dietetic App</i>.`,
                },
            ],
            active: (isWindows && isChrome) || (isMacOs && isChrome),
        },
        {
            id: 'windows_edge_chromium',
            items: [
                {
                    id: 1,
                    icon: 'windows-edge-chromium-plus',
                    text: `Click the <i>plus</i> icon in the url bar (next to the <i>star/favorites</i> icon)`,
                },
                {
                    id: 2,
                    icon: 'windows-edge-chromium-confirm',
                    text: `In the 'Install Nutricia Dietetic App?' prompt, click <i>Install</i> to confirm installation.`,
                },
                {
                    id: 3,
                    icon: 'windows-edge-chromium-settings',
                    text: `To uninstall, click the 3 dots at the top of the app and choose <i>Uninstall Nutricia Dietetic App</i>, then click <i>Remove</i> to confirm.`,
                },
            ],
            active: (isWindows && isEdgeChromium) || (isMacOs && isEdgeChromium),
        },
        {
            id: 'android_chrome',
            items: [
                {
                    id: 1,
                    icon: 'android-chrome-plus',
                    text: `Tap the three-dot menu icon.`,
                },
                {
                    id: 2,
                    icon: 'android-chrome-confirm',
                    text: `Tap the <i>Add to Home screen</i> option`,
                },
                {
                    id: 3,
                    icon: 'android-chrome-settings',
                    text: `Tap the <i>Add</i> icon to confirm installation.`,
                },
            ],
            active: isAndroid && isChrome,
        },
        {
            id: 'samsung',
            items: [
                {
                    id: 1,
                    text: `Open the three-line menu in the top-right corner of the browser window.`,                
                },
                {
                    id: 2,
                    text: `Select <i>Add page to</i> and then select Home screen from the list of options`
                }
            ],
            active: isAndroid && isSamsungBrowser,
        },
        {
            id: 'android',
            items: [
                {
                    id: 1,
                    text: `Under <i>settings</i>, tap the <i>Add to Home screen</i> option.`,                
                },
            ],
            active: isAndroid,
        }
    ];

    const content = options.find(e => e.active);

    return (
        <div className="support-save-app">
            <PageHeader
                page_title={page_title}
                subtitle={description}
            />

            { content?.items?.length > 0 ? (
                <Instructions items={content.items} />
            ) : (
                <p>Sorry, your browser cannot currently save this app to your homescreen.</p>
            )}
        </div>
    );
}
