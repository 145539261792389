import React, { /* useContext, */ useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleOutline, ErrorOutline, NavigateNext } from '@material-ui/icons';

// import Context from '../Context';

function StepList({ items }) {
    // const [state] = useContext(Context);
    const [rendered, updateRendered] = useState();

    useEffect(() => updateRendered(true), []);
    const filteredItems = items.filter(item => item.id !== 'intermittent');

    return rendered && filteredItems && filteredItems.length > 0 ?
        <div className="c-step-list">
            <h2>Create a patient regimen</h2>
            <h3>Add details over a few simple steps to create a feeding regimen customised to your patient's specific nutritional requirements</h3>
            <nav>
                <ol className="c-step-list__items">
                    {
                        filteredItems.map((step, index) => (
                            <li
                                className={`c-step-list__item${step.disabled ? '' : ' active'}${(step?.complete && !step.disabled) ? ' complete' : ' incomplete'}`}
                                key={step.id}
                            >
                                <Link
                                    className={`c-step-list__link ${step.disabled ? 'c-step-list__link--disabled' : ''}`}
                                    to={step.disabled ? '#' : step?.url}
                                >
                                    <div className="c-step-list__link__icon">
                                        {(step?.complete && !step.disabled) ? <CheckCircleOutline /> : <ErrorOutline />}

                                    </div>

                                    {step?.navigation_title}

                                    <NavigateNext />
                                </Link>
                            </li>
                        ))
                    }
                </ol>
            </nav>
        </div>

        : null;
}

export default StepList;
