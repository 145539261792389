export function pull() {
    // We need to cache product data immediately for offline access
    // Request access token from commerce tools
    return new Promise((resolve, reject) => { 
        fetch(`${process.env.REACT_APP_CTP_AUTH_URL}/oauth/token?grant_type=client_credentials&scope=${process.env.REACT_APP_CTP_SCOPES}`, {
            method: 'POST',
            headers: {
                "Authorization": "Basic " + btoa(process.env.REACT_APP_CTP_CLIENT_ID + ':' + process.env.REACT_APP_CTP_CLIENT_SECRET),
                "Content-Type": "application/x-www-form-urlencoded",
            },
        })
        .then(response => response.json())
        .then(json => {
            // if token exists, query product data from commerce tools

            fetch(`${process.env.REACT_APP_CTP_API_URL}/${process.env.REACT_APP_CTP_PROJECT_KEY}/categories?limit=500`, {
                method: 'GET',
                headers: {
                    "Authorization": "Bearer " + json.access_token
                },
            })
            .then(res => res.json())
            .then(data => data.results)
            .then(categoryData => {
                fetch(`${process.env.REACT_APP_CTP_API_URL}/${process.env.REACT_APP_CTP_PROJECT_KEY}/product-projections/search?limit=500`, {
                    method: 'GET',
                    headers: {
                        "Authorization": "Bearer " + json.access_token
                    },
                })
                .then(res => res.json())
                .then(data => {
                    if(!data) {
                        return;
                    }

                    // This callback is to clear previous data before new import
                    let db;
                    let request = window.indexedDB.open("nutriciaProducts", 1);

                    request.onsuccess = function (event) {
                        db = event.target.result;

                        let tx = db.transaction(['keyval'], 'readwrite');
                        let store = tx.objectStore('keyval');
                        store.clear();

                        tx.oncomplete = function (event) {
                            // report on the success of the transaction completing, when everything is done
                            window.localStorage.setItem('nutricia-pwa-api-latest', new Date());

                            const results = data.results?.filter(product => product?.masterVariant?.attributes?.find(e => e.name === 'productAppearance')?.value?.key !== 'web');
                            const worker = new Worker('/import-worker.js');
                            worker.postMessage({
                                results,
                                categoryData,
                            });
                            
                            resolve();
                        };

                        tx.onerror = function (event) {
                            reject(tx.error);
                        };
                    }

                    request.onerror = function () {
                        console.error("Error", request.error);
                        reject(request.error);
                    };
                });
            })
        })
        .catch(e => reject(e));
    });
};

export default {
    pull
};