import React from 'react';

function Toggle({
    callback,
    isActive,
    label = null,
}) {
    const handleClick = event => {
        event.preventDefault();

        callback();
    };

    return (
        <label className={`toggle${isActive ? ' active' : ''}`}>
            {
                label && <span className="toggle__label">{label}</span>
            }

            <button className="toggle__button" onClick={handleClick}>
                <div className="toggle__handle"></div>
            </button>
        </label>
    );
}

export default Toggle;