import labels from '../data/labels.json';
import ranges from '../utils/calculation-sets/age-ranges';

export default function generateEmail({
  gender,
  age,
  dateOfBirth,
  weight,
  height,
  BMI,
  protein,
  energy,
  chosenProducts,
  macroNutrients,
  vitamins,
  minerals,
  others,
  country,
}) {
  const ageInYears = Math.floor(age/12);

  return `<!doctype html>
  <html>
    <head>
      <meta name="viewport" content="width=device-width" />
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>${labels.site.email_report.email_title}</title>
      <style>
        /* -------------------------------------
            GLOBAL RESETS
        ------------------------------------- */
        
        /*All the styling goes here*/
        
        img {
          border: none;
          -ms-interpolation-mode: bicubic;
          max-width: 100%; 
        }
  
        body {
          background-color: #f6f6f6;
          font-family: sans-serif;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          line-height: 1.4;
          margin: 0;
          padding: 0;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%; 
        }
  
        table {
          border-collapse: separate;
          mso-table-lspace: 0pt;
          mso-table-rspace: 0pt;
          width: 100%;
        }

        table tr {
          background-color: rgb(246,246,246);
        }

        table td {
          border-spacing: 2px;
          font-family: sans-serif;
          font-size: 14px;
          padding: 5px;
          vertical-align: top; 
        }

        table td:last-child {
          text-align: right;
        }
  
        table th {
          padding: 10px 0px;
          background-color: #6200EE;
          color: rgb(255, 255, 255);
        }

        .bottom-border {
          border-bottom: 1px solid #ffffff;
        }

        .three-col td {
          width: 33.333333%;
        }

        .three-col-1 {
          text-align: center;
        }

        .three-col-2 {
          text-align: right;
        }

        /* -------------------------------------
            BODY & CONTAINER
        ------------------------------------- */
  
        .body {
          background-color: #f6f6f6;
          width: 100%; 
        }
  
        /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
        .container {
          display: block;
          margin: 0 auto !important;
          /* makes it centered */
          max-width: 580px;
          padding: 10px;
          width: 580px; 
        }
  
        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 580px;
          padding: 10px; 
        }
  
        /* -------------------------------------
            HEADER, FOOTER, MAIN
        ------------------------------------- */
        .main {
          background: #ffffff;
          border-radius: 3px;
          width: 100%; 
        }
  
        .wrapper {
          box-sizing: border-box;
          padding: 20px; 
        }
  
        .content-block {
          padding-bottom: 10px;
          padding-top: 10px;
        }
  
        .footer {
          clear: both;
          margin-top: 10px;
          text-align: center;
          width: 100%; 
        }
          .footer td,
          .footer p,
          .footer span,
          .footer a {
            color: #999999;
            font-size: 12px;
            text-align: center; 
        }
  
        /* -------------------------------------
            TYPOGRAPHY
        ------------------------------------- */
        h1,
        h2,
        h3,
        h4 {
          color: #6200EE;
          font-family: sans-serif;
          font-weight: 400;
          line-height: 1.4;
          margin: 0;
          margin-bottom: 30px; 
        }
  
        h1 {
          font-size: 35px;
          font-weight: 300;
          text-align: center;
          text-transform: capitalize; 
        }

        h2 {
          margin-bottom: 10px;
        }

        h3 {
          font-weight: bold;
        }

        p,
        ul,
        ol {
          font-family: sans-serif;
          font-size: 14px;
          font-weight: normal;
          margin: 0;
          margin-bottom: 15px; 
        }
          p li,
          ul li,
          ol li {
            list-style-position: inside;
            margin-left: 5px; 
        }
  
        a {
          color: #3498db;
          text-decoration: underline; 
        }
  
        /* -------------------------------------
            BUTTONS
        ------------------------------------- */
        .btn {
          box-sizing: border-box;
          width: 100%; }
          .btn > tbody > tr > td {
            padding-bottom: 15px; }
          .btn table {
            width: auto; 
        }
          .btn table td {
            background-color: #ffffff;
            border-radius: 5px;
            text-align: center; 
        }
          .btn a {
            background-color: #ffffff;
            border: solid 1px #3498db;
            border-radius: 5px;
            box-sizing: border-box;
            color: #3498db;
            cursor: pointer;
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            margin: 0;
            padding: 12px 25px;
            text-decoration: none;
            text-transform: capitalize; 
        }
  
        .btn-primary table td {
          background-color: #3498db; 
        }
  
        .btn-primary a {
          background-color: #3498db;
          border-color: #3498db;
          color: #ffffff; 
        }
  
        /* -------------------------------------
            OTHER STYLES THAT MIGHT BE USEFUL
        ------------------------------------- */
        .last {
          margin-bottom: 0; 
        }
  
        .first {
          margin-top: 0; 
        }
  
        .align-center {
          text-align: center; 
        }
  
        .align-right {
          text-align: right; 
        }
  
        .align-left {
          text-align: left; 
        }
  
        .clear {
          clear: both; 
        }
  
        .mt0 {
          margin-top: 0; 
        }
  
        .mb0 {
          margin-bottom: 0; 
        }
  
        .preheader {
          color: transparent;
          display: none;
          height: 0;
          max-height: 0;
          max-width: 0;
          opacity: 0;
          overflow: hidden;
          mso-hide: all;
          visibility: hidden;
          width: 0; 
        }
  
        .powered-by a {
          text-decoration: none; 
        }
  
        hr {
          border: 0;
          border-bottom: 1px solid #f6f6f6;
          margin: 20px 0; 
        }
  
        /* -------------------------------------
            RESPONSIVE AND MOBILE FRIENDLY STYLES
        ------------------------------------- */
        @media only screen and (max-width: 620px) {
          table[class=body] h1 {
            font-size: 28px !important;
            margin-bottom: 10px !important; 
          }
          table[class=body] p,
          table[class=body] ul,
          table[class=body] ol,
          table[class=body] td,
          table[class=body] span,
          table[class=body] a {
            font-size: 16px !important; 
          }
          table[class=body] .wrapper,
          table[class=body] .article {
            padding: 10px !important; 
          }
          table[class=body] .content {
            padding: 0 !important; 
          }
          table[class=body] .container {
            padding: 0 !important;
            width: 100% !important; 
          }
          table[class=body] .main {
            border-left-width: 0 !important;
            border-radius: 0 !important;
            border-right-width: 0 !important; 
          }
          table[class=body] .btn table {
            width: 100% !important; 
          }
          table[class=body] .btn a {
            width: 100% !important; 
          }
          table[class=body] .img-responsive {
            height: auto !important;
            max-width: 100% !important;
            width: auto !important; 
          }
        }
  
        /* -------------------------------------
            PRESERVE THESE STYLES IN THE HEAD
        ------------------------------------- */
        @media all {
          .ExternalClass {
            width: 100%; 
          }
          .ExternalClass,
          .ExternalClass p,
          .ExternalClass span,
          .ExternalClass font,
          .ExternalClass td,
          .ExternalClass div {
            line-height: 100%; 
          }
          .apple-link a {
            color: inherit !important;
            font-family: inherit !important;
            font-size: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
            text-decoration: none !important; 
          }
          #MessageViewBody a {
            color: inherit;
            text-decoration: none;
            font-size: inherit;
            font-family: inherit;
            font-weight: inherit;
            line-height: inherit;
          }
          .btn-primary table td:hover {
            background-color: #34495e !important; 
          }
          .btn-primary a:hover {
            background-color: #34495e !important;
            border-color: #34495e !important; 
          } 
        }
  
      </style>
    </head>
    <body class="">
      <span class="preheader">${labels.site.email_report.preheader}</span>
      <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
        <tr>
          <td>&nbsp;</td>
          <td class="container">
            <div class="content">
  
              <!-- START CENTERED WHITE CONTAINER -->
              <table role="presentation" class="main">
  
                <!-- START MAIN CONTENT AREA -->
                <tr>
                  <td class="wrapper">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td>
                          <h1>Nutricia</h1>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <!-- PATIENT INFORMATION AREA -->
                <tr>
                  <td class="wrapper">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td colspan="2">
                          <h2>${labels.site.email_report.patient_title}</h2>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-left bottom-border">
                          <strong>${labels.site.email_report.patient_gender}:</strong>
                        </td>
                        <td class="align-right bottom-border">
                          ${gender.charAt(0).toUpperCase() + gender.slice(1) || ''}
                        </td>
                      </tr>
                      <tr>
                        <td class="align-left bottom-border">
                          <strong>${labels.site.email_report.patient_age}:</strong>
                        </td>
                        <td class="align-right bottom-border">
                          ${age <= ranges.to.oneYear ? `${age} month${age !== 1 ? 's' : ''}` : `${ageInYears} year${ageInYears !== 1 ? 's' : ''}`}
                        </td>
                      </tr>
                      ${
                        dateOfBirth ? `
                          <tr>
                            <td class="align-left bottom-border">
                              <strong>${labels.site.email_report.patient_dob}:</strong>
                            </td>
                            <td class="align-right bottom-border">
                              ${dateOfBirth || ''}
                            </td>
                          </tr>
                        `
                        : ''
                      }
                      <tr>
                        <td class="align-left bottom-border">
                          <strong>${labels.site.email_report.patient_height}:</strong>
                        </td>
                        <td class="align-right bottom-border">
                          ${height || ''}cm
                        </td>
                      </tr>
                      <tr>
                        <td class="align-left bottom-border">
                          <strong>${labels.site.email_report.patient_weight}:</strong>
                        </td>
                        <td class="align-right bottom-border">
                          ${weight || ''}kgs
                        </td>
                      </tr>
                      <tr>
                        <td class="align-left bottom-border">
                          <strong>${labels.site.email_report.patient_bmi}:</strong>
                        </td>
                        <td class="align-right bottom-border">
                          ${age <= ranges.to.oneYear ? 'N/A' : `${BMI?.toFixed(1)}kg/m²`}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <!-- ENERGY AND PROTEIN REQUIREMENTS AREA -->
                <tr>
                  <td class="wrapper">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td colspan="2">
                          <h2>${labels.site.email_report.requirements_title}</h2>
                        </td>
                      </tr>
                      <tr>
                        <td class="align-left bottom-border">
                          <strong>${labels.site.email_report.energy_title}:</strong>
                        </td>
                        <td class="align-right bottom-border">
                          ${energy ? `${energy}kcal` : 'Not selected'}
                        </td>
                      </tr>
                      <tr>
                        <td class="align-left bottom-border">
                          <strong>${labels.site.email_report.protein_title}:</strong>
                        </td>
                        <td class="align-right bottom-border">
                          ${protein ? `${Number(protein).toFixed(1)}g` : 'Not selected'}
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <!-- FEEDING REGIMEN AREA -->
                <tr>
                  <td class="wrapper">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td>
                          <h2>${labels.site.email_report.regimen_title}</h2>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                      ${
                        chosenProducts && chosenProducts.length > 0 ?
                          chosenProducts.map(product => `
                            <tr>
                              <td class="align-left bottom-border">
                                <strong>${product.name || ''}</strong>
                              </td>
                              <td class="bottom-border align-right">
                                ${product?.volume || ''}${product?.volumeUnit || ''}
                              </td>
                            </tr>
                          `).join('')
                        : null
                      }
                    </table>
                  </td>
                </tr>

                <!-- PRODUCT COMPARISON AREA -->
                <tr>
                  <td class="wrapper">
                    <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                      <tr>
                        <td>
                          <h2>${labels.site.email_report.comparison_title}</h2>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3>All nutrients</h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        ${
                          others && others.length > 0 ?
                            others.map(nutrient => `
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                  <tr>
                                    <td align="left">
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                          <th colspan="2">
                                            ${nutrient.title}
                                          </th>
                                        </tr>
                                        ${
                                          nutrient.items && nutrient.items.length > 0 ?
                                            nutrient.items.map(mineralItem => (`
                                              <tr>
                                                <td class="align-left bottom-border">
                                                  <strong>${mineralItem.title}</strong>
                                                </td>
                                                <td class="align-right bottom-border">
                                                  ${mineralItem.value ? Number(mineralItem.value).toFixed(1) : 0}
                                                </td>
                                              </tr>
                                            `)).join('')
                                          : null
                                        }
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            `).join('')
                          : null
                        }
                        </td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>
                          <h3>${labels.site.email_report.macronutrients}</h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          ${
                            macroNutrients && macroNutrients.length > 0 ?
                              macroNutrients.map(nutrient => `
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td align="left">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tbody>
                                            <tr>
                                              <th colspan="2">
                                                ${nutrient.title}
                                              </th>
                                            </tr>
                                            <tr>
                                              <td>
                                                ${
                                                  nutrient.items && nutrient.items.length > 0 ?
                                                    nutrient.items.map(item => `
                                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                                        <tbody>
                                                          <tr>
                                                            <td class="align-left bottom-border">
                                                              <strong>${item.title}</strong>
                                                            </td>
                                                            <td class="align-right bottom-border">
                                                              ${item.value ? Number(item.value).toFixed(1) : 0}
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                `).join('')
                                                : null
                                              }
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              `).join('')
                            : null
                          }
                        </td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>
                          <h3>${labels.site.email_report.minerals}</h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          ${
                            minerals && minerals.length > 0 ?
                              minerals.map(nutrient => `
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td align="left">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                          <tbody>
                                          <tr>
                                            <th colspan="3">
                                              ${nutrient.title}
                                            </th>
                                          </tr>
                                          ${
                                            nutrient.items && nutrient.items.length > 0 ?
                                              `
                                                <tr class="three-col">
                                                  ${
                                                    nutrient.items.map((mineralItem, index) => (`
                                                      <td class="three-col-${index}">
                                                        <strong>${mineralItem.title}</strong>
                                                      </td>
                                                    `)).join('')
                                                  }
                                                </tr>
                                                <tr class="three-col">
                                                  ${
                                                    nutrient.items.map((mineralItem, index) => (`
                                                      <td class="three-col-${index}">
                                                        ${mineralItem.value ? Number(mineralItem.value).toFixed(1) : 0}
                                                      </td>
                                                    `)).join('')
                                                  }
                                                </tr>
                                              `
                                            : null
                                          }
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              `).join('')
                            : null
                          }
                        </td>
                      </tr>

                      <tr>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>
                          <h3>${labels.site.email_report.vitamins}</h3>
                        </td>
                      </tr>
                      <tr>
                        <td>
                        ${
                          vitamins && vitamins.length > 0 ?
                            vitamins.map(nutrient => `
                              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                <tbody>
                                  <tr>
                                    <td align="left">
                                      <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                        <tbody>
                                        <tr>
                                          <th colspan="3">
                                           ${nutrient.title}
                                           ${((nutrient.tooltip && typeof nutrient.tooltip.country === (undefined)) || (nutrient.tooltip?.country && nutrient.tooltip?.country === country)) ? '<br><small>' + nutrient.tooltip.text + '</small>' : ''}
                                          </th>
                                        </tr>
                                        ${
                                          nutrient.items && nutrient.items.length > 0 ?
                                            `
                                              <tr class="three-col">
                                                ${
                                                  nutrient.items.map((vitaminItem, index) => (`
                                                    <td class="three-col-${index}">
                                                      <strong>${vitaminItem.title}</strong>
                                                    </td>
                                                  `)).join('')
                                                }
                                              </tr>
                                              <tr class="three-col">
                                                ${
                                                  nutrient.items.map((vitaminItem, index) => (`
                                                    <td class="three-col-${index}">
                                                      ${vitaminItem.value ? Number(vitaminItem.value).toFixed(1) : 0}
                                                    </td>
                                                  `)).join('')
                                                }
                                              </tr>
                                            `
                                          : null
                                        }
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            `).join('')
                          : null
                        }
                        </td>
                      </tr>
                      
                    </table>
                  </td>
                </tr>
            </div>
          </td>
          <td>&nbsp;</td>
        </tr>
      </table>
    </body>
  </html>`;
}
