import React, { useContext, memo } from 'react';

import Context from '../Context';

function Tooltip({
  content,
}) {
  const { handleTooltipActive } = useContext(Context);
  const handleOpen = () => handleTooltipActive(content);

  return (
    <div className="c-tooltip">
      <button
        onClick={handleOpen}
        type="button"
        className="c-tooltip__open"
      >
        i
      </button>
    </div>
  );
}

export default memo(Tooltip);
