import labels from "../../../data/labels.json";
import ROUTES from "../../../consts/routes.json";
import Report from "../../../components/Regimen/Report";

const { step_5 } = labels?.pages?.regimen;

export default {
    "id": "report",
    "url": ROUTES.CREATE_REGIMEN_5,
    "next_step": ROUTES.CREATE_REGIMEN,
    "navigation_title": step_5?.navigation_title,
    "step_title": step_5?.step_title,
    "component": Report,
    "dependant": "product_comparison",
    "fields": [
        {
            "id": "RR_REPORT",
            "type": "regimen_report",
        },
        { // this is here for validation
            "id": "PC_SELECTED_PRODUCTS",
            "title": "Selected Products",
            "required": true,
        },
    ],
}
