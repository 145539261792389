import React from 'react';
import Instructions from '../Instructions';
import { isSafari, isChrome, isFirefox, isEdge, isEdgeChromium } from 'react-device-detect';
import labels from '../../data/labels.json';

function NotificationBlockedInstructions() {
  const options = [
    {
      id: 'chrome',
      items: [
        {
          id: 1,
          icon: 'chrome-padlock',
          text: `Click the padlock icon to the left of the url bar`,
        },
        {
          id: 2,
          text: `When prompted, changed the <i>Notifications</i> dropdown to <i>Allow</i>.`,
        },
        {
          id: 3,
          text: `Refresh your browser for the new settings to update.`,
        },
      ],
      active: isChrome,
    },
    {
      id: 'safari',
      items: [
        {
          id: 1,
          text: `Open <i>Preferences</i> in Safari`,
        },
        {
          id: 2,
          text: `Go to the <i>Websites</i> tab`,
        },
        {
          id: 3,
          text: `Under <i>Notifications</i>, find the <i>Nutricia</i> website and change to <i>Allow</i>`,
        },
      ],
      active: isSafari,
    },
    {
      id: 'firefox',
      items: [
        {
          id: 1,
          icon: 'firefox-padlock',
          text: `If you have disabled notifications, click the padlock icon in the address bar`,
        },
        {
          id: 2,
          icon: 'firefox-arrow',
          text: `Click the arrow next to <i>Connection Secure</i>.`,
        },
        {
          id: 3,
          text: `Click <i>More Information</i>`,
        },
        {
          id: 4,
          text: `Click the <i>Permissions</i> tab`,
        },
        {
          id: 5,
          text: `Scroll down to <i>Send Notifications</i> and uncheck <i>Use Default</i>`,
        },
        {
          id: 6,
          text: `Click <i>Allow</i> and refresh the page.`,
        },
      ],
      active: isFirefox,
    },
    {
      id: 'edge_chromium',
      items: [
        {
          id: 1,
          icon: 'edge-chromium-padlock',
          text: `Click the padlock icon next to the address bar`,
        },
        {
          id: 2,
          text: `When prompted, change the <i>Notifications</i> setting to <i>Allow</i>`,
        },
        {
          id: 3,
          text: `Refresh the page`,
        },
      ],
      active: isEdgeChromium,
    },
    {
      id: 'edge',
      items: [
        {
          id: 1,
          icon: 'edge-padlock',
          text: `Click the padlock icon in the address bar`,
        },
        {
          id: 2,
          text: `When prompted, toggle the <i>Notifications</i> setting on, under <i>Website permissions</i>`,
        },
        {
          id: 3,
          text: `Refresh the page`,
        },
      ],
      active: isEdge,
    },
  ];

  const content = options.find(e => e.active);

  return (
    content?.items?.length > 0 ?
      <Instructions items={content.items} />
      : <p>{ labels.site.notification_blocked }</p>
  );
}

export default NotificationBlockedInstructions;
