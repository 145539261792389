import ranges from '../../../age-ranges';

const male = [
  {
    range: [ ranges.from.zero, ranges.to.threeMonths],
    kcal_day: 0,
    kcal_kg_day: 96,
    label: '96 - 120',
  },
  {
    range: [ ranges.from.threeMonths, ranges.to.fiveMonths],
    kcal_day: 0,
    kcal_kg_day: 96,
    label: '96',
  },
  {
    range: [ ranges.from.fiveMonths, ranges.to.sevenMonths],
    kcal_day: 0,
    kcal_kg_day: 72,
    label: '72 - 96',
  },
  {
    range: [ ranges.from.sevenMonths, ranges.to.oneYear],
    kcal_day: 0,
    kcal_kg_day: 72,
    label: '72',
  },
  {
    range: [ ranges.from.oneYear, ranges.to.twoYears],
    kcal_day: 770,
    kcal_kg_day: 80,
  },
  {
    range: [ ranges.from.twoYears, ranges.to.threeYears],
    kcal_day: 1000,
    kcal_kg_day: 82,
  },
  {
    range: [ ranges.from.threeYears, ranges.to.fourYears],
    kcal_day: 1170,
    kcal_kg_day: 82,
  },
  {
    range: [ ranges.from.fourYears, ranges.to.fiveYears],
    kcal_day: 1390,
    kcal_kg_day: 85,
  },
  {
    range: [ ranges.from.fiveYears, ranges.to.sixYears],
    kcal_day: 1480,
    kcal_kg_day: 80,
  },
  {
    range: [ ranges.from.sixYears, ranges.to.sevenYears],
    kcal_day: 1560,
    kcal_kg_day: 74,
  },
  {
    range: [ ranges.from.sevenYears, ranges.to.eightYears],
    kcal_day: 1650,
    kcal_kg_day: 71,
  },
  {
    range: [ ranges.from.eightYears, ranges.to.nineYears],
    kcal_day: 1750,
    kcal_kg_day: 67,
  },
  {
    range: [ ranges.from.nineYears, ranges.to.tenYears],
    kcal_day: 1840,
    kcal_kg_day: 63,
  },
  {
    range: [ ranges.from.tenYears, ranges.to.elevenYears],
    kcal_day: 2030,
    kcal_kg_day: 64,
  },
  {
    range: [ ranges.from.elevenYears, ranges.to.twelveYears],
    kcal_day: 2130,
    kcal_kg_day: 62,
  },
  {
    range: [ ranges.from.twelveYears, ranges.to.thirteenYears],
    kcal_day: 2250,
    kcal_kg_day: 59,
  },
  {
    range: [ ranges.from.thirteenYears, ranges.to.fourteenYears],
    kcal_day: 2410,
    kcal_kg_day: 56,
  },
  {
    range: [ ranges.from.fourteenYears, ranges.to.fifteenYears],
    kcal_day: 2630,
    kcal_kg_day: 54,
  },
  {
    range: [ ranges.from.fifteenYears, ranges.to.sixteenYears],
    kcal_day: 2820,
    kcal_kg_day: 51,
  },
  {
    range: [ ranges.from.sixteenYears, ranges.to.seventeenYears],
    kcal_day: 2970,
    kcal_kg_day: 49,
  },
  {
    range: [ ranges.from.seventeenYears, ranges.to.eighteenYears],
    kcal_day: 3080,
    kcal_kg_day: 48,
  },
  {
    range: [ ranges.from.eighteenYears, ranges.to.max],
    kcal_day: 3160,
    kcal_kg_day: 48,
  },
];

const female = [
  {
    range: [ ranges.from.zero, ranges.to.threeMonths ],
    kcal_day: 0,
    kcal_kg_day: 96,
    label: '96 - 120',
  },
  {
    range: [ ranges.from.threeMonths, ranges.to.fiveMonths ],
    kcal_day: 0,
    kcal_kg_day: 96,
    label: '96',
  },
  {
    range: [ ranges.from.fiveMonths, ranges.to.sevenMonths ],
    kcal_day: 0,
    kcal_kg_day: 72,
    label: '72 - 96',
  },
  {
    range: [ ranges.from.sevenMonths, ranges.to.oneYear ],
    kcal_day: 0,
    kcal_kg_day: 72,
    label: '72',
  },
  {
    range: [ ranges.from.oneYear, ranges.to.twoYears ],
    kcal_day: 720,
    kcal_kg_day: 80,
  },
  {
    range: [ ranges.from.twoYears, ranges.to.threeYears ],
    kcal_day: 930,
    kcal_kg_day: 81,
  },
  {
    range: [ ranges.from.threeYears, ranges.to.fourYears ],
    kcal_day: 1080,
    kcal_kg_day: 78,
  },
  {
    range: [ ranges.from.fourYears, ranges.to.fiveYears ],
    kcal_day: 1290,
    kcal_kg_day: 81,
  },
  {
    range: [ ranges.from.fiveYears, ranges.to.sixYears ],
    kcal_day: 1360,
    kcal_kg_day: 75,
  },
  {
    range: [ ranges.from.sixYears, ranges.to.sevenYears ],
    kcal_day: 1480,
    kcal_kg_day: 70,
  },
  {
    range: [ ranges.from.sevenYears, ranges.to.eightYears ],
    kcal_day: 1530,
    kcal_kg_day: 67,
  },
  {
    range: [ ranges.from.eightYears, ranges.to.nineYears ],
    kcal_day: 1630,
    kcal_kg_day: 63,
  },
  {
    range: [ ranges.from.nineYears, ranges.to.tenYears ],
    kcal_day: 1720,
    kcal_kg_day: 59,
  },
  {
    range: [ ranges.from.tenYears, ranges.to.elevenYears ],
    kcal_day: 1940,
    kcal_kg_day: 61,
  },
  {
    range: [ ranges.from.elevenYears, ranges.to.twelveYears ],
    kcal_day: 2020,
    kcal_kg_day: 56,
  },
  {
    range: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
    kcal_day: 2100,
    kcal_kg_day: 53,
  },
  {
    range: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
    kcal_day: 2220,
    kcal_kg_day: 48,
  },
  {
    range: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
    kcal_day: 2340,
    kcal_kg_day: 46,
  },
  {
    range: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
    kcal_day: 2390,
    kcal_kg_day: 45,
  },
  {
    range: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
    kcal_day: 2410,
    kcal_kg_day: 44,
  },
  {
    range: [ ranges.from.seventeenYears, ranges.to.eighteenYears ],
    kcal_day: 2460,
    kcal_kg_day: 43,
  },
  {
    range: [ ranges.from.eighteenYears, ranges.to.max ],
    kcal_day: 2460,
    kcal_kg_day: 43,
  },
];


const getResult = ({
  age,
  gender,
}) => {
  let result;
  const data = gender === 'male' ? male : female;
  data.forEach(e => {
    if (age >= e.range[0] && age <= e.range[1]) {
      result = {
        kcal_day: e.kcal_day,
        kcal_kg_day: e.kcal_kg_day,
        label: e.label,
      };
    }
  });
  return result || {
    kcal_day: 0,
    kcal_kg_day: 0,
  };
};

export default getResult;
