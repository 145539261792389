import React, { useContext } from 'react';
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import Context from '../../Regimen/Context';

function RadioList({
    field,
}) {
    const [state, dispatch] = useContext(Context);
    const currentValue = state[field.id] || '';
    const handleChange = event => {
        dispatch({
            type: field.id,
            payload: event.target.value,
        });
    };

    return (
        <FormControl
            component="fieldset"
            className="c-radio-list"
            required={field.required}
        >
            <FormLabel
                className="c-radio-list__label"
                component="legend"
            >
                {field.title}
            </FormLabel>
            {
                field.items && field.items.length > 0 ?
                    <RadioGroup
                        className={`c-radio-list__group${field.class ? ` ${field.class}` : ''}`}
                        aria-label={field.title}
                        name={field.id}
                        value={currentValue}
                        onChange={handleChange}
                    >
                        {
                            field.items.map(item =>
                                <FormControlLabel
                                    className={`c-radio-list__option ${currentValue === item.value ? 'c-radio-list__option--active' : ''}`}
                                    key={item.value}
                                    value={item.value}
                                    control={<Radio />}
                                    labelPlacement="start"
                                    label={item.title}
                                />
                            )
                        }
                    </RadioGroup>
                    : null
            }
        </FormControl>
    );
}

export default RadioList;
