import React, { useContext, useEffect, useState, createRef } from 'react';
import {
	FormControl,
	// InputLabel,
	InputAdornment,
	// OutlinedInput,
	Button,
	TextField,
} from '@material-ui/core';
import { /* Close, */ FilterList, ChevronRight } from '@material-ui/icons';
import Context from '../Context';
import SiteContext from '../../Context';
import labels from '../../../data/labels.json';
import useDebounce from '../../../utils/hooks/useDebounce';
// import SearchField from '../../Form/SearchField';

function Form(comparedProduct) {
	const [_, dispatch] = useContext(Context); // eslint-disable-line
	const { filterListExpanded, handleFilterListExpanded } = useContext(SiteContext);
	const [searchInputValue, updateInput] = useState('');
	const debouncedSearchTerm = useDebounce(searchInputValue, 100);
	
	const input = createRef();
	
	const handleChange = (event) => {
		updateInput(event.target.value);
		sessionStorage.setItem('searchTerm', event.target.value);
	};
	const handleReset = () => {
		updateInput('');
		dispatch({
			type: 'UPDATE_SEARCH_TERM',
			payload: '',
		});
	};

	const handleSubmit = (event) => event.preventDefault();

	useEffect(() => {
		if (sessionStorage.getItem('searchTerm')){

			updateInput(sessionStorage.getItem('searchTerm'));

			dispatch({
				type: 'UPDATE_SEARCH_TERM',
				payload: searchInputValue,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		dispatch({
			type: 'UPDATE_SEARCH_TERM',
			payload: searchInputValue,
		});

		// eslint-disable-next-line
	}, [debouncedSearchTerm]);

	
	


	return (
		<form className='c-product-search-form' onSubmit={handleSubmit}>
			<FormControl>
				{/* <InputLabel htmlFor="product-search">{labels?.pages?.products?.product_search}</InputLabel> */}
				<TextField
					id='product-search'
					placeholder={labels?.pages?.products?.product_search}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<button
										onClick={handleReset}
										type='button'
										className='c-product-search-form__control'
										aria-label='Clear search term'>
										<ChevronRight />
									</button>
							</InputAdornment>
						),
					}}
					inputProps={{
						ref: input,
					}}
					onChange={handleChange}
					value={searchInputValue}
					// labelWidth={130} // This should match the width of the active field label
				/>
				<Button
                    color="primary"
                    endIcon={<FilterList />}
                    onClick={() => handleFilterListExpanded(!filterListExpanded)}
                    size="large"
                    variant="contained"
                    className={`c-product-search-form__filterbutton`}
                    type="button">
                    {labels?.pages?.products?.product_search_filter}
                </Button>
			</FormControl>
		</form>
	);
}

export default Form;
