import React, { useState, useContext } from 'react';
import labels from '../../../data/labels.json';
import {
  TextField as Field,
  InputAdornment,
} from '@material-ui/core';
import {
  Edit,
} from '@material-ui/icons';
import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';

function ImperialTab() {
  const [ state, dispatch ] = useContext(Context);
  const [ totalInCentimeters, updateTotal ] = useState(state.PD_HEIGHT || 0);

  const inchesInFeet = 12;  
  const feetMultiplier = 0.393700;  
  const feetLimit = 8;
  const inchesLimit = 11;

  const CMToFeet = (totalInCentimeters * feetMultiplier) / inchesInFeet;
  const feetTotal = Math.floor(CMToFeet);
  const inchesTotal = ((CMToFeet - feetTotal) * inchesInFeet);

  const [ feetValue, updateFeetChange ] = useState(feetTotal || '');
  const [ inchesValue, updateInchesChange ] = useState(inchesTotal ? inchesTotal.toFixed(2) : '');

  // const [ feetValid, setFeetValid ] = useState(true);
  // const [ inchesValid, setInchesValid ] = useState(true);

  const handleFeetChange = event => {
    const value = event.target.value;
    updateFeetChange(value);

    // Uncomment to apply field validation
    // if (value >= 0 && value <= feetLimit) {
    //   updateFeetChange(value);
    //   setFeetValid(true);
    // } else {
    //   setFeetValid(false);
    // }
  };

  const handleInchesChange = event => {
    const value = event.target.value;
    updateInchesChange(value);

    // Uncomment to apply field validation
    // if (value >= 0 && value <= inchesLimit) {
    //   updateInchesChange(value);
    //   setInchesValid(true);
    // } else {
    //   setInchesValid(false);
    // }
  };

  useDebouncedEffect(() => {
    const feetToCM = Number(feetValue || 0) * 30.48;
    const inchesToCM = Number(inchesValue || 0) * 2.54;
    const result = Math.round(feetToCM + inchesToCM);

    updateTotal(result);
  }, [ feetValue, inchesValue ]);

  useDebouncedEffect(() => {
    dispatch({
      type: 'PD_HEIGHT',
      payload: totalInCentimeters,
    });
  }, [ totalInCentimeters ]);

  const {
    feet_title,
    inches_title,
  } = labels?.pages?.regimen;

  return (
    <div className="c-height-picker__controls">
      <div className="c-height-picker__dropdown">
        <FieldHeader
          title={feet_title}
          labelFor="PD_HEIGHT_FEET"
        />
        <div className="c-text-field">
          <Field
            id="PD_HEIGHT_FEET"
            variant="outlined"
            onChange={handleFeetChange}
            value={feetValue}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
                max: feetLimit,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Edit />
                </InputAdornment>
              )
            }}
          />
          {/* { !feetValid && <p className="e-error">Please enter a value below or equal to {feetLimit}</p> } */}
        </div>
      </div>

      <div className="c-height-picker__dropdown">
        <FieldHeader
          title={inches_title}
          labelFor="PD_HEIGHT_INCHES"
        />
        <div className="c-text-field">
          <Field
            id="PD_HEIGHT_INCHES"
            variant="outlined"
            onChange={handleInchesChange}
            value={inchesValue}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
                max: inchesLimit,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Edit />
                </InputAdornment>
              )
            }}
          />
          {/* { !inchesValid && <p className="e-error">Please enter a value below or equal to {inchesLimit}</p> } */}
        </div>
      </div>
    </div>
  );
}

export default ImperialTab;
