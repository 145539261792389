import 'intersection-observer';
import React, { useContext, useState, lazy, Suspense } from 'react';
import { Link } from 'react-router-dom';
import { Button, Dialog, IconButton } from '@material-ui/core';
import { Close, FilterList, AssignmentInd } from '@material-ui/icons';
import { useInView } from 'react-intersection-observer';

import Context from './Context';
import SiteContext from '../Context';
import RegimenContext from '../Regimen/Context';
import Loader from '../Loader';
import { groupArrayByLetter } from '../../utils';
import labels from '../../data/labels.json';
import ROUTES from '../../consts/routes.json';
import PageFooter from '../PageFooter';

const ProductList = lazy(() => import('./List'));
const Product = lazy(() => import('../../templates/Products/Product'));
const Modal = lazy(() => import('../../components/Regimen/ProductComparison/FilteredList/Modal'));

function ProductSearch({ isRegimenComparison, comparedProduct, setComparedProduct }) {
    const [state, dispatch] = useContext(Context);
    const { filterListExpanded, handleFilterListExpanded } = useContext(SiteContext);
    const [regimenState, regimenDispatch] = useContext(RegimenContext);
    const groupItems = groupArrayByLetter(state.results || regimenState.PRODUCT_RESULTS || regimenState.DEFAULT_PRODUCT_LIST || []);
    const { search_results_default_title, search_results_none } = labels?.pages?.products;

    const [selectedProduct, setSelectedProduct] = useState();
    const [productInView, setProductInView] = useState();

    const { ref } = useInView();


    const currentSelectedProducts = regimenState.PC_SELECTED_PRODUCTS || [];

    const handleSetProductInView = id => setProductInView(id);

    const handleClickOpen = product => {
        const newProduct = {
            ...product,
            volume: 0,
            volumeUnit: '',
        };

        if (currentSelectedProducts.filter(tempProduct => tempProduct.id === product.id).length > 0) {
            handleAddProductToComparison(newProduct); // Remove from selected products
        } else {
            setSelectedProduct(newProduct);
        }
    };

    const handleClose = () => setSelectedProduct();

    const handleAddProductToComparison = (product, volume, volumeUnit) => {
        if (!product) return;

        let newProducts = [...currentSelectedProducts];
        const index = newProducts.findIndex(e => e.id === product.id);

        if (index === -1) {
            newProducts.push({
                ...product,
                volume,
                volumeUnit,
            });
        } else {
            newProducts = newProducts.filter(e => e.id !== product.id);
        }

        regimenDispatch({
            type: 'PC_SELECTED_PRODUCTS',
            payload: newProducts,
        });

        setSelectedProduct();
    };

    const handleProductCompare = async (id) => {
        if (!comparedProduct.includes(id) && comparedProduct.length < 4) {
            setComparedProduct(prev => [...prev, id]);
        } else {
            setComparedProduct(prev => prev.filter(e => e !== id));
        }
    };

    //console.log(state);

    return (
        <section className="c-product-search">
            { productInView ? (
                <Dialog
                    onClose={() => handleSetProductInView()}
                    aria-labelledby="customized-dialog-title"
                    open
                    className="c-modal"
                >
                    <div className="c-modal__header">
                        <IconButton
                            aria-label="close"
                            onClick={() => handleSetProductInView()}
                        >
                            <Close />
                        </IconButton>
                    </div>

                    <Suspense fallback={<div />}>
                        <Product productId={productInView} />
                    </Suspense>
                </Dialog>
            ) : null }
      
            { !state.searchTerm && state.activeFilters.length === 0 ? (
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <h2>{ !isRegimenComparison && search_results_default_title + ':' }</h2> <p style={{fontSize: '0.875em'}}>Last updated: {window.localStorage.getItem('nutricia-pwa-api-latest') ? new Date(window.localStorage.getItem('nutricia-pwa-api-latest')).toISOString().split('T')[0] : 'unknown'}</p>
                </div>
            ) : (
                <>
                    { state.activeFilters && state.activeFilters.length > 0 ? (
                        <ul className="c-product-search__active-filters">
                            {
                                state.activeFilters.map(filter => (
                                    <li
                                        key={filter.id}
                                        className="c-product-search__active-filter"
                                    >
                                        {filter.label}

                                        <button
                                            aria-label={`Remove ${filter.label} filter`}
                                            type="button"
                                            className="c-product-search__remove-filter"
                                            onClick={() => dispatch({
                                                type: 'REMOVE_FILTER',
                                                payload: {
                                                    id: filter.id,
                                                },
                                            })}
                                        >
                                            <Close />
                                        </button>
                                    </li>
                                ))
                            }
                        </ul>
                    ) : null }

                    <h2>Showing {state.results ? state.results.length : ''} product{state.results && state.results.length !== 1 ? 's' : ''}:</h2>
                </>
            )}

            { state.loading ? <Loader /> : (
                state.searchTerm ? (
                    <div ref={ref}>
                        <Suspense fallback={<div />}>
                            <ProductList
                                items={state.results}
                                isRegimenComparison={isRegimenComparison}
                                handleClickOpen={handleClickOpen}
                                currentSelectedProducts={currentSelectedProducts}
                                handleSetProductInView={handleSetProductInView}
                                handleProductCompare={!isRegimenComparison && handleProductCompare}
                                comparedProduct={comparedProduct}
                            />
                        </Suspense>
                    </div>
                ) : (
                    groupItems && groupItems.length > 0 ? (
                        <>
                            <Suspense fallback={<div />}>
                                <ProductList
                                    items={state.results}
                                    isRegimenComparison={isRegimenComparison}
                                    handleClickOpen={handleClickOpen}
                                    currentSelectedProducts={currentSelectedProducts}
                                    handleSetProductInView={handleSetProductInView}
                                    handleProductCompare={!isRegimenComparison && handleProductCompare}
                                    comparedProduct={comparedProduct}
                                />
                            </Suspense>
                        </>
                    ) : <p className="c-product-search__status">{search_results_none}</p>
                )
            )}

            { selectedProduct ? (
                <Suspense fallback={<div />}>
                    <Modal
                        handleClose={handleClose}
                        handleAddProductToComparison={handleAddProductToComparison}
                        selectedProduct={selectedProduct}
                    />
                </Suspense>
            ) : null }

            <PageFooter>
                
                { false &&
                <Button
                    color="primary"
                    endIcon={<FilterList />}
                    onClick={() => handleFilterListExpanded(!filterListExpanded)}
                    size="large"
                    variant="contained"
                    className={`c-product-search__filterbutton${currentSelectedProducts?.length || comparedProduct?.length ? ' c-product-search__filterbutton--offset' : ''}`}
                    type="button">
                    {labels?.pages?.products?.product_search_filter}
                </Button>
                }

                {(comparedProduct?.length && !isRegimenComparison) ? (
                    <div className="c-product-search__compare">
                        <Link to={{
                            pathname: ROUTES.PRODUCTS + '/compare/' + comparedProduct.join('/') + '/',
                            state: comparedProduct
                        }}>
                            <Button
                                onClick={() => { }}
                                size="large"
                                fullWidth
                                className="c-product-search__compare__button"
                                type="button"
                                endIcon={
                                    <span className="e-basket">
                                        <AssignmentInd />
                                        
                                        <span className="e-basket__count">
                                            {comparedProduct.length}
                                        </span>
                                    </span>
                                }
                            >
                                {labels?.pages?.products?.product_compare} ({comparedProduct.length} of 4)
                            </Button>
                        </Link>
                    </div>
                ) : null}
            </PageFooter>
        </section>
    );
}

export default ProductSearch;
