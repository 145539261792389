import React, { memo } from 'react';
import chevron from '../../../images/chatbot/chevron.png';

function ChatbotQueryItem({ item, onClick }) {

	return (
        <button className='chatbot-queries__item' type='button' onClick={(e) => onClick(e, item)}>
            {item} <img src={chevron} alt="Chevron icon" />
        </button>
	);
}

export default memo(ChatbotQueryItem);
