import React, { memo, lazy, Suspense } from 'react';

const FieldHeader = lazy(() => import('../../Form/FieldHeader'));

function Legend({
  items,
  variantMini,
  onProductPage,
  title,
  tooltip,
  variantLarge,
}) {


  return (
    <>
      {
        title &&
          <Suspense fallback={<div />}>
            <FieldHeader
              title={title}
              tooltip={tooltip}
            />
          </Suspense>
      }
      {
        items && items.length > 0 ?
          <ul className={`c-legend${variantMini ? ' c-legend--mini' : ''}${onProductPage ? ' c-legend--product' : ''}`}>
            {
              items.map(item => (
                <li
                  key={item.value}
                  className={`c-legend__item${onProductPage ? '--product' : ' '}`}
                >
                  <div className={`c-legend__icon c-legend__icon--${item.value}${onProductPage ? ' c-legend__icon--large--' : ' '}${item.value}`}></div>
                  <div>{!variantMini && item.label}</div>
                </li>
              ))
            }
          </ul>
        : onProductPage && 
        <div class="c-legend__none">
          None
        </div>
      }
    </>
  );
}

export default memo(Legend);
