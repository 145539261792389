window.OneSignal = window.OneSignal || [];

window.OneSignal.push(function() {
    window.OneSignal.SERVICE_WORKER_PARAM = { scope: '/onesignal/' };
    window.OneSignal.SERVICE_WORKER_PATH = 'onesignal/OneSignalSDKWorker.js'
    window.OneSignal.SERVICE_WORKER_UPDATER_PATH = 'onesignal/OneSignalSDKUpdaterWorker.js'

    window.OneSignal.init({
        appId: process.env.REACT_APP_CTP_ONESIGNAL_KEY,
        // appId: "c90893a0-d466-4406-a94b-4153bbe6f35b",
        //safari_web_id: "web.onesignal.auto.67c39979-7446-4274-a748-fcdf2406257a",
        // safari_web_id: "web.onesignal.auto.41b6a3ea-cfe2-480b-805a-97ab17a018f3",
        safari_web_id: process.env.REACT_APP_CTP_ONESIGNAL_SAFARI_ID,
        allowLocalhostAsSecureOrigin: true,
        autoResubscribe: true, // recommended
        ///notifyButton: {
        ///  enable: true,
        ///},
        //welcomeNotification: {
        //  "title": "Welcome to Nutricia",
        //  "message": "Thanks for subscribing!",
        //},
        //slidedown: {
        //  autoPrompt: true,
        //  enabled: true,
        //}
    });

    // Prompt users with Native Browser Prompt to subscribe to web push notifications
    // showSlidedownPrompt is the only prompt that works on safari
    window.OneSignal.showSlidedownPrompt(); 

    if (window.location.href.includes('localhost') || window.location.href.includes('fishawack.solutions') || window.location.href.includes('dietetic.danonedigital.com')) {
        // window.OneSignal.log.setLevel('trace');
    }
});