import labels from "../../../data/labels.json";
import ROUTES from "../../../consts/routes.json";
import ProteinCalculator from "../../../components/Regimen/Protein";

const { step_3 } = labels?.pages?.regimen;

export default {
    "id": "protein_calculator",
    "url": ROUTES.CREATE_REGIMEN_3,
    "next_step": ROUTES.CREATE_REGIMEN_4,
    "navigation_title": step_3?.navigation_title,
    "step_title": step_3?.step_title,
    "component": ProteinCalculator,
    "dependant": "enter_patient_details",
    "optional": true,
    "fields": [
        {
            "id": "PC_CALCULATION_METHOD",
            "title": "Estimate protein requirements",
            "type": "protein_calculator",
            "tooltip": {
                "type": "text",
                "text": `- For Adults with a BMI>30kg/m², protein requirements are automatically adjusted according to table 3.10 of the downloadable PENG 2018 pdf guidance. 
          - Paediatrics (UK): protein requirements based on UK DRVs (DoH, 1991). 
          - Paediatrics (ROI): protein requirements based on EFSA ARs (EFSA, 2017) from 6 months to 18 years. Under 6 months, where no EFSA ARs are available, UK DRVs are used instead. 
          Full reference list can be found in the Resources section.
        `
            },
        },
        {
            "id": "PD_PENG_PDF",
            "title": " ",
            "type": "link",
            "button": "PENG guidelines",
            "classes": "button",
            "buttonText": "PENG guidelines",
            "isDownload": true,
            "url": "https://www.nutricia.com/content/dam/sn/local/gbr/approved/pdf/peng-nutricia-combined-pocket-guide.pdf",
            "useConditionalVisibility": true,
            "style": "button"
        },
        {
            "id": "PC_UK_PAEDS",
            "title": "UK Paediatrics 0 - 18",
            "type": "ukproteinpaeds",
            "fieldType": "number",
            "useConditionalVisibility": true,
        },
        {
            "id": "PC_ROI_PAEDS",
            "title": "EFSA",
            "type": "roiproteinpaeds",
            "fieldType": "number",
            "useConditionalVisibility": true,
        },
        {
            "id": "PC_MIN_MAX",
            "type": "group",
            "useConditionalVisibility": true,
            "items": [
                {
                    "id": "PC_MINIMUM",
                    "title": "Enter minimum (g/kg/day)",
                    "type": "textfield",
                    "fieldType": "number",
                    "maxValue": 50,
                },
                {
                    "id": "PC_MAXIMUM",
                    "title": "Enter maximum (g/kg/day)",
                    "type": "textfield",
                    "fieldType": "number",
                    "maxValue": 50,
                },
            ]
        },
        {
            "id": "PC_MIN_MAX_GPERDAY",
            "type": "group",
            "title": "Estimated protein requirements",
            "useConditionalVisibility": true,
            "items": [
                {
                    "id": "PC_PROTEIN_REQ_MIN",
                    "title": "Minimum",
                    "type": "textfield",
                    "readonly": "PROTEIN_IRISH_ADULTS_MINIMUM",
                    "readonlyround": true,
                    "unit": "g/day",
                    "isResultField": true,
                },
                {
                    "id": "PC_PROTEIN_REQ_MAX",
                    "title": "Maximum",
                    "type": "textfield",
                    "readonly": "PROTEIN_IRISH_ADULTS_MAXIMUM",
                    "readonlyround": true,
                    "unit": "g/day",
                    "isResultField": true,
                },
            ]
        },
        {
            "id": "PC_UK_ADULTS",
            "title": "Select minimum and maximum of protein (g/kg/day):",
            "type": "protein_range",
            "defaultMinValue": 0.5,
            "defaultMaxValue": 2.5,
            "minValue": "PC_PENG_PROTEIN_MIN",
            "maxValue": "PC_PENG_PROTEIN_MAX",
            "readOnly": "PC_RESULT_PENG_PREADJUST",
            "textField": "Protein range:",
            "unit": "(g/day)",
            "stepIncrement": 0.05,
            "useConditionalVisibility": true,
            "useOnChange": true,
            "tooltip": {
                "type": "text",
                "text": "Refer to table 3.9 of the downloadable PENG 2018 pdf for guidance."
            },
            "items": [
                {
                    "value": 0.5,
                    "label": "0.5"
                },
                {
                    "value": 2.5,
                    "label": "2.5"
                }
            ],
        },
        {
            "id": "PC_PENG_TOGGLE",
            "title": "Select to use minimum or maximum in calculations:",
            "type": "radio",
            "useConditionalVisibility": true,
            "items": [
                {
                    "id": "PC_PENG_TOGGLE_MIN",
                    "title": "Minimum",
                    "value": "min",
                },
                {
                    "id": "PC_PENG_TOGGLE_MAX",
                    "title": "Maximum",
                    "value": "max",
                }
            ]
        },
        {
            "id": "PC_PROTEIN_RESULT",
            "title": "Estimated protein requirements",
            "type": "protein_result",
            "useConditionalVisibility": true,
            "tooltip": {
                "type": "text",
                "text": "ADULTS only: For BMI> 30kg/m², protein requirements are adjusted to 75% of estimated requirements, and for BMI>50kg/m², automatically adjusted to 65% of estimated requirements (see table 3.10 of the PENG 2018 pdf for reference)."
            }
        },
        {
            "id": "PC_NITROGEN",
            "title": "Estimation of Nitrogen requirements",
            "type": "dropdown",
            "useConditionalVisibility": true,
            "values": [
                {
                    "label": "Normal",
                    "value": "normal",
                },
                {
                    "label": "Hypermetabolic 5-25%",
                    "value": "hypermetabolic_5-25",
                },
                {
                    "label": "Hypermetabolic 25-50%",
                    "value": "hypermetabolic_25-50",
                },
                {
                    "label": "Hypermetabolic > 50%",
                    "value": "hypermetabolic_50plus",
                },
                {
                    "label": "Depleted",
                    "value": "depleted",
                },
            ]
        },
        {
            "id": "PC_NITROGEN_RESULT",
            "useConditionalVisibility": true,
            "type": "nitrogen_result",
            "title": "Estimated protein requirements",
        },
        {
            "id": "PC_FLUID_TITLE",
            "title": "Fluid ml/day",
            "type": "title",
            "tooltip": {
                "type": "text",
                "text": "Adult fluid requirements based on PENG, 2018. Paediatric fluid requirements based on Gandy, 2019. Full reference list available in the Resources section.",
            },
        },
        {
            "id": "PC_FLUID",
            "title": "Estimated fluid requirements",
            "type": "textfield",
            "readonly": "FLUID_RESULT",
            "unit": "ml/day",
            "tooltip": {
                "type": "text",
                "text": "Calculation is made based on age and weight."
            },
        },
        {
            "id": "PC_FLUID_ADJUST",
            "title": "Adjust fluid requirements ml/kg/day (if required):",
            "type": "protein_slider",
            "defaultValue": "FLUID_PER_KG",
            "textField": "Estimated fluid requirements - Adjusted:",
            "unit": "(ml/day)",
            "stepIncrement": 1,
            "items": [
                {
                    "value": 20,
                    "label": "20",
                    "sliderLabel": "20",
                },
                {
                    "value": 150,
                    "label": "150",
                    "sliderLabel": "150",
                }
            ],
            "tooltip": {
                "type": "text",
                "text": "For adults, further guidance on adjusting fluid requirements can be found in the Fluid section (table 3.11) of the downloadable PENG 2018 pdf guidance. Paediatric fluid requirements are based on Gandy (2019). See full reference list in Resources section."
            },
            "useOnChange": true
        },
        {
            "id": "PC_FLUID_ADJUST_RESULT",
            "title": "Estimated fluid requirements - Adjusted",
            "type": "textfield",
            "isResultField": true,
            "readonly": "FLUID_RESULT_ADJUST",
            "unit": "ml/day",
            "tooltip": {
                "type": "text",
                "text": "Notes: Add 2-2.5ml/kg for each °C rise in body temperature above 37°C."
            },
        }
    ],
}
