/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { handleStepFields } from '../Form';

const Panel = lazy(() => import('./Panel'));

function TabsComponent({ items, activeTabCallback, setDefaultTab }) {
    const [activeTab, updateActiveTab] = useState(setDefaultTab || 0);
    const handleChange = (_, newValue) => updateActiveTab(newValue);

    useEffect(() => {
        if (activeTabCallback) {
            activeTabCallback(activeTab);
        }
    }, [activeTab]);

    return (
        <div className="c-tabs">
            <Tabs
                value={activeTab}
                onChange={handleChange}
            >
                { items && items.length > 0 ? (
                    items.map((item, index) => {
                        const isDownload = item.type === 'download';

                        return (
                            <Tab
                                key={index}
                                label={item.title}
                                component={isDownload ? 'a' : 'button'}
                                {...(isDownload ? {
                                    href: item.url,
                                } : {})}
                            />
                        );
                    })
                ) : null }
            </Tabs>

            { items && items.length > 0 ? (
                items.map((tab, index) => (
                    <Suspense
                        key={index}
                        fallback={<div />}
                    >
                        <Panel
                            value={activeTab}
                            index={index}
                        >
                            { tab.isField ? (
                                tab.type === 'multi_dropdown' ? (
                                    tab.items && tab.items.length > 0 ? tab.items.map(handleStepFields) : null
                                ) : handleStepFields
                            ) : tab.component }
                        </Panel>
                    </Suspense>
                ))
            ) : null }
        </div>
    );
}

export default TabsComponent;