import React from 'react';
import { Link } from 'react-router-dom';
import avatar from '../../../images/chatbot/sim-waving.gif';
import chevron from '../../../images/chatbot/chevron.png';
import chatbot from '../../../data/pages/chatbot';
import ChatbotEop from '../../../components/Chatbot/ChatbotEop';
import ChatbotSearch from '../../../components/Chatbot/ChatbotSearch';

function ChatbotLanding({ labels }) {
    const handleSubmit = (e) => {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);

        if (!window.BE_API.isInitialized()) {
            window.BE_API.create();
        }

        window.BE_API.sendMessage({ message: formData.get('chatbotSearchTerm') });
    }

	return (
		<>
            <section className='chatbot'>
                <img className='chatbot__avatar' src={avatar} alt="Sim avatar" />

                <div className='chatbot__items'>
                    <p className='chatbot__items-summary'>{labels.description}</p>

                    <ChatbotSearch placeholder={labels.placeholder} onSubmit={handleSubmit} />

                    {chatbot.map(item => (
                        <Link id={item.id} className="chatbot__items-anchor" to={item.url} key={item.id}>{item.title} <img src={chevron} alt="Chevron icon" /></Link>
                    ))}
                </div>
            </section>

            <ChatbotEop />
		</>
	);
}

export default ChatbotLanding;
