import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const UsePageTracking = ({
  children,
}) => {
  const location = useLocation();
  const [ initialized, setInitialized ] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes('localhost')) {
      ReactGA.initialize('G-21QSF1VSD9');
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }
  }, [
    initialized,
    location,
  ]);

  return children;
};

export default UsePageTracking;
