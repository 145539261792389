import ranges from '../../age-ranges';

const male = [
  {
    range: [ ranges.from.zero, ranges.to.fourMonths ],
    value: 12.5,
  },
  {
    range: [ ranges.from.fourMonths, ranges.to.sixMonths ],
    value: 12.7,
  },
  {
    range: [ ranges.from.sixMonths, ranges.to.tenMonths ],
    value: 13.7,
  },
  {
    range: [ ranges.from.tenMonths, ranges.to.oneYear ],
    value: 14.9,
  },
  {
    range: [ ranges.from.oneYear, ranges.to.fourYears ],
    value: 14.5,
  },
  {
    range: [ ranges.from.fourYears, ranges.to.sevenYears ],
    value: 19.7,
  },
  {
    range: [ ranges.from.sevenYears, ranges.to.elevenYears ],
    value: 28.3,
  },
  {
    range: [ ranges.from.elevenYears, ranges.to.fifteenYears ],
    value: 42.1,
  },
  {
    range: [ ranges.from.fifteenYears, ranges.to.eighteenYears ],
    value: 55.2,
  },
];

const female = [
  {
    range: [ ranges.from.zero, ranges.to.fourMonths ],
    value: 12.5,
  },
  {
    range: [ ranges.from.fourMonths, ranges.to.sixMonths ],
    value: 12.7,
  },
  {
    range: [ ranges.from.sixMonths, ranges.to.tenMonths ],
    value: 13.7,
  },
  {
    range: [ ranges.from.tenMonths, ranges.to.oneYear ],
    value: 14.9,
  },
  {
    range: [ ranges.from.oneYear, ranges.to.fourYears ],
    value: 14.5,
  },
  {
    range: [ ranges.from.fourYears, ranges.to.sevenYears ],
    value: 19.7,
  },
  {
    range: [ ranges.from.sevenYears, ranges.to.elevenYears ],
    value: 28.3,
  },
  {
    range: [ ranges.from.elevenYears, ranges.to.fifteenYears ],
    value: 42.1,
  },
  {
    range: [ ranges.from.fifteenYears, ranges.to.eighteenYears ],
    value: 45.4,
  },
];

const getResult = ({
  age,
  gender,
}) => {
  let result;
  const data = gender === 'male' ? male : female;
  data.forEach(e => {
    if (age >= e.range[0] && age <= e.range[1]) {
      result = e.value;
    }
  });
  return result;
};

export default getResult;
