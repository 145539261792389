import React from 'react';
import { Button } from '@material-ui/core';
import { GetApp, Launch } from '@material-ui/icons';
import { isAndroid } from 'react-device-detect';
import FieldHeader from '../FieldHeader';

const Icons = {
    download: <GetApp />,
    launch: <Launch />,
};

function FormLink({ field }) {
    const { buttonText, icon, title, url, isDownload, classes = '' } = field;
    const fullUrl = isAndroid ? `https://docs.google.com/viewerng/viewer?url=${window?.location?.origin}${url}` : url;

    return (
        
        <div className={(field.style && field.style === 'button') ? '' : 'c-form-link'}>
            <FieldHeader
                title={title}
            />

            {
                url && (
                    <Button
                        href={fullUrl}
                        component="a"
                        classes={{
                            root: `e-button ${(field.style && field.style === 'button') ? '' : 'e-button--secondary'}${classes ? ` ${classes}` : ''}`,
                        }}
                        endIcon={icon ? Icons[icon] : null}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={isDownload}
                    >
                        <span>{buttonText}</span>
                    </Button>
                )
            }
        </div>
    );
}

export default FormLink;
