// Age range map
// the idea behind naming ranges is for consistency across all calcs
const ranges = {
  from: {
    'zero': 0,
    'twoMonths': 2,
    'threeMonths': 3,
    'fourMonths': 4,
    'fiveMonths': 5,
    'sixMonths': 6,
    'sevenMonths': 7,    
    'eightMonths': 8,    
    'nineMonths': 9,
    'tenMonths': 10,
    'elevenMonths': 11,
    'oneYear': 12,
    'thirteenMonths': 13,
    'eighteenMonths': 18,
    'twoYears': 24,
    'threeYears': 36,
    'fourYears': 48,
    'fiveYears': 60,
    'sixYears': 72,
    'sevenYears': 84,
    'eightYears': 96,
    'nineYears': 108,
    'tenYears': 120,
    'elevenYears': 132,
    'twelveYears': 144,
    'thirteenYears': 156,
    'fourteenYears': 168,
    'fifteenYears': 180,
    'sixteenYears': 192,
    'seventeenYears': 204,
    'eighteenYears': 216,
    'nineteenYears': 228,
    'twentyFiveYears': 300,
    'thirtyYears': 360,
    'thirtyFiveYears': 420,
    'fortyFiveYears': 540,
    'fiftyYears': 600,
    'fiftyFiveYears': 660,
    'sixtyYears': 720,
    'sixtyFiveYears': 780,
    'seventyFiveYears': 900,
  },
  to: {
    'twoMonths': 1,
    'threeMonths': 2,
    'fourMonths': 3,
    'fiveMonths': 4,
    'sixMonths': 5,
    'sevenMonths': 6,
    'nineMonths': 8,
    'tenMonths': 9,
    'elevenMonths': 10,
    'oneYear': 11,
    'thirteenMonths': 12,
    'eighteenMonths': 17,
    'twoYears': 23,
    'threeYears': 35,
    'fourYears': 47,
    'fiveYears': 59,
    'sixYears': 71,
    'sevenYears': 83,
    'eightYears': 95,
    'nineYears': 107,
    'tenYears': 119,
    'elevenYears': 131,
    'twelveYears': 143,
    'thirteenYears': 155,
    'fourteenYears': 167,
    'fifteenYears': 179,
    'sixteenYears': 191,
    'seventeenYears': 203,
    'eighteenYears': 215,
    'nineteenYears': 227,
    'twentyFiveYears': 299,
    'thirtyYears': 359,
    'thirtyFiveYears': 419,
    'fortyFiveYears': 539,
    'fiftyYears': 599,
    'fiftyFiveYears': 659,
    'sixtyFiveYears': 779,
    'sixtyYears': 719,
    'seventyFiveYears': 899,
    'max': 1440,
  },
};

export default ranges;
