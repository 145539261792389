import ROUTES from '../../../consts/routes.json';
import Intermittent from '../../../components/Regimen/Intermittent';

export default {
    "id": "intermittent",
    "url": ROUTES.CREATE_REGIMEN_INTERMITTENT,
    "component": Intermittent,
    "exact": false,
    "dependant": null,
    "fields": [],
};
