import React, { /* useEffect, */ useContext } from 'react';
import AgeTab from './AgeTab';
import DobTab from './DobTab';
import Tabs from '../../Tabs';
import FieldHeader from '../FieldHeader';
import labels from '../../../data/labels.json';
import data from '../../../consts/age.json';

import Context from '../../../components/Regimen/Context';

function useRequired(){
  const [ state, /* dispatch */ ] = useContext(Context);
  const required = state.navigationItems.find(item => item.id === 'enter_patient_details').requiredFields;

  if(!required.includes('PD_HEIGHT')) {
    state.navigationItems.find(item => item.id === 'enter_patient_details').requiredFields.push('PD_HEIGHT');
  }

  if(state.PD_AGE < data.minimumAgeForHeight){
    state.navigationItems.find(item => item.id === 'enter_patient_details').requiredFields = required.filter(item => !item.includes('PD_HEIGHT'));
  }
}

function AgePicker({
  field,
}) {
  const { age_tab, age_title, dob_tab } = labels?.pages?.regimen;

  const tabItems = [
    {
      "id": "age",
      "title": age_tab,
      "component": <AgeTab />,
    },
    {
      "id": "dob",
      "title": dob_tab,
      "component": <DobTab />,
    },
  ];  

  useRequired();

  return (
    <section className="c-age-picker">
      <FieldHeader
        title={age_title}
        tooltip={field.tooltip}
      />
      <Tabs items={tabItems} />
    </section>
  );
}

export default AgePicker;
