import React, { lazy, Suspense } from 'react';
import RadioList from './RadioList';
import Tabs from '../Tabs';
import MultiDropdown from './MultiDropdown';
import Dropdown from './Dropdown';
import Datepicker from './Datepicker';
import TextField from './TextField';
import Link from './Link';
import Text from './Text';
import Title from './Title';
import AgePicker from './AgePicker';
import WeightPicker from './WeightPicker';
import HeightPicker from './HeightPicker';
import Accordion, { AccordionContent } from './Accordion';
import FieldGroup from './FieldGroup';
import VisibilityWrapper from './VisibilityWrapper';
import AgeTextField from './AgeTextField';
import WeightTextField from './WeightTextField';
import HeightTextField from './HeightTextField';

// Lazy loading of non-critical components
const NitrogenResult = lazy(() => import('./NitrogenResult'));
const RegimenReport = lazy(() => import('./RegimenReport'));
const StressFactorAdjuster = lazy(() => import('./StressFactorAdjuster'));
const StressFactor = lazy(() => import('./StressFactor'));
const ROIProteinPaeds = lazy(() => import('./ROIProteinPaeds'));
const UKProteinPaeds = lazy(() => import('./UKProteinPaeds'));
const ProteinResult = lazy(() => import('./ProteinResult'));
const Tabslider = lazy(() => import('./Tabslider'));
const EnergyResult = lazy(() => import('./EnergyResult'));
const ProteinCalculator = lazy(() => import('./ProteinCalculator'));
const CalculatorSelector = lazy(() => import('./CalculatorSelector'));
const SummaryBlock = lazy(() => import('./SummaryBlock'));
const PatientDetailsResult = lazy(() => import('./PatientDetailsResult'));
const Slider = lazy(() => import('./Slider'));
const ProteinSlider = lazy(() => import('./ProteinSlider'));
const RangeSlider = lazy(() => import('./RangeSlider'));
const CaloricRange = lazy(() => import('./CaloricRange'));
const ProteinRange = lazy(() => import('./ProteinRange'));
const PALDITSlider = lazy(() => import('./PALDITSlider'));

export function handleStepFields(field) {
    const props = {
        field,
        key: field.id,
    };

    

    return (
        <VisibilityWrapper {...props}>
            {
                (() => {
                    switch (field.type) {
                        case 'agetextfield':
                            return <AgeTextField {...props} />;
                        case 'caloric_range':
                            return <Suspense fallback={<div />}><CaloricRange {...props} /></Suspense>;
                        case 'protein_range':
                            return <Suspense fallback={<div />}><ProteinRange {...props} /></Suspense>;
                        case 'dropdown':
                            return <Dropdown {...props} />;
                        case 'datepicker':
                            return <Datepicker {...props} />;
                        case 'multi_dropdown':
                            return <MultiDropdown {...props} />;
                        case 'text':
                            return <Text {...props} />;
                        case 'title':
                            return <Title {...props} />;
                        case 'link':
                            return <Link {...props} />;
                        case 'textfield':
                            return <TextField {...props} />;
                        case 'weighttextfield':
                            return <WeightTextField {...props} />;
                        case 'heighttextfield':
                            return <HeightTextField {...props} />;
                        case 'pal_dit_slider':
                            return <Suspense fallback={<div />}><PALDITSlider {...props} /></Suspense>;
                        case 'protein_slider':
                            return <Suspense fallback={<div />}><ProteinSlider {...props} /></Suspense>;
                        case 'slider':
                            return <Suspense fallback={<div />}><Slider {...props} /></Suspense>;
                        case 'range_slider':
                            return <Suspense fallback={<div />}><RangeSlider {...props} /></Suspense>;
                        case 'ukenergypaeds_0to18':
                            return <Suspense fallback={<div />}><Tabslider {...props} /></Suspense>;
                        case 'ukproteinpaeds':
                            return <Suspense fallback={<div />}><UKProteinPaeds {...props} /></Suspense>;
                        case 'radio':
                            return <RadioList {...props} />;
                        case 'tabs':
                            return <Tabs items={field.items} />;
                        case 'roiproteinpaeds':
                            return <Suspense fallback={<div />}><ROIProteinPaeds {...props} /></Suspense>;
                        case 'age_picker':
                            return <AgePicker {...props} />;
                        case 'weight_picker':
                            return <WeightPicker {...props} />;
                        case 'height_picker':
                            return <HeightPicker {...props} />;
                        case 'patient_result':
                            return <Suspense fallback={<div />}><PatientDetailsResult {...props} /></Suspense>;
                        case 'summary_block':
                            return <Suspense fallback={<div />}><SummaryBlock {...props} /></Suspense>;
                        case 'accordion':
                            const {
                                cta,
                                items,
                                text,
                                title,
                                open,
                            } = field;
                            return (
                                <Accordion title={title} open={open}>
                                    <AccordionContent
                                        cta={cta}
                                        items={items}
                                        text={text}
                                    />
                                </Accordion>
                            );
                        case 'calculator_selector':
                            return <Suspense fallback={<div />}><CalculatorSelector {...props} /></Suspense>;
                        case 'protein_calculator':
                            return <Suspense fallback={<div />}><ProteinCalculator {...props} /></Suspense>;
                        case 'energy_result':
                            return <Suspense fallback={<div />}><EnergyResult {...props} /></Suspense>;
                        case 'protein_result':
                            return <Suspense fallback={<div />}><ProteinResult {...props} /></Suspense>;
                        case 'nitrogen_result':
                            return <Suspense fallback={<div />}><NitrogenResult {...props} /></Suspense>;
                        case 'stress_factor':
                            return <Suspense fallback={<div />}><StressFactor {...props} /></Suspense>;
                        case 'stress_factor_adjuster':
                            return <Suspense fallback={<div />}><StressFactorAdjuster {...props} /></Suspense>;
                        case 'regimen_report':
                            return <Suspense fallback={<div />}><RegimenReport {...props} /></Suspense>;
                        case 'group':
                            return (
                                <FieldGroup title={field?.title}>
                                    {field?.items.map(handleStepFields)}
                                </FieldGroup>
                            );
                        default: return;
                    }
                })()
            }
        </VisibilityWrapper>
    )
}
