import ranges from '../age-ranges';

const male = [
  {
    range: [ ranges.from.zero, ranges.to.threeYears ],
    a: 59.5,
    b: 30.4,
    SEE: 70,
  },
  {
    range: [ ranges.from.threeYears, ranges.to.tenYears],
    a: 22.7,
    b: 504.3,
    SEE: 67,
  },
  {
    range: [ ranges.from.tenYears, ranges.to.eighteenYears],
    a: 17.7,
    b: 657,
    SEE: 105,
  },
  {
    range: [ ranges.from.eighteenYears, ranges.to.thirtyYears],
    a: 15.1,
    b: 692,
    SEE: 153,
  },
  {
    range: [ ranges.from.thirtyYears, ranges.to.sixtyYears],
    a: 11.5,
    b: 873,
    SEE: 167,
  },
  {
    range: [ ranges.from.sixtyYears, ranges.to.max ],
    a: 11.9,
    b: 700,
    SEE: 164,
  },
];

const female = [
  {
    range: [ ranges.from.zero, ranges.to.threeYears ],
    a: 58.3,
    b: 31.05,
    SEE: 59,
  },
  {
    range: [ ranges.from.threeYears, ranges.to.tenYears],
    a: 20.3,
    b: 486,
    SEE: 70,
  },
  {
    range: [ ranges.from.tenYears, ranges.to.eighteenYears],
    a: 13.4,
    b: 692,
    SEE: 111,
  },
  {
    range: [ ranges.from.eighteenYears, ranges.to.thirtyYears],
    a: 14.8,
    b: 487,
    SEE: 119,
  },
  {
    range: [ ranges.from.thirtyYears, ranges.to.sixtyYears],
    a: 8.3,
    b: 846,
    SEE: 111,
  },
  {
    range: [ ranges.from.sixtyYears, ranges.to.max ],
    a: 9.2,
    b: 687,
    SEE: 108,
  },
];

const getResult = ({
  age,
  gender,
  weight,
}) => {
  let result;
  const data = gender === 'male' ? male : female;
  data.forEach((e, i) => {
    if (age >= e.range[0] && age <= e.range[1]) {
      if (i === 0) {
        result = e.a * weight - e.b;
      } else {
        result = e.a * weight + e.b;
      }
    }
  });
  return result;
};

export default getResult;
