import React, { memo } from 'react';

function ChatbotQuery({ title, children }) {

	return (
        <section className='chatbot-queries'>
            <h3 className='chatbot-queries__title'>{title}</h3>

            {children}
        </section>
	);
}

export default memo(ChatbotQuery);