import React, { memo } from 'react';
import { AlternateEmailOutlined, RoomOutlined, PhoneInTalkOutlined } from '@material-ui/icons';

function ContactBlock({
    title,
    subtitle,
    fields,
    opening_times,
    labels,
}) {
    return (
        <section className="c-contact-block">
            {title ? (
                <h2 className="c-contact-block__title">{title}</h2>
            ) : null}

            <div className="c-contact-block__content-wrapper">
                <div className="c-contact-block__content">
                    {subtitle ? (
                        <h3 className="c-contact-block__subtitle">{subtitle}</h3>
                    ): null}

                    { fields && fields.length > 0 ? (
                        <div className="c-contact-block__details">
                            {
                                fields.map((field, index) => {
                                    switch (field.type) {
                                        case 'address':
                                            return (
                                                <address
                                                    key={index}
                                                    className="c-contact-block__item c-contact-block__item--address"
                                                >
                                                    <RoomOutlined />

                                                    {field?.value?.split('\n').map((item, i) => <p key={i}>{item}</p>)}
                                                </address>
                                            );
                                        case 'phone':
                                            return (
                                                <p
                                                    key={index}
                                                    className="c-contact-block__item c-contact-block__item--phone"
                                                >
                                                    <a href={`tel:${field.value || ''}`}>
                                                        <PhoneInTalkOutlined />

                                                        {field.preLabel || ''}

                                                        {field.value || ''}
                                                    </a>
                                                </p>
                                            );
                                        case 'fax':
                                            return (
                                                <p
                                                    key={index}
                                                    className="c-contact-block__item c-contact-block__item--fax"
                                                >
                                                    <svg className="MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24"><path d="M11 6h5v2h-5V6M8 9V3h11v6a3 3 0 0 1 3 3v6h-3v3H8v-3H7V9h1m2-4v4h7V5h-7m0 10v4h7v-4h-7m9-4a1 1 0 0 0-1 1a1 1 0 0 0 1 1a1 1 0 0 0 1-1a1 1 0 0 0-1-1M4 9h1a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2z" /></svg>

                                                    {field.preLabel || ''}

                                                    {field.value || ''}
                                                </p>
                                            );
                                        case 'email':
                                            return (
                                                <p
                                                    key={index}
                                                    className="c-contact-block__item c-contact-block__item--email"
                                                >
                                                    <a
                                                        href={`mailto:${field.value || ''}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <AlternateEmailOutlined />

                                                        {field.value || ''}
                                                    </a>
                                                </p>
                                            );
                                        default:
                                            return null;
                                    }
                                })
                            }
                        </div>
                    ) : null }
                </div>

                { opening_times ? (
                    <div className="c-contact-block__opening-times">
                        <p className="c-contact-block__opening-title"><strong>{labels?.opening_times}</strong></p>

                        <p><strong>{labels?.monday_friday} </strong>{opening_times?.monday_friday}</p>

                        <p><strong>{labels?.monday_friday} </strong>{opening_times?.saturday}</p>
                        
                        <p><strong>{labels?.monday_friday} </strong>{opening_times?.sunday}</p>
                    </div>
                ) : null}
            </div>
        </section>
    );
}

export default memo(ContactBlock);
