import React, { useContext } from 'react';

import Context from '../Context';

export default function CountryIcon() {
    const { country } = useContext(Context);

    return (
        <div className={`c-language-selector c-language-selector--static c-language-selector--${country}`}></div>
    );
};