import React from 'react';

import labels from '../../../data/labels.json';
import Step from '../../../data/forms/create-regimen/ProductComparison';
import { handleStepFields } from '../../Form';
import StepForm from '../StepForm';
import PageHeader from '../../PageHeader';
import { ContextProvider } from '../../ProductSearch/Context';
import FilterList from '../../FilterList';
import ProductSearchResults from '../../ProductSearch';
import ProductSearchForm from '../../ProductSearch/Form';
import Summary from '../Summary';
import SelectedProducts from '../SelectedProducts';

function ProductComparison() {
    const { page_title, text } = labels?.pages?.regimen?.step_4;

    return (
        <section className="regimen-products">
            <PageHeader
                page_title={page_title}
                text={text}
            />

            <Summary detailsLinks={true} />

        
            <SelectedProducts/>
            
            {
                Step.fields && Step.fields.length > 0 ? (
                    <StepForm
                        stepId={Step.id}
                        nextStep={Step.next_step}
                        ctas={[ 'open_report' ]}
                    >
                        { Step.fields.map(handleStepFields) }

                        <ContextProvider>
                            <ProductSearchForm 
					            
				            />
                            <ProductSearchResults
                                isRegimenComparison
                            />

                            <FilterList />
                        </ContextProvider>
                    </StepForm>
                ) : null
            }
        </section>
    );
}

export default ProductComparison;
