import calculateFluidPerKG from '../calculation-sets/fluid-requirements';
import calculateEnergyUK0to18Years from '../calculation-sets/energy/uk/paeds/0-18years';
import calculateEnergyROI12MonthsTo17Years from '../calculation-sets/energy/roi/paeds/6months-17years';
import calculateSchofield from '../calculation-sets/energy/schofield';
import calculateSchofieldPAL from '../calculation-sets/energy/schofield-pal';
import calculateHenryOxford from '../calculation-sets/energy/henry-oxford';
import calculateSACN from '../calculation-sets/energy/sacn';
import ranges from '../calculation-sets/age-ranges';

export const BMI = ({
  height,
  weight,
}) => {
  if (!height || !weight) {
    return 0;
  }

  return weight / (height*height);
}

export const BMRCalculator = ({
  weight,
  height,
  age,
  gender
}) => {
  let result = 10 * weight + 6.25 * height - 5 * age;
  if (gender === 'female') {
    result = result - 161;
  } else {
    result = result + 5;
  }

  return result;
};

export const pengCalculator = ({
  weight,
  caloricEquation,
}) => {  
  const result = Number(weight) * Number(caloricEquation);
  return result;
};

export const harrisBenedictCalculator = ({
  weight,
  height,
  age,
  gender,
}) => {

  const BMR = gender === 'male' ?
              66.47 + (13.75 * weight + (5.003 * height - (6.755 * (age / 12)))) :
              655.1 + (9.563 * weight + (1.85 * height - (4.676*(age / 12))));
  return BMR;
};

export const schofieldCalculator = calculateSchofield;

export const schofieldPAL = calculateSchofieldPAL;

export const henryOxfordCalculator = calculateHenryOxford;

export const SACNCalculator = calculateSACN;

export const pennCalculator = ({
  age,
  weight,
  height,
  bmi,
  gender,
  tmax,
  ve,
}) => {
  const BMR = BMRCalculator({
    weight,
    height,
    age,
    gender,
  });

  let result = 0;

  if (age >= ranges.from.sixtyYears && bmi > 30) {
    // if over 60 or bmi of 30
    result = (BMR * 0.71) + (tmax * 85) + (ve * 64) - 3085;
  } else {
    result = (BMR * 0.96) + (tmax * 167) + (ve * 31) - 6212;
  }

  return result;
};

export const fluidCalculator = ({
  months,
}) => {
  return calculateFluidPerKG(months);
};

export const proteinMinCalculator = ({
  minWeight,
  currentWeight,
  BMIValue,
}) => {
  const requirements = Number(minWeight) * currentWeight;
  const result = (BMIValue >= 30 && BMIValue < 50) ? requirements * 0.75 : BMIValue > 50 ? requirements * 0.65 : requirements;
  return result;
};

export const proteinMaxCalculator = ({
  maxWeight,
  currentWeight,
  BMIValue,
}) => {
  const requirements = Number(maxWeight) * currentWeight;
  const result = (BMIValue >= 30 && BMIValue < 50) ? requirements * 0.75 : BMIValue > 50 ? requirements * 0.65 : requirements;
  return result;
};

export const nitrogenLowCalculator = ({
  nitrogenLevel,
  weightInKG,
  BMIValue,
}) => {
  const calculateNitrogenLevel = () => {
    switch (nitrogenLevel) {
      case 'normal':
        return ((0.14 * weightInKG) * 6.25);
      case 'hypermetabolic_5-25':
        return ((0.17 * weightInKG) * 6.25);
      case 'hypermetabolic_25-50':
        return ((0.2 * weightInKG) * 6.25);
      case 'hypermetabolic_50plus':
        return ((0.25 * weightInKG) * 6.25);
      case 'depleted':
        return ((0.2 * weightInKG) * 6.25);
      default:
        return 0;
    }
  };

  const calculatedNitrogenLevel = calculateNitrogenLevel();
  const result = (BMIValue >= 30 && BMIValue < 50) ? calculatedNitrogenLevel * 0.75 : BMIValue > 50 ? calculatedNitrogenLevel * 0.65 : calculatedNitrogenLevel;
  return result;
};

export const nitrogenAverageCalculator = ({
  nitrogenLevel,
  weightInKG,
  BMIValue,
}) => {
  const calculateNitrogenLevel = () => {
    switch (nitrogenLevel) {
      case 'normal':
        return ((0.17 * weightInKG) * 6.25);
      case 'hypermetabolic_5-25':
        return ((0.2 * weightInKG) * 6.25);
      case 'hypermetabolic_25-50':
        return ((0.25 * weightInKG) * 6.25);
      case 'hypermetabolic_50plus':
        return ((0.3 * weightInKG) * 6.25);
      case 'depleted':
        return ((0.3 * weightInKG) * 6.25);
      default:
        return 0;
    }
  };

  const calculatedNitrogenLevel = calculateNitrogenLevel();
  const result = (BMIValue >= 30 && BMIValue < 50) ? calculatedNitrogenLevel * 0.75 : BMIValue > 50 ? calculatedNitrogenLevel * 0.65 : calculatedNitrogenLevel;
  return result;
};

export const nitrogenHighCalculator = ({
  nitrogenLevel,
  weightInKG,
  BMIValue,
}) => {
  const calculateNitrogenLevel = () => {
    switch (nitrogenLevel) {
      case 'normal':
        return ((0.2 * weightInKG) * 6.25);
      case 'hypermetabolic_5-25':
        return ((0.25 * weightInKG) * 6.25);
      case 'hypermetabolic_25-50':
        return ((0.3 * weightInKG) * 6.25);
      case 'hypermetabolic_50plus':
        return ((0.35 * weightInKG) * 6.25);
      case 'depleted':
        return ((0.4 * weightInKG) * 6.25);
      default:
        return 0;
    }
  };

  const calculatedNitrogenLevel = calculateNitrogenLevel();
  const result = (BMIValue >= 30 && BMIValue < 50) ? calculatedNitrogenLevel * 0.75 : BMIValue > 50 ? calculatedNitrogenLevel * 0.65 : calculatedNitrogenLevel;
  return result;
};

export const energyUK0to18Years = calculateEnergyUK0to18Years;

export const energyROI6MonthsTo17Years = calculateEnergyROI12MonthsTo17Years;
