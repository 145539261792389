import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { Link } from 'react-router-dom';

import Tooltip from '../../Tooltip';

function Accordion({
    title,
    description,
    open,
    children,
}) {
    const [expanded, updateExpanded] = useState(open);

    return (
        <div className={`c-accordion ${expanded ? 'c-accordion--expanded' : ''}`}>
            <div
                onClick={() => updateExpanded(!expanded)}
                className="c-accordion__header"
            >
                { title ? (
                    <h3 className="c-accordion__title">{title}</h3>
                ) : null}

                <span className="c-accordion__icon">
                    { expanded ? <Remove /> : <Add /> }
                </span>
            </div>

            <div className="c-accordion__content">
                { description ? (
                    <p className="c-accordion__description">{description}</p>
                ) : null}

                {children}
            </div>
        </div>
    );
}

function Tip(props) {
    const item = props.item;
    const country = localStorage.getItem('userCountry');

    return (!item.tooltip.country || (item.tooltip.country && item.tooltip.country === country))
        ? <Tooltip content={item.tooltip || {}} />
        : '';
};

export default Accordion;

export function AccordionContent({
    cta,
    items,
    text,
}) {
    return (
        <>
            {
                items && items.length > 0 ? (
                    <ul className="c-accordion__list">
                        { items.map(item => (
                            <li
                                className="c-accordion__list-item"
                                key={item.title}
                            >
                                <h3 className="c-accordion__list-title">
                                    {item.title} {item.tooltip && <Tip item={item || {}} />}
                                </h3>

                                { item.items && item.items.length > 0 ? (
                                        <ul className="c-accordion__field-list">
                                            {
                                                item.items.map(subItem => (
                                                    <li
                                                        key={subItem.title}
                                                        className="c-accordion__field-list-item"
                                                    >
                                                        <p className="c-accordion__field-list-label">
                                                            {subItem.title}
                                                        </p>

                                                        <input
                                                            type="text"
                                                            value={subItem.value}
                                                            className="c-accordion__field-list-value"
                                                            disabled
                                                        />
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                ) : (
                                    <div className="c-accordion__field-value">
                                        <TextField
                                            variant="outlined"
                                            value={item.value ? Number(item.value).toFixed(1) : '0'}
                                            type="text"
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                ) : null
            }
            <div className="c-accordion__footer">
                {text ? (
                    <p>{text}</p>
                ) : null}

                {
                    cta ? (
                        <Button
                            to={cta.url}
                            component={Link}
                            classes={{
                                root: 'e-button',
                            }}
                        >{cta.text}</Button>
                    ) : null
                }
            </div>
        </>
    );
}

