const ContactUs = [
  {
    "id": "uk_enquiries",
    "type": "contact_block",
    "subtitle": "UK Enquiries",
    "fields": [
      {
        "type": "phone",
        "value": "01225 751098",
      },
      {
        "type": "email",
        "value": "resourcecentre@nutricia.com",
      }
    ],
  },
  {
    "id": "ireland_enquiries",
    "type": "contact_block",
    "subtitle": "ROI and NI Enquiries",
    "fields": [
      {
        "type": "phone",
        "value": "1800 923 404",
        "preLabel": "ROI: ",
      },
      {
        "type": "phone",
        "value": "0800 783 4379",
        "preLabel": "NI: ",
      },
      {
        "type": "email",
        "value": "support.ireland@nutricia.com",
      }
    ],
  },
  {
    "id": "address",
    "type": "contact_block",
    "subtitle": "Registered addresses:",
    "fields": [
      {
        "type": "address",
        "value": `Nutricia Ltd
                  White Horse Business Park
                  Newmarket Avenue
                  Trowbridge, Wiltshire, UK
                  BA14 0XQ`,
      },
      {
        "type": "phone",
        "value": "01225 711688",
        "preLabel": "Tel: ",
      },
      {
        "type": "fax",
        "value": "01225 711798",
        "preLabel": "Fax: ",
      },
      {
        "type": "address",
        "value": `Nutricia Ireland Limited
                  Block 1
                  Deansgrange Business Park
                  Deansgrange
                  Co. Dublin`
      }
    ],
  },
];

export default ContactUs;
