import React, { useEffect, useState } from 'react';

function PageHeaderFixed({ children, offset, show }) {
	const [offsetY, setOffsetY] = useState(0);

	const updateOffset = () => {
		setOffsetY(window.pageYOffset);
	};

	useEffect(() => {
		window.addEventListener('scroll', updateOffset);

		return function cleanup() {
			window.removeEventListener('scroll', updateOffset);
		};
	}, []);

	return (
		<>
			{show ? (
				<header className={`c-page-header-fixed${(offsetY > offset) || (offset === 0) ? ' c-page-header-fixed__show' : ''}`}>
					{children}
				</header>
			) : null}
		</>
	);
}

export default PageHeaderFixed;
