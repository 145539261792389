import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);

      if('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations()
          .then(registrations => {
            registrations.forEach(registration => {
              registration.update();
            });
          });
      }
    });

    return () => {
      unlisten();
    }

    // eslint-disable-next-line
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);
