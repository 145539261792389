import React, { lazy, memo, Suspense } from "react";

const Item = lazy(() => import('./Item'));

const ResourceList = ({ items }) => (
    items && items.length > 0 ? (
        <ul className="c-resource-list">
            { items.map(resource => (
                <li
                    key={resource.id}
                    className="c-resource-list__item"
                >
                    <Suspense fallback={<div />}>
                        <Item {...resource} />
                    </Suspense>
                </li>
            ))}
        </ul>
    ) : null
);

export default memo(ResourceList);
