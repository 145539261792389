import React from 'react';
import { Button } from '@material-ui/core';
import labels from '../../data/labels.json';

function NotificationsFallbackSettings() {
  const subscribed = localStorage?.getItem('notificationsFallbackSubscribed') === 'true';
  const subscribeText = labels.site.notification_subscribe;
  const unsubscribeText = labels.site.notification_unsubscribe;

  // Handle checkbox interactions
  const handleToggle = () => {
    const value = !subscribed;
    localStorage.setItem('notificationsFallbackSubscribed', value);
    window.location.reload();
  };

  return (
    <>
      <Button
        onClick={handleToggle}
        classes={{ root: 'e-button' }}
        type="button"
      >
        { subscribed ? unsubscribeText : subscribeText }
      </Button>
    </>
  );
}

export default NotificationsFallbackSettings;
