import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

import Context from '../Context';
import PageHeader from '../../PageHeader';
import ROUTES from '../../../consts/routes.json';

function RegimenIntermittent() {
    const [state] = useContext(Context);
    const { navigationItems } = state;
    const history = useHistory();

    const filteredNavItems = navigationItems.filter(({ complete, id }) => (id === 'energy_requirements' || id === 'protein_calculator') && !complete);

    if (!filteredNavItems || !filteredNavItems.length) {
        history.push('/regimen');
    }

    return (
        <section className="regimen-intermittent">
            <PageHeader />

            <div className="regimen-intermittent__container">
                <p className="regimen-intermittent__text">You now have { filteredNavItems.length } additional option{ filteredNavItems.length === 1 ? '' : 's' } to calculate your patients energy, protein or fluid requirements...</p>
                
                <p className="regimen-intermittent__text">Or you can skip them to compare products</p>
            
                <div className="regimen-intermittent__buttons">
                    {
                        filteredNavItems.map(navItem => (
                            <Button
                                key={navItem.id}
                                to={navItem.url}
                                component={Link}
                                classes={{ root: 'e-button' }}
                            >{navItem.navigation_title}</Button>
                        ))
                    }

                    <Button
                        key="regimen-products"
                        to={ROUTES.CREATE_REGIMEN_4}
                        component={Link}
                        classes={{ root: 'e-button' }}
                    >Skip and go to compare products</Button>
                </div>
            </div>
        </section>
    );
}

export default RegimenIntermittent;
