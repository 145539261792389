export default [
  {
    "value": `Thank you for using the Nutricia Dietetic App (referred to as "the App”). By using the App you agree to be legally bound by the following Terms and Conditions, which shall take effect upon first use of the App. For the avoidance of doubt, the App also refers to the web based platform of the same. These Terms and Conditions will similarly apply to your use of the web based platform of the App.`,
  },
  {
    "value": `This App is an initiative of Nutricia Limited a company registered in England & Wales under company number 275552, whose registered office is at Newmarket Avenue, Whitehorse Business Park, Trowbridge, Wiltshire BA14 0XQ (NUTRICIA). NUTRICIA reserves the right to amend or change these Terms and Conditions at any time by updating the App. Please review these terms regularly to ensure you are aware of any changes made by NUTRICIA. Your continued use of the App after changes are made means you agree to be legally bound by these terms as updated and/or amended.`,
  },
  {
    "type": "title",
    "value": `Use of the App`,
  },
  {
    "value": `You must not copy, reproduce, republish, download, post, broadcast, transmit, make available to the public, or otherwise use the App content in any way except for your own personal, non-commercial use. You also agree not to adapt, alter or create a derivative work from any of the App content, except for your own personal, non-commercial use. Any other use of the App content requires the prior written permission of NUTRICIA. We reserve the right to change, modify, substitute or remove without notice any information on the App from time to time.`,
  },
  {
    "value": `You agree to use the App only for lawful purposes and in a way that does not harm the interests or infringe the rights of NUTRICIA or any other third party or user.`
  },
  {
    "type": "title",
    "value": `Disclaimers`,
  },
  {
    "value": `<strong>Information</strong>`,
  },
  {
    "value": `You shall use the information and data contained in the App at your own risks and under your own responsibility.`,
  },
  {
    "value": `NUTRICIA gives no warranties or representations of any kind, whether express or implied (to the extent permitted by law), on the information, data or materials contained in the App, including the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.`,
  },
  {
    "value": `The information and data contained in the App are provided on an "as is" and "as available" basis. It has not been written to meet specific individual requirements and it is your sole responsibility to satisfy yourself prior to using this information in any way that it is suitable for your purposes.`,
  },
  {
    "value": `It is not in any way an invitation or recommendation to buy any products or services featured. Before making any decisions based on any information contained in the App, you are strongly advised to refer to alternative independent sources of information to substantiate the basis for your decision.`,
  },
  {
    "type": "title",
    "value": `No Medical Advice`,
  },
  {
    "value": `In particular, information and data contained in the App are provided on an indicative basis, notably in relation to medical issues. The Nutricia App is designed solely for the use of Healthcare Professionals in the UK and Ireland and the information provided is for their use only. The Nutritional Requirements Calculator provides an estimate of nutritional requirements based on information inputted by the user. This information should only be used as a guide and does not replace nutritional assessment and clinical judgment by a qualified healthcare professional.`,
  },
  {
    "value": `The Nutritional Comparison allows the user to select products from the product compendium and to arrive at nutritional values which can then be compared against a reference nutrient intake for the patient's age group and gender. The analysis does not account for micronutrient intakes that the patient may be receiving from their normal dietary intake. This information should be used as a guide only and is not a replacement for nutritional assessment and clinical judgment by a qualified healthcare professional. Nutritional management of a patient is the responsibility of the managing healthcare professional and clinical decisions should not be made based on the output from the calculators included in the App.`,
  },
  {
    "value": `The App contains product information.`,
  },
  {
    "value": `<strong>The products discussed on the App are Foods for Special Medical Purposes and must be used under medical supervision.</strong>`,
  },
  {
    "value": `<strong>The content and product detail provided by the App is for information purposes only</strong>`,
  },
  {
    "value": `Although we carefully review our content, NUTRICIA does not guarantee or take responsibility for the medical accuracy of documents published in the App`,
  },
  {
    "value": `<strong>Access, third party sites and user data</strong>`,
  },
  {
    "value": `We will endeavour to allow uninterrupted access to the App, but NUTRICIA does not warrant that functions contained in the App will be uninterrupted or error free, that defects will be corrected, or that it is free of viruses or bugs. Access to the App may be suspended, restricted or terminated at any time. Whilst we make all reasonable attempts to exclude viruses and other harmful code from the App, NUTRICIA cannot ensure such exclusion and no liability is accepted for viruses and other harmful code.`,
  },
  {
    "value": `Thus, you are recommended to take all appropriate safeguards before downloading information from or accessing the App.`,
  },
  {
    "type": "title",
    "value": `Limitation of Liability`,
  },
  {
    "value": `We use reasonable endeavours to ensure that data on the App is accurate and to correct any errors or omissions as soon as practicable after being notified of them. To the extent permitted by applicable law, we disclaim all warranties and representations (whether express or implied) as to the accuracy of any information contained on the App. We do not guarantee that the App will be fault free and do not accept liability for any errors or omissions.`,
  },
  {
    "value": `Due to the nature of electronic transmission of data over the internet, any liability we may have for any losses or claims arising from an inability to access the App, or from any use of the App or reliance on the data transmitted using the App, is excluded to the fullest extent permissible by Iaw. In no event sha11 we be IiabIe for any indirect Ioss, consequential loss, loss of profit, data, revenue, business opportunity, anticipated savings, goodwi11 or reputation whether in contract, tort or otherwise arising out of or in connection with this agreement or use of the App save where such liability cannot be excluded by law.`,
  },
  {
    "value": `We do not give any warranty that the App is free from viruses or anything else which may have a harmful effect on any technology.`,
  },
  {
    "type": "title",
    "value": `Intellectual Property`,
  },
  {
    "value": `The names, images and logos identifying NUTRICIA or third parties and their products and services are subject to copyright, design rights and trademarks owned by or licensed to NUTRICIA and/or third parties.`,
  },
  {
    "value": `All rights are reserved. Nothing contained in these terms shall be construed as conferring by implication, estoppel or otherwise any license or right to use any trademark, patent, design right or copyright of NUTRICIA, or any other third party.`,
  },
  {
    "value": `Copyrights of the texts, images, the App's design, and its selection and arrangement, and all software compilations, underlying source code and software (including applets) and other elements appearing on the App are owned by NUTRICIA, or have been included with the consent of the respective owner. The reproduction of the App content, either in whole or in part, on any electronic support is formally forbidden unless expressly authorized by NUTRICIA.`,
  },
  {
    "value": `The reproduction on paper of texts contained in the App is authorised for educational purposes, subject to the fulfilment of the following conditions:`,
  },
  {
    "type": "unorderedlist",
    "value": [
      `free diffusion;`,
      `respect of all the reproduced documents;`,
      `clear and readable attribution of the source as follows:`,
    ],
  },
  {
    "value": `“this document is originated by NUTRICIA: The Nutricia App. All rights deriving therefrom are reserved and strictly limited". The name of the App must in all cases appear in such a reference.`,
  },
  {
    "value": `For any other use, the express prior consent of NUTRICIA is required.`,
  },
  {
    "value": `The trademarks and/or logos of NUTRICIA that appear on the App are trademarks registered worldwide and protected by trademark laws. Any reproduction made without the prior and express authorization of NUTRICIA shalI constitute counterfeit and is subject to criminal punishment. Other product and company names mentioned on the App may be the trademarks or registered trademarks of their respective owners.`,
  },
  {
    "type": "title",
    "value": `Data Protection and Privacy`,
  },
  {
    "value": `NUTRICIA commitments in relation to the safeguard of your privacy are defined in our Privacy Policy, which forms an integral and indivisible part of these Terms & Conditions.`,
  },
  {
    "type": "title",
    "value": `Cookies`,
  },
  {
    "value": `When accessing websites via the App you may be asked to accept cookies. Cookies are small data files which are placed on your computer by websites and which collect certain personal information about you. Cookies make it easier for you to log on to and use the site during future visits. They also allow us to monitor website traffic and to personalize the content of the site for you.`,
  },
  {
    "value": `We will request your consent to place cookies to gather information about your visits and use of the website to enhance your browsing experience, with the exception of cookies that are used for the sole purpose of carrying out the transmission of communication over the internet or that are strictly necessary to carry out the service requested by you. You can change your website browser settings to reject all cookies if you so wish, but please note that if you do this it may impair the functionality of this website. For further information on our cookie policy, please visit <a href="http://nutricia.co.uk/cookie-policy" target="_blank" rel="noopener noreferrer">http://nutricia.co.uk/cookie-policy</a>. Cookies will not be used for unsolicited communications. For further information on our cookie policy, please visit our website.`,
  },
  {
    "type": "title",
    "value": `Law Governing these Terms & Conditions`,
  },
  {
    "value": `These Terms and Conditions and any disputes arising under or related to these Terms and Conditions and/or the privacy policy of the App shalI be governed by and construed in accordance with English law. Any such dispute shall be subject to the exclusive jurisdiction of the English courts, to which both parties submit.`,
  },
  {
    "type": "title",
    "value": `Copyrights`,
  },
  {
    "value": `All the App's designs, texts, graphics, the selection and arrangement thereof are copyright © 2012 NUTRICIA­ ALL RIGHTS RESERVED.`,
  },
  {
    "type": "title",
    "value": `General`,
  },
  {
    "value": `If any provision of these terms and conditions is held to be unlawful, invalid or unenforceable, that provision shall be deemed severed and the validity and enforceability of the remaining provisions of these terms and conditions shall not be affected.`,
  },
  {
    "value": `NUTRICIA may terminate your access to the App and the services if you use the App in a way that is not permitted and/or contrary to these Terms <u>and</u> Conditions.`,
  },
  {
    "value": `All disclaimers, indemnities and exclusions in these terms and conditions shall survive termination of the agreement between us for any reason.`,
  },
];
