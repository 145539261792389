import EnterPatientDetails from './EnterPatientDetails';
import EnergyRequirements from './EnergyRequirements';
import Home from './Home';
import ProteinCalculator from './ProteinCalculator';
import ProductComparison from './ProductComparison';
import Report from './Report';
import Intermittent from './Intermittent';

export default [
    Home,
    Intermittent,
    EnterPatientDetails,
    EnergyRequirements,
    ProteinCalculator,
    ProductComparison,
    Report,
];