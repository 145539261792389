import React, { useContext, useEffect, useState } from 'react';
import { TextField as Field, InputAdornment } from '@material-ui/core';
import { Edit } from '@material-ui/icons';

import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';
import Toggle from '../Toggle';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';

function TextField({
    field,
}) {
    const [state, dispatch] = useContext(Context);
    const defaultValue = state[field.readonly] || state[field.id] || '';
    const [currentValue, setCurrentValue] = useState(defaultValue);
    // const [ isValid, setValidity ] = useState(true);

    const handleChange = event => {
        const value = event.target.value;
        // const minValue = field.minValue || 0;
        // const maxValue = field.maxValue || 9999;

        setCurrentValue(value);

        // Uncomment to apply field validation
        // if (value >= minValue && value <= maxValue) {
        //   setCurrentValue(value);
        //   setValidity(true);
        // } else {
        //   setValidity(false);
        // }
    };

    useDebouncedEffect(() => {
        dispatch({
            type: field.id,
            payload: currentValue,
        });
    }, [currentValue]);

    useEffect(() => {
        if (!state[field.readonly]) {
            return;
        }

        setCurrentValue(defaultValue);

        // eslint-disable-next-line
    }, [defaultValue]);

    return (
        <div className={`c-text-field${field.isResultField ? ' t-result' : ''}`}>
            {
                field?.toggle && <Toggle {...field.toggle} />
            }

            <FieldHeader
                title={field.title}
                tooltip={field.tooltip}
                labelFor={field.id}
            />

            <Field
                key={field.id}
                id={field.id}
                variant="outlined"
                value={`${field.readonly ? field.readonlyround ? Number(currentValue)?.toFixed(1) : currentValue : currentValue}${field.unit ? ` ${field.unit}` : ''}`}
                type={field.fieldType || 'text'}
                onChange={field.readonly ? null : handleChange}
                onBlur={field.readonly ? null : handleChange}
                InputProps={{
                    inputProps: field.fieldType && field.fieldType === 'number' ? {
                        min: 0,
                    } : {},
                    readOnly: field.readonly ? true : false,
                    endAdornment: (
                        !field.readonly &&
                        <InputAdornment position="end">
                            <Edit />
                        </InputAdornment>
                    )
                }}
            />
            {/* {
        !isValid &&
          <p className="e-error">Please enter a number over {field.minValue || 0} and below {field.maxValue || 9999}</p>
      } */}
        </div>
    );
}

export default TextField;
