const filterList = {
  age: {
    id: "age",
    label: "Age",
    type: "FILTER_AGE",
    options: [
      {
        id: "age-infant-0-12m",
        value: "age-infant-0-12m",
        label: "Infant (0-12m)",
      },
      {
        id: "age-child-1-18y",
        value: "age-child-1-18y",
        label: "Child (1-18y)",
      },
      {
        id: "age-adult-18-y",
        value: "age-adult-18-y",
        label: "Adult (18y+)",
      },
    ]
  },
  category: {
    id: "category",
    label: "Category",
    type: "FILTER_CATEGORY",
    options: [
      {
        id: "format-powder-and-tablets",
        value: "format-powder-and-tablets",
        label: "Powder and Tablets",
      },
      {
        id: "format-liquid",
        value: "format-liquid",
        label: "Liquid",
      },
      {
        id: "format-food",
        value: "format-food",
        label: "Food",
      },
      {
        id: "format-oral-nutritional-supplement",
        value: "format-oral-nutritional-supplement",
        label: "Oral Nutritional Supplement",
      },
      {
        id: "format-tube-feed",
        value: "format-tube-feed",
        label: "Tube Feed",
      },
    ]
  },
  suitableFor: {
    id: "suitableFor",
    label: "Suitable for",
    type: "FILTER_SPECIAL_DIET",
    description: "Choose products that are suitable for the selected dietary requirement.",
    options: [
      {
        id: "vegetarian",
        value: "vegetarian",
        label: "Vegetarian",
      },
      {
        id: "vegan",
        value: "vegan",
        label: "Vegan",
      },
      {
        id: "kosher",
        value: "kosher",
        label: "Kosher",
      },
      {
        id: "halal",
        value: "halal",
        label: "Halal",
      },
    ]
  },
  metabolic: {
    id: "metabolic_conditionas",
    label: "Metabolic conditions",
    type: "FILTER_METABOLICS",
    options: [
      {
        id: "metabolic-conditions-other",
        value: "metabolic-conditions-other",
        label: "Other",
      },
      {
        id: "metabolic-conditions-pku",
        value: "metabolic-conditions-pku",
        label: "PKU",
      },
      {
        id: "metabolic-conditions-ga1",
        value: "metabolic-conditions-ga1",
        label: "GA1",
      },
      {
        id: "metabolic-conditions-hcu",
        value: "metabolic-conditions-hcu",
        label: "HCU",
      },
      {
        id: "metabolic-conditions-iva",
        value: "metabolic-conditions-iva",
        label: "IVA",
      },
      {
        id: "metabolic-conditions-mma-pa",
        value: "metabolic-conditions-mma-pa",
        label: "MMA-PA",
      },
      {
        id: "metabolic-conditions-msud",
        value: "metabolic-conditions-msud",
        label: "MSUD",
      },
      {
        id: "metabolic-conditions-nkh",
        value: "metabolic-conditions-nkh",
        label: "NKH",
      },
      {
        id: "metabolic-conditions-sod",
        value: "metabolic-conditions-sod",
        label: "SOD",
      },
      {
        id: "metabolic-conditions-tyr",
        value: "metabolic-conditions-tyr",
        label: "TYR",
      },
      {
        id: "metabolic-conditions-ucd",
        value: "metabolic-conditions-ucd",
        label: "UCD",
      },
    ]
  },
};

export default filterList;
