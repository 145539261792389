import React from 'react';
import labels from '../../../data/labels.json';

function RotatePhone() {
	return (
		<div className='c-product-rotatephone'>
			<div className='c-product-rotatephone__wrapper'>
				<div className='c-product-rotatephone__icon' />
				<span>{labels?.pages?.products?.product_comparison_rotate}</span>
			</div>
		</div>
	);
}

export default RotatePhone;
