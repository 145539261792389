import { DescriptionOutlined } from '@material-ui/icons';

import ROUTES from "../../../consts/routes.json";
import TermsAndConditions from "../../../components/Support/TermsAndConditions";

export default {
    "component": TermsAndConditions,
    "id": "support_terms-and-conditions",
    "title": "Terms and conditions",
    "url": ROUTES.SUPPORT_TERMS_AND_CONDITIONS,
    "icon": DescriptionOutlined
};