import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import navigation from '../../data/navigation';
import api from "../../data/api.js";
 
const Context = createContext();

export default Context;

export const ContextProvider = ({
    children,
    userOnboarded,
}) => {
    const OneSignal = window.OneSignal || [];
    const location = useLocation();

    // Set default country to UK if not set
    let temp = localStorage?.getItem('userCountry');
    if(!temp){
        localStorage.setItem('userCountry', 'uk');
    }

    const [country, setCountry] = useState(localStorage?.getItem('userCountry'));
    const [navigationExpanded, handleNavigationExpand] = useState();
    const [filterListExpanded, handleFilterListExpanded] = useState();
    const [tooltipActive, handleTooltipActive] = useState();
    const [notification, updateNotification] = useState();
    const [isOnline, updateOnlineStatus] = useState(navigator.onLine);
    const [loading, updateLoading] = useState(true);

    // Check if user's browser support push notifications, for UI only
    const [browserSupportsNotifications, setBrowserSupportsNotifications] = useState("Notification" in window || window.webkitNotifications || navigator.mozNotification);

    // Check if user has enabled push notifications, for UI only
    const [userHasPushEnabled, setUserHasPushEnabled] = useState(browserSupportsNotifications && Notification?.permission === 'granted');

    // 
    const [subscribed, setSubscribed] = useState();

    const handleOnlineStatusUpdate = e => updateOnlineStatus(e.type === 'online');

    useEffect(() => {
        // add event listeners to detect user connectivity
        window.addEventListener('online', handleOnlineStatusUpdate);
        window.addEventListener('offline', handleOnlineStatusUpdate);

        // cleanup
        return () => {
            window.removeEventListener('online', handleOnlineStatusUpdate);
            window.removeEventListener('offline', handleOnlineStatusUpdate);
        }
    }, []);

    // collapse secondary nav, notification on route change
    useEffect(() => {
        handleNavigationExpand();
        updateNotification();
    }, [location]);

    function getSubscriptionState() {
        if (!OneSignal?.isPushNotificationsEnabled || !OneSignal?.isOptedOut) {
            return Promise.resolve().then(function () {
                return {
                    isPushEnabled: false,
                    isOptedOut: false,
                };
            });
        }

        return Promise.all([
            OneSignal.isPushNotificationsEnabled(),
            OneSignal.isOptedOut()
        ]).then(function (result) {
            return {
                isPushEnabled: false,
                isOptedOut: true,
            };
        });
    }

    function onManageWebPushSubscriptionButtonClicked(event) {
        getSubscriptionState().then(function (state) {
            if (!OneSignal?.setSubscription || !OneSignal?.registerForPushNotifications) {
                return;
            }

            if (state.isPushEnabled) {
                /* Subscribed, opt them out */
                OneSignal.setSubscription(false);
            } else {
                if (state.isOptedOut) {
                    /* Opted out, opt them back in */
                    OneSignal.setSubscription(true);
                } else {
                    /* Unsubscribed, subscribe them */
                    OneSignal.registerForPushNotifications();
                }
            }
        });
        event.preventDefault();
    }

    useEffect(() => {
        function updateManageWebPushSubscription() {
            getSubscriptionState().then(function (state) {
                if (!state.isPushEnabled || state.isOptedOut) {
                    setSubscribed(false);
                } else {
                    setSubscribed(true);
                };
            }).catch(error => console.log("getSubscriptionState error", error));
        }

        /* This example assumes you've already initialized OneSignal */
        OneSignal.push(function () {
            // If we're on an unsupported browser, do nothing
            if (OneSignal?.isPushNotificationsSupported) {
                if (!OneSignal.isPushNotificationsSupported()) {
                    setBrowserSupportsNotifications(false);
                    return;
                }
            }

            updateManageWebPushSubscription();

            if (OneSignal?.on) {
                OneSignal.on("subscriptionChange", function (isSubscribed) {
                    setSubscribed(isSubscribed);

                    if (OneSignal?.sendTags) {
                        OneSignal.sendTags({
                            adults: isSubscribed,
                            paediatrics: isSubscribed,
                            metabolics: isSubscribed,
                        });
                    }

                    updateManageWebPushSubscription();
                });

                OneSignal.on('notificationPermissionChange', function (permissionChange) {
                    setUserHasPushEnabled(permissionChange.to === 'granted');
                });
            }
        });

        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const readyToImport = Boolean(userOnboarded);

        if (readyToImport) {
            updateLoading(true);

            api.pull()
                .then(() => {
                    updateLoading();
                })
                .catch(error => {
                    updateLoading();
                });
        } else {
            updateLoading();
        }

    }, [userOnboarded]);

    useEffect(() => {
        // Web Push Notifications Fallback
        if (!browserSupportsNotifications && localStorage?.getItem('notificationsFallbackSubscribed') === 'true') {
            fetch(`https://onesignal.com/api/v1/notifications?app_id=${process.env.REACT_APP_CTP_ONESIGNAL_KEY}&limit=1`, {
                method: 'GET',
                headers: {
                    "Authorization": "Basic " + process.env.REACT_APP_CTP_ONESIGNAL_REST_KEY
                },
            })
                .then(e => e.json())
                .then(json => {
                    const data = json.notifications[0];
                    const currentNotifications = localStorage.getItem('userNotifications') ? JSON.parse(localStorage.getItem('userNotifications')) : [];

                    if (data && !currentNotifications.includes(data?.id)) {
                        updateNotification({
                            type: 'message',
                            title: data?.headings?.en,
                            text: data?.contents?.en,
                            link: data?.url,
                            image: data?.global_image,
                            isOneSignalNotification: true,
                            id: data?.id,
                        });
                    }
                })
                .catch(e => console.error(e));
        }

        // eslint-disable-next-line
    }, []);

    return (
        <Context.Provider
            value={{
                navigation,
                navigationExpanded,
                handleNavigationExpand,
                country,
                setCountry,
                filterListExpanded,
                handleFilterListExpanded,
                tooltipActive,
                handleTooltipActive,
                notification,
                updateNotification,
                isOnline,
                loading,
                userHasPushEnabled,
                onManageWebPushSubscriptionButtonClicked,
                browserSupportsNotifications,
                subscribed,
            }}
        >
            {children}
        </Context.Provider>
    );
};
