import ranges from '../calculation-sets/age-ranges';

const data = [
  {
    range: [ ranges.from.zero, ranges.to.sevenMonths ],
    value: 150,
  },
  {
    range: [ ranges.from.sevenMonths, ranges.to.oneYear ],
    value: 120,
  },
  {
    range: [ ranges.from.oneYear, ranges.to.fourYears ],
    value: 90,
  },
  {
    range: [ ranges.from.fourYears, ranges.to.sevenYears ],
    value: 80,
  },
  {
    range: [ ranges.from.sevenYears, ranges.to.elevenYears ],
    value: 60,
  },
  {
    range: [ ranges.from.elevenYears, ranges.to.fifteenYears ],
    value: 50,
  },
  {
    range: [ ranges.from.fifteenYears, ranges.to.eighteenYears ],
    value: 40,
  },
  {
    range: [ ranges.from.eighteenYears, ranges.to.sixtyYears ],
    value: 35,
  },
  {
    range: [ ranges.from.sixtyYears, ranges.to.max ],
    value: 30,
  },
];

const getResult = age => {
  let result;
  data.forEach(e => {
    if (age >= e.range[0] && age <= e.range[1]) {
      result = e.value;
    }
  });
  return result;
};

export default getResult;
