/* eslint-disable no-self-assign, react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useReducer } from 'react';
import { get, keys } from 'idb-keyval';
import filters from '../../data/forms/filters';
import Fuse from 'fuse.js'
import {
  products as ProductsStore,
} from '../../utils/db';
import { useLocation } from 'react-router-dom';


const initialState = {
  searchTerm: sessionStorage.getItem('searchTerm'),
  defaultProductList: [],
  results: [],
  loading: true,
  filterList: [],
  activeFilters: [],
};


const reducer = (state, action) => {

  

  function addFilter(payload) {
    const { id, label, type, value } = payload;
    let newArr = [ ...state.activeFilters ];
    const currentItem = newArr.find(e => e.id === id);
    if (!currentItem) {
      const newItem = {
        id,
        label,
        type,
        value,
      };
      newArr.push(newItem);
    }
    return newArr;
  }

  function removeFilter(payload) {
    const { id } = payload;
      let newArr = [ ...state.activeFilters ];
      const currentItem = newArr.find(e => e.id === id);
      if (currentItem) {
        newArr = newArr.filter(e => e.id !== id);
      }
    return newArr;
  }

  switch (action.type) {
    case 'ADD_FILTER':
      return {
        ...state,
        activeFilters: addFilter(action.payload),
      };
    case 'REMOVE_FILTER':
      return {
        ...state,
        activeFilters: removeFilter(action.payload),
      };
    case 'UPDATE_SEARCH_TERM':
      return {
        ...state,
        searchTerm: action.payload,
      };
    case 'UPDATE_DEFAULT_PRODUCT_LIST':
      return {
        ...state,
        defaultProductList: action.payload,
      };
    case 'UPDATE_RESULTS':
      return {
        ...state,
        results: action.payload,
      };
    case 'UPDATE_FILTER_LIST':
      return {
        ...state,
        filterList: action.payload,
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    default:
      if (window.location.href.includes('localhost') || window.location.href.includes('netlify')) {
        console.log(action);
      }
      return {
        ...state,
        [action.type]: action.payload,
      };
  }
};

export const ContextProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);
  const country = localStorage?.getItem('userCountry') || '';
  const location = useLocation();

  useEffect(() => {
    let filteredResults = state.defaultProductList;

    if (state.searchTerm && state.searchTerm !== '') {
      // Fuzzy search version
      const fuse = new Fuse(filteredResults, {
        keys: [`name`],
        includeScore: true,
        threshold: 0.4,
        minMatchCharLength: 2,
      });

      filteredResults = fuse.search(state.searchTerm).map(e => ({
        ...e.item,
        score: e.score,
      }));
    }

    if (state.activeFilters.length > 0) {
      state.activeFilters.forEach(filter => {
        switch (filter.type) {
          case 'FILTER_ALLERGENS':
            // filter results that DON'T match for allergens
            filteredResults = filteredResults.filter(e => e?.allergens.find(a => a.id === filter.id && a.value !== 'yes'));
          return;
          case 'FILTER_SPECIAL_DIET':
            filteredResults = filteredResults.filter(e => e?.specialDiet.find(a => a === filter.id));
          return;
          case 'FILTER_AGE':
            filteredResults = filteredResults.filter(e => e?.categories.find(a => a.slug === filter.id));
          return;
          case 'FILTER_CATEGORY':
            filteredResults = filteredResults.filter(e => e?.categories.find(a => a.slug === filter.id));
          return;
          case 'FILTER_METABOLICS':
            filteredResults = filteredResults.filter(e => e?.categories.find(a => a.slug === filter.id));
          return;
          default: 
            filteredResults = filteredResults;
          return;
        }
      });
    }

    dispatch({
      type: 'UPDATE_RESULTS',
      payload: filteredResults,
    });
  
  }, [   
    state.activeFilters,
    state.searchTerm,
    country,
    location
  ]);

  useEffect(() => {
    let promises = [];

    keys(ProductsStore)
      .then(keys => {
        for (let i = 0; i < keys.length; i++) {
          promises.push(
            get(keys[i], ProductsStore)
              .then(product => product)
              .catch(err => console.log(err))
          );
        }
      })
      .then(() => {
        Promise.all(promises)
          .then(data => {
            let localisedData = data;

            if (localisedData && localisedData.length > 0) {
              // filter data based on user's country selection
              localisedData = localisedData.filter(e => {
                const value = e?.productAvailability?.key;
                return value === 'both' || value === country;
              });
            }

            dispatch({
              type: 'LOADING',
              payload: false,
            });

            dispatch({
              type: 'UPDATE_DEFAULT_PRODUCT_LIST',
              payload: localisedData,
            });

            dispatch({
              type: 'UPDATE_RESULTS',
              payload: localisedData,
            });

            const newFilterList = [
              filters.age,
              filters.category,
              filters.suitableFor,
              {
                id: "allergens",
                label: "Allergens",
                type: "FILTER_ALLERGENS",
                description: `Select the allergen you want to avoid. This will choose products that do <strong>not</strong> contain the selected allergen. This filter is intended for indicative purposes only. Always ensure you check packaging or call our Resource Centre for flavour/presentation specific information.`,
                options: localisedData[0]?.allergens.map(e => (
                  {
                    id: e.id,
                    value: e.id,
                    label: e.label,
                  }
                ))
              },
              filters.metabolic,
            ];

            dispatch({
              type: 'UPDATE_FILTER_LIST',
              payload: newFilterList,
            });
          })
          .catch(error => console.log(error));
      });
    // eslint-disable-next-line
  }, []);

  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  );
};

const Context = createContext(initialState);

export default Context;
 