import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import ROUTES from '../../consts/routes.json';
import labels from '../../data/labels.json';
import { ContextProvider } from '../../components/ProductSearch/Context';
import { ContextProvider as RegimenProvider } from '../../components/Regimen/Context';
import ChatbotLanding from './Landing';
import Query from './Query';

const routes = [
    {
        path: ROUTES.CHATBOT_LANDING,
        labels: labels.pages.chatbot,
    },
    {
        path: ROUTES.CHATBOT_PRODUCT,
        labels: labels.pages.chatbot.productQuery,
    },
    {
        path: ROUTES.CHATBOT_GENERAL,
        labels: labels.pages.chatbot.generalQuery,
    },
    // {
    //     path: ROUTES.CHATBOT_STOCK,
    //     labels: labels.pages.chatbot.stockQuery,
    // },
    {
        path: ROUTES.CHATBOT_FAQ,
        labels: labels.pages.chatbot.faq,
    }
];

function Chatbot() {
    const country = localStorage.getItem('userCountry');
    console.log(country);

    // Register chatbot
    useEffect(() => {
        if (!window.__be) {
            window.__be = window.__be || {};
            // window.__be.id = `${process.env.REACT_APP_CTP_CHATBOT_KEY}`;
            window.__be.id = "65ef1e34b4124100063abf3d";

            (function() {
                var be = document.createElement('script'); be.type = 'text/javascript'; be.async = true; be.id = 'chatbot-tag'
                be.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + 'cdn.chatbot.com/widget/plugin.js';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(be, s);
            })();
        }
        
        window.BE_API = window.BE_API || {};

        window.BE_API.onLoad = () => {
            window.BE_API.setSessionAttributes({
                user_country: country
            })
        }
        
        return () => { }
    }, [country]);

    return (
        <ContextProvider>
            <RegimenProvider>
                <Switch>
                    {routes.map((obj, i) => (
                        <Route exact path={obj.path} key={obj.path}>
                            {
                                i === 0 
                                ? <ChatbotLanding labels={obj.labels} />
                                : <Query labels={obj.labels} intro={labels.pages.chatbot.intro} />
                            }
                        </Route>
                    ))}
                </Switch>
            </RegimenProvider>
        </ContextProvider>
    );
}

export default Chatbot;
