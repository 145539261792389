import React, { useState, useContext } from 'react';
import {
    TextField as Field,
    InputAdornment,
} from '@material-ui/core';
import {
    Edit,
} from '@material-ui/icons';
import labels from '../../../data/labels.json';
import Context from '../../Regimen/Context';
import FieldHeader from '../FieldHeader';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';

function ImperialTab() {

    const [state, dispatch] = useContext(Context);

    const KGConversionRate = 0.45359237;

    const [currentWeightKG, updateCurrentWeightKG] = useState(state.PD_WEIGHT || '');
    const [previousWeightKG, updatePreviousWeightKG] = useState(state.PD_WEIGHT_PREVIOUS || '');

    const [currentWeightStones, updateCurrentWeightStones] = useState(() => {
        const result = currentWeightKG / KGConversionRate;
        return Math.floor(result / 14);
    });

    const [currentWeightPounds, updateCurrentWeightPounds] = useState(() => {
        const result = currentWeightKG / KGConversionRate;
        return (result % 14).toFixed(2);
    });

    const [previousWeightStones, updatePreviousWeightStones] = useState(() => {
        const result = previousWeightKG / KGConversionRate;
        return Math.floor(result / 14);
    });

    const [previousWeightPounds, updatePreviousWeightPounds] = useState(() => {
        const result = previousWeightKG / KGConversionRate;
        return (result % 14).toFixed(2);
    });

    // const stonesLimit = 40;
    const poundsLimit = 13;

    // const [ currentStonesValid, setCurrentStonesValid ] = useState(true);
    // const [ previousStonesValid, setPreviousStonesValid ] = useState(true);
    // const [ currentPoundsValid, setCurrentPoundsValid ] = useState(true);
    // const [ previousPoundsValid, setPreviousPoundsValid ] = useState(true);

    const handleCurrentWeightStonesChange = event => {
        const value = event.target.value;
        updateCurrentWeightStones(value);

        // Uncomment to apply field validation
        // if (value >= 0 && value <= stonesLimit) {
        //   updateCurrentWeightStones(value);
        //   setCurrentStonesValid(true);
        // } else {
        //   setCurrentStonesValid(false);
        // }
    };

    const handlePreviousWeightStonesChange = event => {
        const value = event.target.value;
        updatePreviousWeightStones(value);

        // Uncomment to apply field validation
        // if (value >= 0 && value <= stonesLimit) {
        //   updatePreviousWeightStones(value);
        //   setPreviousStonesValid(true);
        // } else {
        //   setPreviousStonesValid(false);
        // }
    };

    const handleCurrentWeightPoundsChange = event => {
        const value = event.target.value;
        updateCurrentWeightPounds(value);

        // Uncomment to apply field validation
        // if (value >= 0 && value <= poundsLimit) {
        //   updateCurrentWeightPounds(value);
        //   setCurrentPoundsValid(true);
        // } else {
        //   setCurrentPoundsValid(false);
        // }
    };

    const handlePreviousWeightPoundsChange = event => {
        const value = event.target.value;
        updatePreviousWeightPounds(value);

        // Uncomment to apply field validation
        // if (value >= 0 && value <= poundsLimit) {
        //   updatePreviousWeightPounds(value);
        //   setPreviousPoundsValid(true);
        // } else {
        //   setPreviousPoundsValid(false);
        // }
    };

    const {
        stones_current,
        stones_previous,
        pounds_current,
        pounds_previous,
    } = labels?.pages?.regimen;

    useDebouncedEffect(() => {
        const totalPounds = Number(currentWeightStones * 14) + Number(currentWeightPounds);
        const resultInKG = totalPounds * KGConversionRate;
        updateCurrentWeightKG(resultInKG);
    }, [currentWeightStones, currentWeightPounds]);

    useDebouncedEffect(() => {
        const totalPounds = Number(previousWeightStones * 14) + Number(previousWeightPounds);
        const result = totalPounds * KGConversionRate;
        updatePreviousWeightKG(result);
    }, [previousWeightStones, previousWeightPounds]);

    useDebouncedEffect(() => {
        dispatch({
            type: 'PD_WEIGHT',
            payload: Math.round(currentWeightKG),
        });
    }, [currentWeightKG]);

    useDebouncedEffect(() => {
        dispatch({
            type: 'PD_WEIGHT_PREVIOUS',
            payload: Math.round(previousWeightKG),
        });
    }, [previousWeightKG]);

    return (
        <>
            <FieldHeader
                title="Current"
            />
            <div className="c-weight-picker__controls">
                <div className="c-weight-picker__dropdown">
                    <FieldHeader
                        title={stones_current}
                        labelFor="PD_WEIGHT_CURRENT_ST"
                    />
                    <div className="c-text-field">
                        <Field
                            id="PD_WEIGHT_CURRENT_ST"
                            variant="outlined"
                            onChange={handleCurrentWeightStonesChange}
                            onBlur={handleCurrentWeightStonesChange}
                            value={currentWeightStones}
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Edit />
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/* { !currentStonesValid && <p className="e-error">Please enter a value below or equal to {stonesLimit}</p> } */}
                    </div>
                </div>
                <div className="c-weight-picker__dropdown">
                    <FieldHeader
                        title={pounds_current}
                        labelFor="PD_WEIGHT_CURRENT_LB"
                    />
                    <div className="c-text-field">
                        <Field
                            id="PD_WEIGHT_CURRENT_LB"
                            variant="outlined"
                            onChange={handleCurrentWeightPoundsChange}
                            onBlur={handleCurrentWeightPoundsChange}
                            value={currentWeightPounds}
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: poundsLimit,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Edit />
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/* { !currentPoundsValid && <p className="e-error">Pleae enter a value below or equal to {poundsLimit}</p> } */}
                    </div>
                </div>
            </div>
            <FieldHeader
                title="Previous"
            />
            <div className="c-weight-picker__controls">
                <div className="c-weight-picker__dropdown">
                    <FieldHeader
                        title={stones_previous}
                        labelFor="PD_WEIGHT_PREVIOUS_ST"
                    />
                    <div className="c-text-field">
                        <Field
                            id="PD_WEIGHT_PREVIOUS_ST"
                            variant="outlined"
                            onChange={handlePreviousWeightStonesChange}
                            onBlur={handlePreviousWeightStonesChange}
                            value={previousWeightStones}
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Edit />
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/* { !previousStonesValid && <p className="e-error">Please enter a value below or equal to {stonesLimit}</p> } */}
                    </div>
                </div>
                <div className="c-weight-picker__dropdown">
                    <FieldHeader
                        title={pounds_previous}
                        labelFor="PD_WEIGHT_PREVIOUS_LB"
                    />
                    <div className="c-text-field">
                        <Field
                            id="PD_WEIGHT_PREVIOUS_LB"
                            variant="outlined"
                            onChange={handlePreviousWeightPoundsChange}
                            onBlur={handlePreviousWeightPoundsChange}
                            value={previousWeightPounds}
                            type="number"
                            InputProps={{
                                inputProps: {
                                    min: 0,
                                    max: poundsLimit,
                                },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Edit />
                                    </InputAdornment>
                                )
                            }}
                        />
                        {/* { !previousPoundsValid && <p className="e-error">Please enter a value below or equal to {poundsLimit}</p> } */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default ImperialTab;
