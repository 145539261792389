import React, { /* useEffect, */ useState } from 'react';
import PageHeaderProduct from '../../../components/PageHeaderProduct';
import FilterList from '../../../components/FilterList';
import ProductSearchForm from '../../../components/ProductSearch/Form';
import ProductSearchResults from '../../../components/ProductSearch';
import labels from '../../../data/labels.json';
import PageHeaderFixed from '../../../components/PageHeaderFixed';
import HeaderComparison from '../../../components/ProductSearch/HeaderComparison';

function ProductsHome() {
	const { page_title, subtitle, text, /* tooltip */ } = labels?.pages?.products;
	const [showHeaderComparison, setShowHeaderComparison] = useState(false);
	const [comparedProduct, setComparedProduct] = useState([]);

	const handleCancelComparison = () => {
		setComparedProduct([]);
	};

	return (
		<>
			{comparedProduct?.length ? (
				<PageHeaderFixed
					offset={comparedProduct.length > 0 ? 0 : 360}
					show={comparedProduct.length > 0 ? showHeaderComparison : true}
				>
					{comparedProduct.length > 0 ? (
						<HeaderComparison onCancelComparison={handleCancelComparison} />
					) : (
						<ProductSearchForm />
					)}
				</PageHeaderFixed>
			): null}

			<PageHeaderProduct
				page_title={page_title}
				subtitle={subtitle}
				text={text}
			/>

			<div className="c-product-container">
				<ProductSearchForm 
					comparedProduct={comparedProduct}
				/>

				<ProductSearchResults
					comparedProduct={comparedProduct}
					setComparedProduct={setComparedProduct}
					setShowHeaderComparison={setShowHeaderComparison}
				/>

				<FilterList />
			</div>
		</>
	);
}

export default ProductsHome;
