import React, { useContext, useEffect, createRef, lazy, Suspense } from 'react';
import {
  Button,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Context from '../ProductSearch/Context';
import SiteContext from '../Context';
import Loader from '../Loader';
import labels from '../../data/labels.json';

const FilterItem = lazy(() => import('./Item'));

function FilterList() {
  const [ state ] = useContext(Context);
  const { filterListExpanded, handleFilterListExpanded } = useContext(SiteContext);
  const { product_filter_cta, product_filter_title, product_filter_clear } = labels?.pages?.products;
  const closeButtonRef = createRef();

  useEffect(() => {
    if (filterListExpanded && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }

    // eslint-disable-next-line
  }, []);

  return (
    <form
      onSubmit={e => e.preventDefault()}
      className={`c-filter-list ${filterListExpanded ? 'c-filter-list--active' : ''}`}>
      <div className="c-filter-list__inner">
        <div className="c-filter-list__header">
          <h4 className="c-filter-list__title">{product_filter_title}</h4>
          <Button
            ref={closeButtonRef}
            className="c-filter-list__close"
            onClick={() => handleFilterListExpanded()}
            type="button"
          >
            <Close />
          </Button>
        </div>
        {
          state.filterList && state.filterList.length > 0 ?
            <div className="c-filter-list__list c-filter-list__list-wrapper">
              <ul>
                {
                  state.filterList.map(filter => (
                    <li
                      key={filter.id}
                    >
                      <Suspense fallback={<div />}>
                        <FilterItem filter={filter}/>
                      </Suspense>
                    </li>
                  ))
                }
              </ul>
            </div>
            : <Loader />
        }
        <div className="c-filter-list__submit" >
          <Button
            className="e-button c-filter-list__submit--clear"
            color="primary"
            onClick={() => handleFilterListExpanded()}
            size="large"
            variant="contained"
            type="button"
          >
            {product_filter_clear}
          </Button>
          <Button
            className="e-button c-filter-list__submit--show"
            color="primary"
            onClick={() => handleFilterListExpanded()}
            size="large"
            variant="contained"
            type="button"
            disabled={!state.activeFilters || state.activeFilters.length === 0}
          >
            {product_filter_cta}
          </Button>
        </div>
      </div>
    </form>
  );
}

export default FilterList;
