import React from 'react';
import Instructions from '../../components/Instructions';
import { isIOS, isSafari, isWindows, isChrome, isAndroid, isMacOs, isSamsungBrowser } from 'react-device-detect';

function SaveToHomeScreen() {
  const options = [
    {
      id: 'ios',
      items: [
        {
          id: 1,
          icon: 'ios-safari-share',
          text: `Press the '<i>share</i>' button`,
        },
        {
          id: 2,
          icon: 'ios-safari-plus',
          text: `Press '<i>add to home screen</i>'`,
        },
      ],
      active: isIOS && isSafari,
    },
    {
      id: 'windows_chrome',
      items: [
        {
          id: 1,
          icon: 'windows-chrome-plus',
          text: `Click the <i>three-dot icon</i> icon in the top-right corner of the browser window`,
        },
        {
          id: 2,
          icon: 'windows-chrome-confirm',
          text: `Next, hover over <i>More tools</i> and then click <i>Create shortcut</i>`,
        }
      ],
      active: (isWindows && isChrome) || (isMacOs && isChrome),
    },
    {
      id: 'android_chrome',
      items: [
        {
          id: 1,
          icon: 'android-chrome-plus',
          text: `Open the three-dot menu in the top-right corner of the browser window`,
        },
        {
          id: 2,
          icon: 'android-chrome-confirm',
          text: `Select Add to Home screen`,
        }
      ],
      active: isAndroid && isChrome,
    },
    {
      id: 'samsung',
      items: [
          {
              id: 1,
              text: `Open the three-line menu in the top-right corner of the browser window.`,                
          },
          {
              id: 2,
              text: `Select <i>Add page to</i> and then select Home screen from the list of options`
          }
      ],
      active: isAndroid && isSamsungBrowser,
    },
    {
      id: 'android',
      items: [
        {
          id: 1,
          text: `Under <i>settings</i>, tap the <i>Add to Home screen</i> option.`,
        }
      ],
      active: isAndroid,
    }
  ];

  const content = options.find(e => e.active);

  return (
    content?.items?.length > 0 ?
      <Instructions items={content.items} />
      : <p>Sorry, your browser cannot currently save this app to your homescreen.</p>
  );
}

export default SaveToHomeScreen;
