import ranges from '../../../age-ranges';

// Ages display in months
const male = {
  'pal_none': [
    {
      age: ranges.from.sixMonths,
      value: 597,
    },
    {
      age: ranges.from.sevenMonths,
      value: 645,
    },
    {
      age: ranges.from.eightMonths,
      value: 669,
    },
    {
      age: ranges.from.nineMonths,
      value: 823,
    },
    {
      age: ranges.from.tenMonths,
      value: 717,
    },
    {
      age: ranges.from.elevenMonths,
      value: 740,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 0,
      inactive: true,
    },
  ],
  'pal_14': [
    {
      age: ranges.from.sixMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.sevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.eightMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.nineMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.tenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.elevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 788,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 1027,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 1170,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 1266,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 1338,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 1409,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 1505,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 1600,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 1672,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 0,
      inactive: true,
    },
  ],
  'pal_16': [
    {
      age: ranges.from.sixMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.sevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.eightMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.nineMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.tenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.elevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 1433,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 1529,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 1600,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 1720,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 1815,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 1935,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 1935,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 2030,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 2173,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 2341,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 2508,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 2699,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 2842,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 2938,
    },
  ],
  'pal_18': [
    {
      age: ranges.from.sixMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.sevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.eightMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.nineMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.tenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.elevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 1624,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 1720,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 1815,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 1935,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 2054,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 2173,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 2173,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 2293,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 2436,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 2627,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 2818,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 3033,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 3200,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 3296,
    },
  ],
  'pal_20': [
    {
      age: ranges.from.sixMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.sevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.eightMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.nineMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.tenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.elevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 2412,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 2555,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 2723,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 2914,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 3129,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 3368,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 3559,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 3678,
    },
  ]
};

const female = {
  'pal_none': [
    {
      age: ranges.from.sixMonths,
      value: 549,
    },
    {
      age: ranges.from.sevenMonths,
      value: 573,
    },
    {
      age: ranges.from.eightMonths,
      value: 597,
    },
    {
      age: ranges.from.nineMonths,
      value: 621,
    },
    {
      age: ranges.from.tenMonths,
      value: 645,
    },
    {
      age: ranges.from.elevenMonths,
      value: 669,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 0,
      inactive: true,
    },
  ],
  'pal_14': [
    {
      age: ranges.from.sixMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.sevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.eightMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.nineMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.tenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.elevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 717,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 955,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 1099,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 1170,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 1242,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 1314,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 1386,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 1481,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 1576,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 0,
      inactive: true,
    },
  ],
  'pal_16': [
    {
      age: ranges.from.sixMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.sevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.eightMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.nineMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.tenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.elevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 1337,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 1409,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 1505,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 1600,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 1696,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 1791,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 1815,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 1911,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 2006,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 2102,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 2173,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 2221,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 2269,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 2269,
    },
  ],
  'pal_18': [
    {
      age: ranges.from.sixMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.sevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.eightMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.nineMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.tenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.elevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 1505,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 1600,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 1696,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 1791,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 1887,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 2006,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 2054,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 2149,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 2245,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 2364,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 2436,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 2508,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 2532,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 2555,
    },
  ],
  'pal_20': [
    {
      age: ranges.from.sixMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.sevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.eightMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.nineMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.tenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: ranges.from.elevenMonths,
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.oneYear, ranges.to.twoYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.twoYears, ranges.to.threeYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.threeYears, ranges.to.fourYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fourYears, ranges.to.fiveYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.fiveYears, ranges.to.sixYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sixYears, ranges.to.sevenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.sevenYears, ranges.to.eightYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.eightYears, ranges.to.nineYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.nineYears, ranges.to.tenYears ],
      value: 0,
      inactive: true,
    },
    {
      age: [ ranges.from.tenYears, ranges.to.elevenYears ],
      value: 2269,
    },
    {
      age: [ ranges.from.elevenYears, ranges.to.twelveYears ],
      value: 2388,
    },
    {
      age: [ ranges.from.twelveYears, ranges.to.thirteenYears ],
      value: 2508,
    },
    {
      age: [ ranges.from.thirteenYears, ranges.to.fourteenYears ],
      value: 2627,
    },
    {
      age: [ ranges.from.fourteenYears, ranges.to.fifteenYears ],
      value: 2723,
    },
    {
      age: [ ranges.from.fifteenYears, ranges.to.sixteenYears ],
      value: 2794,
    },
    {
      age: [ ranges.from.sixteenYears, ranges.to.seventeenYears ],
      value: 2818,
    },
    {
      age: [ ranges.from.seventeenYears, ranges.to.max ],
      value: 2842,
    },
  ]
};

const getResult = ({
  pal,
  age,
  gender,
}) => {
  let result;
  const data = gender === 'male' ? male[pal] : female[pal];

  if (data) {
    data.forEach(e => {
      switch (typeof e.age) {
        case 'number':
          if (e.age === age) {
            result = e.inactive ? '-' : e.value;
          }
          return;
        case 'object':
          if (age >= e.age[0] && age <= e.age[1]) {
            result = e.inactive ? '-' : e.value;
          }
          return;
        default:
          return;
      }
    });
  }

  return result;
};


export default getResult;
