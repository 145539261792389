import ROUTES from "../../../consts/routes.json";
import Landing from '../../../components/Regimen/Landing';

export default {
  "id": "regimen_home",
  "url": ROUTES.CREATE_REGIMEN,
  "component": Landing,
  "hiddenFromNavigation": true,
  "exact": true,
};
