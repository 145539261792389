import React from 'react';

function MultiDropdown({ children }) {
  // Is this component still needed? maybe as an element wrapper
  return (
    <div>{children}</div>
  );
}

export default MultiDropdown;
