const countries = [
    {
        label: "United Kingdom",
        shortLabel: "U.K.",
        value: "uk",
    },
    {
        label: "Republic of Ireland",
        shortLabel: "R.O.I.",
        value: "roi",
    },
];

export default countries;
