import React, { memo } from 'react';
import { toTitleCase } from '../../../utils/index';;

function NutrientTable({
	title,
	productItems,
	double,
	splitValues,
	unit,
	showUnit,
	takeFirstValue,
	removeUnitFromName,
	showBothValues,
	headings,
	aminoAcids
}) {
	//const isSelectedRow = row => row === 'Energy' || row === 'Protein';

	const getVal = (nutrient, val) => {
		let returnVal = '';
		nutrient.forEach(_val => {
			if (_val.name === val){
				returnVal = _val.value;
			}
		});
		return returnVal;
	};

	return productItems && productItems.length > 0 ? (


		<div>
			{!showBothValues && !aminoAcids &&
				<div className='c-nutrient-table'>
					<ul className={'c-product__list--nutrition' + (double ? ' c-product__list--double' : '')}>
						{productItems?.map((nutrient, index) => (
							nutrient[2].value !== '-,-' &&
							<li key={index}>
								<div>
									{
										aminoAcids ?
										<span>{`${getVal(nutrient, "nutrientName")} (${getVal(nutrient, "nutrientUnit")})`}</span>
										:
										<span>{`${getVal(nutrient, "nutrientUnit") === "percent" ? toTitleCase(getVal(nutrient, "nutrientName")).slice(0, -3) : toTitleCase(getVal(nutrient, "nutrientName")).slice(0, -(getVal(nutrient, "nutrientUnit").length))} ${showUnit && '(' + getVal(nutrient, "nutrientUnit") + ')'}`}</span>
									}
								</div>
								<div>
									{nutrient[2].value && 
										Number.isNaN(+nutrient[2].value) ? getVal(nutrient, "nutrientAmount") : (+getVal(nutrient, "nutrientAmount") < 1) ? +Number.parseFloat(getVal(nutrient, "nutrientAmount")).toFixed(3) : (getVal(nutrient, "nutrientAmount") < 10) ? +Number.parseFloat(getVal(nutrient, "nutrientAmount")).toFixed(2) : +Number.parseFloat(getVal(nutrient, "nutrientAmount")).toFixed(1)
									}
								</div>
							</li>
						))}
					</ul>
				</div>
			}
			{showBothValues && !aminoAcids &&
				<div className='c-nutrient-table'>
					<ul className={'c-product__list--nutrition' + (double ? ' c-product__list--double' : '')}>
						{productItems?.map((nutrient, index) => (
							<>
								<li key={`n-${index}`}>
									<div>
										<span className="c-nutrient-table__item-heading">{toTitleCase(removeUnitFromName ? `${getVal(nutrient, "nutrientName").substring(0, getVal(nutrient, "nutrientName").lastIndexOf(" "))} ${showUnit && '(' + getVal(nutrient, "nutrientUnit") + ')'}` : `${getVal(nutrient, "nutrientName")} ${showUnit && '(' + getVal(nutrient, "nutrientUnit") + ')'}`)}</span>
									</div>
									<div>
										<span className="c-nutrient-table__item-heading">{toTitleCase(headings[0])}</span>
									</div>
									<div>
										{getVal(nutrient, "nutrientAmount").split(',')[0]}
									</div>
								</li>
								{headings.length > 1 && getVal(nutrient, "nutrientAmount").split(',')[1] !== "-"?
									<li key={`n-${index}b`}>
										<div>
											<span className="c-nutrient-table__item-heading">{toTitleCase(removeUnitFromName ? `${getVal(nutrient, "nutrientName").substring(0, getVal(nutrient, "nutrientName").lastIndexOf(" "))} ${showUnit && '(' + getVal(nutrient, "nutrientUnit") + ')'}` : `${getVal(nutrient, "nutrientName")} ${showUnit && '(' + getVal(nutrient, "nutrientUnit") + ')'}`)}</span>
										</div>										<div>
											<span className="c-nutrient-table__item-heading">{toTitleCase(headings[1])}</span>
										</div>
										<div>
											{getVal(nutrient, "nutrientAmount").split(',')[1]}
										</div>
									</li>
									:
									headings.length > 1 ?
									<li>
										<div>
											<span className="c-nutrient-table__item-heading">{toTitleCase(removeUnitFromName ? `${getVal(nutrient, "nutrientName").substring(0, getVal(nutrient, "nutrientName").lastIndexOf(" "))} ${showUnit && '(' + getVal(nutrient, "nutrientUnit") + ')'}` : `${getVal(nutrient, "nutrientName")} ${showUnit && '(' + getVal(nutrient, "nutrientUnit") + ')'}`)}</span>
										</div>	
										<div>
											<span className="c-nutrient-table__item-heading">{toTitleCase(headings[1])}</span>
										</div>
										<div>
											Data not available
										</div>
									</li>
									:
									<li className="ut-remove-border">
										
									</li>
								}
								
							</>
						))}
					</ul>
				</div>
			}
			{aminoAcids &&
				<div className='c-nutrient-table'>
				<ul className={'c-product__list--nutrition' + (double ? ' c-product__list--double' : '')}>
					{productItems?.map((nutrient, index) => (
						true && getVal(nutrient, "nutrientAmount").split(',')[0] !== '-,-' && getVal(nutrient, "nutrientAmount").split(',')[0] !== '-' &&
						<>
							<li key={`n-${index}`}>
								<div>
									<span className="c-nutrient-table__item-heading">{`${getVal(nutrient, "nutrientName")} (${getVal(nutrient, "nutrientUnit")})`}</span>
								</div>
								<div>
									<span className="c-nutrient-table__item-heading">{toTitleCase(headings[0])}</span>
								</div>
								<div>
									{getVal(nutrient, "nutrientAmount").split(',')[0]}
								</div>
							</li>
							{headings.length > 1  && getVal(nutrient, "nutrientAmount").split(',')[1] !== '-' ?
								<li key={`n-${index}b`}>
									<div>
										<span className="c-nutrient-table__item-heading">{`${getVal(nutrient, "nutrientName")} (${getVal(nutrient, "nutrientUnit")})`}</span>
									</div>										
									<div>
										<span className="c-nutrient-table__item-heading">{toTitleCase(headings[1])}</span>
									</div>
									<div>
										{getVal(nutrient, "nutrientAmount").split(',')[1]}
									</div>
								</li> : 
								headings.length > 1 ?
								<li>
									<div>
										<span className="c-nutrient-table__item-heading">{`${getVal(nutrient, "nutrientName")} (${getVal(nutrient, "nutrientUnit")})`}</span>
									</div>										
									<div>
											<span className="c-nutrient-table__item-heading">{toTitleCase(headings[1])}</span>
										</div>
									<div>
										Data not available
									</div>
								</li>:
								<li className="ut-remove-border">

								</li>
							}
							
						</>
					))}
				</ul>
			</div>
			}
		</div>
	) : null;


}

export default memo(NutrientTable);
