import React, { useContext, useState } from 'react';
import {
  TextField as Field,
  InputAdornment,
} from '@material-ui/core';
import {
  Edit,
} from '@material-ui/icons';
import Context from '../../Regimen/Context';
import labels from '../../../data/labels.json';
import FieldHeader from '../FieldHeader';
import useDebouncedEffect from '../../../utils/hooks/useDebouncedEffect';

function MetricTab() {
  const [ state, dispatch ] = useContext(Context); 
  const [ totalInCentimeters, updateTotal ] = useState(state.PD_HEIGHT || '');
  const [ cmValue, updateCMValue ] = useState(totalInCentimeters || '');
  // const [ cmValid, setCmValid ] = useState(true);
  const cmLimit = 300;

  const handleCentimetersChange = event => {
    const value = event.target.value;
    updateCMValue(value);


    // Uncomment to apply field validation
    // if (value >= 0 && value <= cmLimit) {
    //   updateCMValue(value);
    //   setCmValid(true);
    // } else {
    //   setCmValid(false);
    // }
  };

  useDebouncedEffect(() => updateTotal(Number(cmValue)), [ cmValue ]);

  useDebouncedEffect(() => {
    dispatch({
      type: 'PD_HEIGHT',
      payload: totalInCentimeters,
    });
  }, [ totalInCentimeters ]);

  const {
    centimeters_title,
  } = labels?.pages?.regimen;

  return (
    <div className="c-height-picker__controls">
      <div className="c-height-picker__dropdown">
        <FieldHeader
          title={centimeters_title}
          labelFor="PD_HEIGHT_CM"
        />
        <div className="c-text-field">
          <Field
            id="PD_HEIGHT_CM"
            variant="outlined"
            onBlur={handleCentimetersChange}
            onChange={handleCentimetersChange}
            value={cmValue}
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
                max: cmLimit,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Edit />
                </InputAdornment>
              )
            }}
          />
          {/* { !cmValid && <p className="e-error">Please enter a value below or equal to {cmLimit}</p> } */}
        </div>
      </div>
    </div>
  );
}

export default MetricTab;
