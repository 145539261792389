import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

import labels from '../../../data/labels.json';
import ROUTES from '../../../data/forms/support';
import HeaderImage from '../../../images/support-header.svg'
import Context from '../../Context';

export default function Home() {
    const { page_title } = labels?.pages?.support;
    const { isOnline } = useContext(Context);
    const history = useHistory();

    return (
        <section className="support">
            <header className="support__header">
                <h1 className="support__title">{page_title}</h1>
                
                <img
                    src={HeaderImage}
                    alt="Illustration of a page with cog on it"
                    className="support__image"
                />
            </header>

            <ul className="support__list">
                { ROUTES.map(route => (
                    route.id !== 'support_home' ? (
                        <li
                            key={route.id}
                            className="support__item"
                        >
                            { route.component ? (
                                route.external ? (
                                    <a
                                        href={route.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="support__link"
                                    >
                                        <span className="support__link__text">{route.title}</span>

                                        <route.icon />
                                    </a>
                                ) : (
                                    <Link
                                        to={route.url}
                                        className="support__link"
                                    >
                                        <span className="support__link__text">{route.title}</span>
                                        
                                        <route.icon />
                                    </Link>
                                )
                            ) : (
                                <button key={route.id} className={`text-left w-100 button button--clear support__link`} disabled={!isOnline} onClick={() => {history.push("/"); window.location.reload();}}>
                                    <span className="support__link__text">{route.title}</span>

                                    <route.icon />
                                </button>
                            )}
                        </li>
                    ) : null
                ))}
            </ul>
        </section>
    );
}