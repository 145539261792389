import { useCallback, useEffect } from "react";

function useDebouncedEffect(effect, deps) {
    const delay = 10;
    const callback = useCallback(effect, deps);

    useEffect(() => {
        const handler = setTimeout(() => {
            callback();
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [ callback ]);
};

export default useDebouncedEffect;
