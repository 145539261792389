import React from 'react';

import labels from '../../../data/labels.json';
import PageHeader from '../../PageHeader';
import TextPage from '../../../templates/TextPage';

import content from '../../../data/pages/terms';

export default function TermsAndConditions() {
    const { page_title } = labels?.pages?.support?.terms_and_conditions;

    return (
        <div className="support-terms-and-conditions">
            <PageHeader
                page_title={page_title}
            />

            <TextPage content={content} />
        </div>
    );
}