import React from 'react';
import Tabs from '../../Tabs';
import labels from '../../../data/labels.json';
import MetricTab from './MetricTab';
import ImperialTab from './ImperialTab';
import FieldHeader from '../FieldHeader';

function HeightPicker() {
  const {
    metric_title,
    imperial_title,
    height_title,
  } = labels?.pages?.regimen;

  const tabItems = [
    {
      "id": "metric",
      "title": metric_title,
      "component": <MetricTab />,
    },
    {
      "id": "imperial",
      "title": imperial_title,
      "component": <ImperialTab />,
    },
  ];

  return (
    <section className="c-height-picker">
      <FieldHeader
        title={height_title}
      />
      <Tabs items={tabItems} />
    </section>
  );
}

export default HeightPicker;
