import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Context from '../../components/Regimen/Context';
import ROUTES from '../../consts/routes.json';
import steps from '../../data/forms/create-regimen';
import { getRegimenStepItems } from '../../utils';
import Product from '../Products/Product';

function Routes() {
    const [state] = useContext(Context);
    const items = getRegimenStepItems(state);
    const homepage = steps[0];

    return (
        items && items.length > 0 ? (
            <Switch>
                <Route
                    exact={homepage.exact}
                    key={homepage.id}
                    path={homepage.url}
                    component={homepage.component}
                />

                <Route
                    path={`/regimen${ROUTES.PRODUCTS}/:id/`}
                    component={Product}
                />

                <Route
                    path={`/regimen${ROUTES.PRODUCTS}/compare`}
                    component={Product}
                />

                {
                    items.map(item => {
                        return item.disabled ? (
                            <Redirect
                                key={item.id}
                                to={ROUTES.CREATE_REGIMEN}
                            />
                        ) : (
                            <Route
                                exact={item.exact}
                                key={item.id}
                                path={item.url}
                                component={item.component}
                            />
                        )
                    })
                }
            </Switch>
        ) : null
    );
}

export default Routes;
